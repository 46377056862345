import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getSidebar } from '../actions'
import { getCommandService } from '../services/command.service'
import TabContent from '../components/tab/TabContent'
import TabMenu from '../components/tab/TabMenu'

export default function ZoneView({ sidebar }) {
    const commandService = getCommandService()
    const dispatch = useDispatch()

    const [currentTabIndex, setCurrentTabIndex] = useState(0)
    const [currentTabContent, setCurrentTabContent] = useState(null)
    const [currentSidebar, setCurrentSidebar] = useState([])

    const [tabOptions, setTabOptions] = useState([])
    const [tabContent, setTabContent] = useState([])
    const [sidebarItems, setSidebarItems] = useState([])

    const screenKey = useSelector(state => (state?.screen?.data) ? Object.keys(state.screen.data)[0] : null)
    const screenData = useSelector(state => (state?.screen?.data) ? Object.values(state.screen.data)[0] : {})

    useEffect(() => {   
        if (screenData[screenKey + 'Info']?.zones && screenData[screenKey + 'Info']?.zones.length > 0) {
            setTabOptions(screenData[screenKey + 'Info']?.zones.map((i) => i.tabEditor))
            setTabContent(screenData[screenKey + 'Info']?.zones.map((i) => i.controlEditor))
            setSidebarItems(screenData[screenKey + 'Info']?.zones.map((i) => i.sideBarEditor))
            setCurrentTabContent(screenData[screenKey + 'Info']?.zones.map((i) => i.controlEditor)[currentTabIndex])
        } 

    // eslint-disable-next-line
    }, [screenData[screenKey + 'Info']?.zones])

    /** get sidebar items */
    useEffect(() => {   
        const sidebar = sidebarItems[currentTabIndex] ?? []
        dispatch( getSidebar(sidebar) )
    
    // eslint-disable-next-line
    }, [sidebarItems, currentSidebar])

    const setCurrentTab = (index) => {
        setCurrentTabIndex(index)
        setCurrentSidebar(sidebarItems[index])
        setCurrentTabContent(tabContent[index])
    }

    const handleToggleZoneState = (command) => {
        commandService.queueCommand(command)
    }

    return (
        <div className="flex flex-col sm:flex-row justify-between w-full h-full">
            { tabOptions ? 
                <TabMenu 
                    tabOptions={tabOptions}
                    currentTabIndex={currentTabIndex} 
                    onSelectTab={setCurrentTab}
                    onZoneToggle={handleToggleZoneState}
                /> : '' 
            }
            { currentTabContent ? <TabContent tabContent={currentTabContent} /> : '' }   
            { sidebar ? '' : <div className="w-full h-[1px] overflow-hidden sm:h-full sm:w-[34%]"></div> }
        </div>
    )
}