export const SleepIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M36.05,65.94c-16.5,0-29.92-13.42-29.92-29.92c0-15.15,11.31-27.9,26.33-29.7c0.33-0.08,0.65-0.11,0.96-0.11
                        c0.02,0,0.04,0,0.07,0c0.08,0,0.17,0,0.25,0.01c0.1,0.01,0.19,0.03,0.28,0.05c2.21,0.3,3.86,2.18,3.86,4.4
                        c0,1.3-0.57,2.53-1.58,3.38c-3.02,2.95-4.69,6.85-4.69,10.98c0,8.49,6.91,15.4,15.4,15.4c4.19,0,8.23-1.75,11.08-4.79
                        c0.04-0.05,0.09-0.09,0.13-0.13c0.84-0.86,1.99-1.35,3.18-1.35c1.56,0,3.03,0.84,3.83,2.19c0.25,0.42,0.44,0.88,0.54,1.36
                        c0.01,0.04,0.01,0.08,0.02,0.12c0.03,0.19,0.06,0.4,0.07,0.63c0,0.03,0,0.07,0,0.1c0,0.02,0,0.03,0,0.05c0,0.3-0.03,0.61-0.1,0.93
                        C63.99,54.59,51.24,65.94,36.05,65.94z M33.37,9.8c-0.04,0-0.09,0.01-0.16,0.03c-0.08,0.02-0.16,0.03-0.23,0.04
                        C19.73,11.42,9.73,22.66,9.73,36.02c0,14.51,11.81,26.32,26.32,26.32c13.39,0,24.63-10.03,26.15-23.32
                        c0.01-0.07,0.02-0.15,0.04-0.22c0.01-0.06,0.02-0.11,0.03-0.15c0-0.01,0-0.03,0-0.04c0-0.03,0-0.07,0-0.1
                        c0-0.03-0.01-0.05-0.02-0.08c0-0.02-0.01-0.03-0.01-0.05c-0.01-0.05-0.04-0.11-0.09-0.18c-0.17-0.29-0.44-0.45-0.74-0.45
                        c-0.33,0-0.54,0.19-0.64,0.3c-0.06,0.07-0.13,0.13-0.2,0.19c-3.52,3.67-8.45,5.77-13.57,5.77c-10.47,0-19-8.52-19-19
                        c0-5.13,2.07-9.97,5.82-13.61c0.04-0.04,0.07-0.07,0.11-0.1c0.12-0.1,0.32-0.32,0.32-0.65c0-0.43-0.33-0.79-0.76-0.84
                        c-0.03,0-0.06-0.01-0.08-0.01C33.4,9.8,33.4,9.8,33.37,9.8z M62.27,38.56L62.27,38.56L62.27,38.56z M64.06,38.55L64.06,38.55
                        L64.06,38.55z"/>
                </g>
            </g>
        </svg>
    )
}