export const CurtainsClosedIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M62.33,54.04h-2.49V11.96c0-2.98-2.42-5.4-5.4-5.4H17.55c-2.98,0-5.4,2.42-5.4,5.4v42.08H9.67
                            c-1.99,0-3.6,1.61-3.6,3.6v4.33c0,1.99,1.61,3.6,3.6,3.6h52.67c1.99,0,3.6-1.61,3.6-3.6v-4.33
                            C65.93,55.66,64.32,54.04,62.33,54.04z M56.25,11.96v42.08H37.8V10.16h16.65C55.44,10.16,56.25,10.97,56.25,11.96z M15.75,11.96
                            c0-0.99,0.81-1.8,1.8-1.8H34.2v43.88H15.75V11.96z M9.67,61.98v-4.33h52.67l0,4.33H9.67z"/>
                        <path fill="currentColor" d="M24.3,29.19c2.63-4.55,3.2-11.96,3.22-12.27c0.07-0.99-0.67-1.85-1.66-1.93c-1-0.07-1.85,0.67-1.93,1.66
                            c-0.01,0.07-0.53,6.9-2.75,10.73c-0.5,0.86-0.2,1.96,0.66,2.46c0.28,0.16,0.59,0.24,0.9,0.24C23.36,30.09,23.96,29.77,24.3,29.19
                            z"/>
                        <path fill="currentColor" d="M27.35,34.08c-0.99-0.07-1.85,0.67-1.93,1.66c-0.01,0.07-0.53,6.9-2.75,10.73c-0.5,0.86-0.2,1.96,0.66,2.46
                            c0.28,0.16,0.59,0.24,0.9,0.24c0.62,0,1.23-0.32,1.56-0.9c2.63-4.55,3.2-11.96,3.22-12.27C29.08,35.02,28.34,34.16,27.35,34.08z"
                            />
                        <path fill="currentColor" d="M49.26,30.09c0.31,0,0.62-0.08,0.9-0.24c0.86-0.5,1.15-1.6,0.66-2.46c-2.22-3.83-2.74-10.66-2.75-10.73
                            C48,15.67,47.13,14.93,46.14,15c-0.99,0.07-1.74,0.93-1.66,1.93c0.02,0.31,0.59,7.72,3.22,12.27
                            C48.04,29.77,48.64,30.09,49.26,30.09z"/>
                        <path fill="currentColor" d="M46.21,48.28c0.33,0.58,0.94,0.9,1.56,0.9c0.31,0,0.62-0.08,0.9-0.24c0.86-0.5,1.15-1.6,0.66-2.46
                            c-2.22-3.83-2.74-10.66-2.75-10.73c-0.07-0.99-0.94-1.73-1.93-1.66c-0.99,0.07-1.74,0.93-1.66,1.93
                            C43.01,36.32,43.58,43.73,46.21,48.28z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}