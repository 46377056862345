import React, { useEffect, useState } from 'react'
import { getCommandService } from '../services/command.service'
import { getSocketStatus } from '../services/socket.service'

export function RefreshToggle({ onReceiveStatus }) {
    const commandService = getCommandService()

    const [toggleStatus, setToggleStatus] = useState(false)
    const [refreshStatus, setRefreshStatus] = useState('')
    let listenForRefresh = null

    const handleSetRefresh = (val) => {
        setToggleStatus(val)
        commandService.setRefreshState(val)
    }

    useEffect(() => {
        if (!listenForRefresh) {
            listenForRefresh = setInterval(() => {
                const status = getSocketStatus()
                setRefreshStatus(status)
                
                if ((status === 'REFRESHING') !== toggleStatus) {
                   setToggleStatus(status === 'REFRESHING')
                }

                onReceiveStatus(status)
            }, 500)
        } 
    }, [])

    return (
        <div className="flex refresh-toggle px-2">
            <button 
                className="text-ata-a-outer-space-crayola dark:text-ata-d-cultured"
                onClick={() => { handleSetRefresh(!toggleStatus) }}
            >
                { refreshStatus === 'INIT' ?     
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                    </svg> : '' 
                }

                { refreshStatus === 'OPEN' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
                    </svg> : '' 
                }
            
                { refreshStatus === 'REFRESHING' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg> : '' 
                }
                { refreshStatus === 'CLOSED' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg> : '' 
                }
                
                { refreshStatus === 'ERROR' ?        
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg> : '' 
                }

                { refreshStatus === 'REFRESH_PAUSED' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg> : '' 
                }
            </button>
        </div>
    )
  }