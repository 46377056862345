export const AwayIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" >
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M38.02,20.31c1.82-0.93,3.94-0.99,5.8-0.18c0.23,0.1,0.46,0.22,0.68,0.35c-0.77,0.12-1.53,0.31-2.26,0.6
                            c-2.9,1.14-5.12,3.5-6.08,6.46c-0.31,0.95,0.21,1.96,1.15,2.27c0.19,0.06,0.37,0.09,0.56,0.09c0.76,0,1.46-0.48,1.71-1.24
                            c0.63-1.94,2.08-3.48,3.98-4.23c0.54-0.21,1.1-0.35,1.66-0.42c-2.85,5.95-2.7,15.27-2.46,19.55c-2.18-0.38-4.45-0.6-6.77-0.6
                            c-10.73,0-20.38,4.29-25.19,11.18c-0.57,0.82-0.37,1.94,0.45,2.51c0.31,0.22,0.67,0.32,1.03,0.32c0.57,0,1.13-0.27,1.48-0.77
                            c4.08-5.86,12.81-9.64,22.24-9.64s18.15,3.78,22.24,9.64c0.57,0.82,1.69,1.02,2.51,0.45c0.82-0.57,1.02-1.69,0.45-2.51
                            c-3.17-4.55-8.46-7.95-14.76-9.75c-0.37-4.78-0.45-14.93,2.55-19.77c1.4,0.39,2.65,1.22,3.55,2.37c1.25,1.61,1.72,3.67,1.27,5.67
                            c-0.22,0.97,0.39,1.93,1.37,2.15c0.13,0.03,0.26,0.04,0.39,0.04c0.82,0,1.57-0.57,1.75-1.41c0.68-3.04-0.03-6.2-1.94-8.66
                            c-0.48-0.62-1.04-1.18-1.64-1.67c0.25,0,0.51,0.01,0.76,0.05c2.02,0.25,3.81,1.38,4.91,3.11c0.34,0.54,0.93,0.83,1.52,0.83
                            c0.33,0,0.66-0.09,0.96-0.28c0.84-0.53,1.09-1.65,0.55-2.48c-1.67-2.63-4.41-4.36-7.5-4.75c-0.72-0.09-1.44-0.09-2.16-0.03
                            c0.15-0.14,0.31-0.27,0.47-0.4c1.62-1.24,3.68-1.69,5.67-1.24c0.97,0.22,1.93-0.38,2.16-1.35c0.22-0.97-0.38-1.93-1.35-2.16
                            c-3.04-0.7-6.2-0.01-8.67,1.89c-0.18,0.14-0.34,0.28-0.51,0.43c-0.08-0.25-0.16-0.5-0.26-0.74c-1.16-2.89-3.53-5.1-6.5-6.05
                            c-0.95-0.3-1.96,0.22-2.26,1.17c-0.3,0.95,0.22,1.96,1.17,2.26c1.94,0.62,3.49,2.06,4.25,3.96c0.08,0.19,0.14,0.38,0.2,0.58
                            c-0.59-0.42-1.21-0.78-1.88-1.07c-2.86-1.24-6.09-1.15-8.87,0.27c-0.89,0.45-1.24,1.53-0.79,2.42
                            C36.05,20.41,37.14,20.76,38.02,20.31z"/>
                        <path fill="currentColor" d="M64.13,57.33c-3.1,0-4.7,1.63-5.98,2.94c-1.19,1.22-1.89,1.86-3.4,1.86c-1.52,0-2.21-0.64-3.4-1.86
                            c-1.28-1.31-2.87-2.94-5.98-2.94s-4.7,1.63-5.98,2.94c-1.19,1.22-1.89,1.86-3.4,1.86s-2.21-0.64-3.4-1.86
                            c-1.28-1.31-2.87-2.94-5.98-2.94c-3.1,0-4.7,1.63-5.98,2.94c-1.19,1.22-1.88,1.86-3.4,1.86c-1.52,0-2.21-0.64-3.4-1.86
                            c-1.28-1.31-2.87-2.94-5.98-2.94c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8c1.52,0,2.21,0.64,3.4,1.86
                            c1.28,1.31,2.87,2.94,5.98,2.94c3.1,0,4.7-1.63,5.98-2.94c1.19-1.22,1.88-1.86,3.4-1.86c1.52,0,2.21,0.64,3.4,1.86
                            c1.28,1.31,2.87,2.94,5.98,2.94c3.1,0,4.7-1.63,5.98-2.94c1.19-1.22,1.89-1.86,3.4-1.86c1.52,0,2.21,0.64,3.4,1.86
                            c1.28,1.31,2.87,2.94,5.98,2.94c3.1,0,4.7-1.63,5.98-2.94c1.19-1.22,1.89-1.86,3.4-1.86c0.99,0,1.8-0.81,1.8-1.8
                            C65.93,58.13,65.13,57.33,64.13,57.33z"/>
                        <path fill="currentColor" d="M8.23,15.52c0.28,0.03,0.57,0.04,0.85,0.04c2.32,0,4.54-0.91,6.2-2.57c1.44-1.44,2.34-3.34,2.53-5.36
                            c0.09-0.99-0.63-1.87-1.62-1.96c-0.99-0.09-1.87,0.63-1.96,1.62c-0.12,1.21-0.63,2.3-1.49,3.16c-1.1,1.1-2.61,1.64-4.15,1.49
                            c-0.98-0.09-1.87,0.63-1.96,1.62C6.52,14.54,7.24,15.42,8.23,15.52z"/>
                        <path fill="currentColor" d="M13.19,24.2v-6.02c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8v6.02c0,0.99,0.81,1.8,1.8,1.8
                            S13.19,25.2,13.19,24.2z"/>
                        <path fill="currentColor" d="M20.33,10.98h6.02c0.99,0,1.8-0.81,1.8-1.8s-0.81-1.8-1.8-1.8h-6.02c-0.99,0-1.8,0.81-1.8,1.8
                            S19.33,10.98,20.33,10.98z"/>
                        <path fill="currentColor" d="M16.72,16.3l4.26,4.26c0.35,0.35,0.81,0.53,1.27,0.53c0.46,0,0.92-0.18,1.27-0.53c0.7-0.7,0.7-1.84,0-2.55
                            l-4.26-4.26c-0.7-0.7-1.84-0.7-2.55,0C16.02,14.45,16.02,15.59,16.72,16.3z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}