import React, { useState } from 'react'

import { dayLabels } from '../../constants'

export default function DayPicker({
    options, 
    onSelect
}) {
    const [optionValues, setValue] = useState(options)

    const handleClick = (e, option, idx) => {
        e.preventDefault()
    
        const ov = [...optionValues]
        ov[idx].selected = !option.selected

        setValue(ov)
        onSelect(ov)
    }

    const displayValues = () => {
        return optionValues
            .map((i, idx) => {
                i.displayLabel = dayLabels[idx].short
                return i
            })
            .filter(i => i.selected)
            .map(i => i.displayLabel).join(', ')
    }

    return (
        <div className="flex flex-col justify-center items-center pb-2">
            <div className="text-left w-full pb-4">
                {
                    optionValues ? displayValues() : ''
                }
            </div>
            <div>
                { 
                    optionValues ?
                        optionValues.map((option, idx) => {
                            return (
                                <button
                                    className={`
                                        rounded-md text-sm text-center w-[30px] py-1 m-2 
                                        ${option.selected ? 'border' : ''}
                                        active:bg-ata-a-gainsboro 
                                        dark:active:bg-ata-d-liver
                                    `}
                                    key={idx}
                                    onClick={(e) => { handleClick(e, option, idx) }}
                                >
                                    {option.label}
                                </button>
                            )
                        }) : 'No Options Found'
                }
            </div>
        </div>
    )
}