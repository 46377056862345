import './App.css'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { App as CapApp } from '@capacitor/app'
import { BackgroundTask } from '@capawesome/capacitor-background-task'
import { Capacitor } from '@capacitor/core'

import { login } from './actions'
import { formatCommandRoute } from './utils/formatCommandRoute'
import { getTitles, computeTitle } from './utils/getTitles'

import ConnectionService from './services/connection.service'
import { setCommandService } from './services/command.service'
import { isAuthenticated } from './services/auth.service'
import { initLogDB } from './services/log.service'

import { ScreenLoader } from './components/Loaders'
import PrivateRoute from './components/auth/PrivateRoute'
import CommandRouter from './components/CommandRouter'
import Header from './components/Navigation/Header'
import Drawer from './components/Navigation/Drawer'
import DeepLink from './components/auth/DeepLink'
import Modal from './components/Modal'
import Sidebar from './components/Sidebar'

import ButtonView from './views/ButtonView'
import TableView from './views/TableView'
import FormView from './views/FormView'
import ZoneView from './views/ZoneView'
import IconView from './views/IconView'
import LoginView from './views/LoginView'
import ConnectView from './views/ConnectView'
import SettingsView from './views/SettingsView'
import EventLogView from './views/EventLogView'
import AccountView from './views/AccountView'
import GalleryView from './views/GalleryView'

/** @todo deprecated - this view is for supporting screens from previous versions */
import LegacyView from './views/LegacyView'

const commandService = setCommandService()
const connectionService = new ConnectionService(commandService)

function App() {
  const dispatch = useDispatch()
  const [appLoading, setLoading] = useState(false)

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modal, setModal] = useState({})

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [commandRoute, setCommandRoute] = useState('/')
  const [sidebarItems, setSidebarItems] = useState([])
  const state = useSelector(state => state)
  const [title, setTitle] = useState({})

  const [iOS, setIos] = useState()
  const [isPortrait, setIsPortrait] = useState(
    window.orientation === 0
  )

  const setAppStateListeners = () => {
    CapApp.addListener('appStateChange', state => {
      if (!state.isActive) {
        BackgroundTask.beforeExit(async () => {
          console.warn('exiting...')
          connectionService.closeConnection()
        })
      }

      if (state.isActive) {
        console.warn('restoring...')
        window.location.reload()
      }
    })

    CapApp.addListener('backButton', e => {
      commandService.sendBackCommand()
    })
  }

  /**
   * Check if the user is already logged in
   */
  useEffect(() => {
    setAppStateListeners()
    setIsLoggedIn(false)

    if (isAuthenticated) {
      const user = JSON.parse(localStorage.getItem('user'))

      if (user) {
        dispatch(login(user))
        setIsLoggedIn(true)
      }
    }


    setIos(Capacitor.getPlatform() == 'ios')
    setIsPortrait(window.orientation === 0)

    if (iOS) {
      window.addEventListener('orientationchange', () => {
        setIsPortrait(window.orientation === 0)
      })
    }

    initLogDB()
  }, [])

  useEffect(() => {
    const titleBar = (state?.screen?.data) ? computeTitle(state?.screen?.data) : getTitles(isLoggedIn)
    const sidebar = state?.sidebar ?? []

    setTitle(titleBar)
    setIsLoggedIn((state?.auth !== null))

    if (state?.screen) {
      const newRoute = formatCommandRoute(Object.keys(state?.screen?.data)[0])
      setCommandRoute(newRoute)
    }
    setSidebarItems(sidebar)
  }, [state])

  useEffect(() => {
    setModal(state?.modal)
    setShowModal(state?.modal !== null)
  }, [state?.modal])

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
    drawerOpen
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden')
  }

  const onDrawerNavigation = (customRoute) => {
    commandService.setRefreshState(false)
    setTimeout(() => {
      setCommandRoute(customRoute)
      setTimeout(() => {
        setSidebarItems([])
        setTitle(getTitles(isLoggedIn))
      }, 10)
    }, 200)


  }

  if (appLoading) {
    return (<ScreenLoader />)
  }

  return (
    <BrowserRouter>
      <div className="absolute w-screen top-0 left-0">
        <Header
          titleBar={title}
          isLoggedIn={isLoggedIn}
          iOS={iOS}
          isPortrait={isPortrait}
          openDrawer={toggleDrawer}
        />
      </div>

      <div className={`
          flex flex-col sm:flex-row w-screen h-screen dark:bg-ata-d-dark-liver dark:text-ata-d-cultured pt-12
        `}>
        <div
          className={`
              ${sidebarItems && sidebarItems.length > 0 ? 'sm:w-4/5 ' : 'h-full'} 
              sm:h-full overflow-hidden flex justify-center items-start overflow-y-auto w-full
          `}>
          {isLoggedIn ?
            <Routes>
              <Route path="/" element={<ConnectView connectionService={connectionService} />} />
              <Route path="/connections" element={<ConnectView connectionService={connectionService} />} />

              <Route path="/button" element={<PrivateRoute isLoggedIn={isLoggedIn}><ButtonView /></PrivateRoute>} />
              <Route path="/button/:id" exact element={<PrivateRoute isLoggedIn={isLoggedIn}><ButtonView /></PrivateRoute>} />

              {/* screen types */}
              <Route path="/zone" element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ZoneView sidebar={(sidebarItems && sidebarItems.length > 0)} />
                </PrivateRoute>
              } exact />

              <Route path="/zone/:id" element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ZoneView sidebar={(sidebarItems && sidebarItems.length > 0)} />
                </PrivateRoute>} exact
              />

              <Route path="/table" element={<PrivateRoute isLoggedIn={isLoggedIn}><TableView /></PrivateRoute>} exact />
              <Route path="/table/:id" element={<PrivateRoute isLoggedIn={isLoggedIn}><TableView /></PrivateRoute>} exact />

              <Route path="/form" element={<PrivateRoute isLoggedIn={isLoggedIn}><FormView /></PrivateRoute>} exact />
              <Route path="/form/:id" element={<PrivateRoute isLoggedIn={isLoggedIn}><FormView /></PrivateRoute>} exact />

              <Route path="/button" element={<PrivateRoute isLoggedIn={isLoggedIn}><ButtonView /></PrivateRoute>} />

              {/* app settings */}
              <Route path="/imageupload" element={<PrivateRoute isLoggedIn={isLoggedIn}><GalleryView /></PrivateRoute>} />
              <Route path="/account" element={<PrivateRoute isLoggedIn={isLoggedIn}><AccountView /></PrivateRoute>} />
              <Route path="/logs" element={<PrivateRoute isLoggedIn={isLoggedIn}><EventLogView /></PrivateRoute>} />
              <Route path="/settings" element={<PrivateRoute isLoggedIn={isLoggedIn}><SettingsView /></PrivateRoute>} />

              {/* implement legacy screens - a good place to hack around and start learning if you are seeing this app for the first time */}
              <Route path="/legacy" element={<PrivateRoute isLoggedIn={isLoggedIn}><LegacyView /></PrivateRoute>} exact />
              <Route path="/legacy/:id" element={<PrivateRoute isLoggedIn={isLoggedIn}><LegacyView /></PrivateRoute>} exact />

              {/* Preview all the icons */}
              <Route path="/icon" element={<IconView />} exact />
            </Routes>
            : <LoginView />
          }
        </div>
        {
          (isLoggedIn && sidebarItems && sidebarItems.length > 0) ?
            <div className="w-full flex-shrink h-1/5 overflow-hidden border-t sm:h-full sm:w-1/5">
              <Sidebar items={sidebarItems} />
            </div> : ''
        }
      </div>

      <Modal data={modal} display={showModal} />
      <CommandRouter route={commandRoute} />

      {drawerOpen ? <div className="fade-in drawer-backdrop fixed inset-0 z-30"></div> : ''}
      {isLoggedIn ?
        <Drawer
          closeDrawer={() => { toggleDrawer() }}
          onNavigate={onDrawerNavigation}
          isOpen={drawerOpen}
          isLoggedIn={isLoggedIn}
          connectionService={connectionService}
        /> : ''}
    </BrowserRouter>
  )
}

export default App
