import React, { useState, useEffect } from 'react'
import { resetPassword } from '../services/auth.service'

export default function AccountView({ isLoggedIn }) {
    const [email, setEmail] = useState('')
    const [responseMessage, setResponseMessage] = useState('')

    const handleSendResetPasswordLink = (e) => {
        e.preventDefault()
        resetPassword().then((r) => { setResponseMessage(r) })
    }

    useEffect(() => {
        const user = localStorage.getItem('user')
        
        if (user) {
          const userJson = JSON.parse(user)  
          setEmail(userJson.email)
        }
    }, [])

    return (
        <div className="w-full justify-center p-4">
            <div className="mb-4">
                <div>
                    <label htmlFor="email-address" className="block mb-2 text-sm font-medium">Email Address</label>
                    <div className="relative">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                            </svg>
                        </div>
                        <input
                            disabled={true} 
                            type="text" 
                            id="email-address" 
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2.5 opacity-40" 
                            placeholder="name@exmaple.com" 
                            value={email}
                            onChange={(e) => { setEmail(e.currentTarget.value) }}  
                        />
                    </div>
                </div>
            </div>          
            
            <h1 className="font-bold text-lg border-b pb-2 mb-2">Reset Password</h1>
            <div>
                <button
                    onClick={(e) => { handleSendResetPasswordLink(e) }}
                    className="block border mx-auto font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center my-4
                        focus:ring-ata-a-june-bud
                        focus:border-ata-a-june-bud   
                        dark:bg-ata-d-dark-liver 
                        dark:border-ata-d-cultured
                        dark:placeholder-ata-d-gray-web
                        dark:text-ata-d-cultured
                        dark:focus:ring-ata-d-yellow-green 
                        dark:focus:border-ata-d-yellow-green"   
                    >Send Reset Password Link</button>
            </div>
            <div className="text-xs text-center">{ responseMessage }</div>
        </div>
    )
}