export const Swing0Icon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M62.16,26.67c-0.16-0.03-0.33-0.05-0.49-0.09c-2-0.4-3.98,0.71-4.7,2.64l-10.8,28.68
                            c-0.54,1.43-0.46,3.01,0.21,4.35c0.68,1.35,1.86,2.32,3.34,2.74v0c3.31,0.95,6.74,1.56,10.19,1.83c0.13,0.01,0.27,0.02,0.4,0.02
                            c1.34,0,2.64-0.51,3.63-1.43c1.1-1.02,1.74-2.47,1.74-3.98V30.82C65.67,28.76,64.16,26.98,62.16,26.67z M62.07,61.44
                            c0,0.51-0.21,0.99-0.58,1.34c-0.36,0.34-0.83,0.5-1.31,0.46c-3.21-0.25-6.39-0.82-9.47-1.7v0c-0.5-0.14-0.89-0.46-1.11-0.9
                            c-0.22-0.45-0.24-0.97-0.06-1.46l10.8-28.68c0.1-0.27,0.37-0.43,0.63-0.38c0.21,0.04,0.43,0.08,0.65,0.11
                            c0.26,0.04,0.46,0.29,0.46,0.59V61.44z"/>
                        <path fill="currentColor" d="M15.22,26.62l28.68-10.8c1.93-0.73,3.04-2.71,2.64-4.7c-0.03-0.16-0.06-0.33-0.09-0.49
                            c-0.31-2-2.09-3.51-4.14-3.51H11.69c-1.5,0-2.95,0.63-3.98,1.74C6.7,9.94,6.19,11.41,6.3,12.89c0.26,3.45,0.88,6.88,1.83,10.19
                            l0,0c0.42,1.48,1.4,2.66,2.74,3.34c0.75,0.38,1.57,0.56,2.4,0.56C13.93,26.98,14.59,26.86,15.22,26.62z"/>
                        <path fill="currentColor" d="M48.9,18.33c-1-1.53-2.99-2.08-4.63-1.28L15.87,31c-1.35,0.66-2.37,1.87-2.8,3.31
                            c-0.43,1.43-0.24,2.98,0.51,4.26c0.83,1.4,1.73,2.78,2.69,4.1c0.87,1.2,2.22,1.99,3.7,2.17c0.21,0.03,0.43,0.04,0.64,0.04
                            c1.28,0,2.53-0.45,3.51-1.29l24.11-20.5C49.62,21.91,49.92,19.88,48.9,18.33z M45.91,20.35L21.8,40.85
                            c-0.39,0.33-0.89,0.48-1.39,0.42c-0.49-0.06-0.93-0.31-1.21-0.71c-0.89-1.23-1.73-2.51-2.5-3.82c-0.25-0.42-0.31-0.92-0.17-1.4
                            c0.15-0.49,0.48-0.89,0.94-1.11l28.41-13.95l0.04,0.04C45.91,20.33,45.91,20.34,45.91,20.35z"/>
                        <path fill="currentColor" d="M54.59,23.08c-1.51-0.98-3.53-0.69-4.7,0.68L29.71,47.5c-0.98,1.15-1.43,2.66-1.25,4.16
                            c0.18,1.48,0.97,2.83,2.17,3.7c1.27,0.92,2.6,1.79,3.95,2.59c0.83,0.49,1.77,0.74,2.72,0.74c0.52,0,1.03-0.07,1.53-0.22
                            c1.44-0.43,2.65-1.45,3.31-2.8l13.73-27.95C56.69,26.07,56.14,24.09,54.59,23.08z M38.93,54.07c-0.23,0.46-0.62,0.79-1.11,0.94
                            c-0.48,0.14-0.97,0.08-1.39-0.17c-1.26-0.74-2.49-1.55-3.68-2.41c-0.4-0.29-0.65-0.72-0.71-1.21c-0.06-0.51,0.09-1.01,0.42-1.4
                            L52.64,26.1l0.02,0.02L38.93,54.07z"/>
                        <path fill="currentColor" d="M48.78,6.98c0,9.49,7.69,17.19,17.19,17.19V6.98H48.78z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const Swing30Icon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M62.16,26.67c-0.16-0.03-0.33-0.05-0.49-0.09c-2-0.4-3.98,0.71-4.7,2.64l-10.8,28.68
                            c-0.54,1.43-0.46,3.01,0.21,4.35c0.68,1.35,1.86,2.32,3.34,2.74v0c3.31,0.95,6.74,1.56,10.19,1.83c0.13,0.01,0.27,0.02,0.4,0.02
                            c1.34,0,2.64-0.51,3.63-1.43c1.1-1.02,1.74-2.47,1.74-3.98V30.82C65.67,28.76,64.16,26.98,62.16,26.67z M62.07,61.44
                            c0,0.51-0.21,0.99-0.58,1.34c-0.36,0.34-0.83,0.5-1.31,0.46c-3.21-0.25-6.39-0.82-9.47-1.7v0c-0.5-0.14-0.89-0.46-1.11-0.9
                            c-0.22-0.45-0.24-0.97-0.06-1.46l10.8-28.68c0.1-0.27,0.37-0.43,0.63-0.38c0.21,0.04,0.43,0.08,0.65,0.11
                            c0.26,0.04,0.46,0.29,0.46,0.59V61.44z"/>
                        <path fill="currentColor" d="M15.22,26.62l28.68-10.8c1.93-0.73,3.04-2.71,2.64-4.7c-0.03-0.16-0.06-0.33-0.09-0.49
                            c-0.31-2-2.09-3.51-4.14-3.51H11.69c-1.5,0-2.95,0.63-3.98,1.74C6.7,9.94,6.19,11.41,6.3,12.89c0.26,3.45,0.88,6.88,1.83,10.19
                            l0,0c0.42,1.48,1.4,2.66,2.74,3.34c0.75,0.38,1.57,0.56,2.4,0.56C13.93,26.98,14.59,26.86,15.22,26.62z M12.49,23.2
                            c-0.44-0.22-0.76-0.61-0.9-1.11c-0.88-3.08-1.45-6.26-1.7-9.47c-0.04-0.48,0.13-0.95,0.46-1.31c0.35-0.38,0.82-0.58,1.34-0.58
                            h30.62c0.29,0,0.54,0.2,0.59,0.46c0.03,0.22,0.07,0.43,0.11,0.65c0.05,0.26-0.11,0.53-0.38,0.63l-28.68,10.8
                            C13.45,23.44,12.93,23.42,12.49,23.2z"/>
                        <path fill="currentColor" d="M48.9,18.33c-1-1.53-2.99-2.08-4.63-1.28L15.87,31c-1.35,0.66-2.37,1.87-2.8,3.31
                            c-0.43,1.43-0.24,2.98,0.51,4.26c0.83,1.4,1.73,2.78,2.69,4.1c0.87,1.2,2.22,1.99,3.7,2.17c0.21,0.03,0.43,0.04,0.64,0.04
                            c1.28,0,2.53-0.45,3.51-1.29l24.11-20.5C49.62,21.91,49.92,19.88,48.9,18.33z"/>
                        <path fill="currentColor" d="M54.59,23.08c-1.51-0.98-3.53-0.69-4.7,0.68L29.71,47.5c-0.98,1.15-1.43,2.66-1.25,4.16
                            c0.18,1.48,0.97,2.83,2.17,3.7c1.27,0.92,2.6,1.79,3.95,2.59c0.83,0.49,1.77,0.74,2.72,0.74c0.52,0,1.03-0.07,1.53-0.22
                            c1.44-0.43,2.65-1.45,3.31-2.8l13.73-27.95C56.69,26.07,56.14,24.09,54.59,23.08z M38.93,54.07c-0.23,0.46-0.62,0.79-1.11,0.94
                            c-0.48,0.14-0.97,0.08-1.39-0.17c-1.26-0.74-2.49-1.55-3.68-2.41c-0.4-0.29-0.65-0.72-0.71-1.21c-0.06-0.51,0.09-1.01,0.42-1.4
                            L52.64,26.1l0.02,0.02L38.93,54.07z"/>
                        <path fill="currentColor" d="M48.78,6.98c0,9.49,7.69,17.19,17.19,17.19V6.98H48.78z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const Swing60Icon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M62.16,26.67c-0.16-0.03-0.33-0.05-0.49-0.09c-2-0.4-3.98,0.71-4.7,2.64l-10.8,28.68
                            c-0.54,1.43-0.46,3.01,0.21,4.35c0.68,1.35,1.86,2.32,3.34,2.74v0c3.31,0.95,6.74,1.56,10.19,1.83c0.13,0.01,0.27,0.02,0.4,0.02
                            c1.34,0,2.64-0.51,3.63-1.43c1.1-1.02,1.74-2.47,1.74-3.98V30.82C65.67,28.76,64.16,26.98,62.16,26.67z M62.07,61.44
                            c0,0.51-0.21,0.99-0.58,1.34c-0.36,0.34-0.83,0.5-1.31,0.46c-3.21-0.25-6.39-0.82-9.47-1.7v0c-0.5-0.14-0.89-0.46-1.11-0.9
                            c-0.22-0.45-0.24-0.97-0.06-1.46l10.8-28.68c0.1-0.27,0.37-0.43,0.63-0.38c0.21,0.04,0.43,0.08,0.65,0.11
                            c0.26,0.04,0.46,0.29,0.46,0.59V61.44z"/>
                        <path fill="currentColor" d="M15.22,26.62l28.68-10.8c1.93-0.73,3.04-2.71,2.64-4.7c-0.03-0.16-0.06-0.33-0.09-0.49
                            c-0.31-2-2.09-3.51-4.14-3.51H11.69c-1.5,0-2.95,0.63-3.98,1.74C6.7,9.94,6.19,11.41,6.3,12.89c0.26,3.45,0.88,6.88,1.83,10.19
                            c0.42,1.47,1.4,2.66,2.74,3.34c0.75,0.38,1.57,0.57,2.4,0.57C13.93,26.98,14.59,26.86,15.22,26.62z M12.49,23.2
                            c-0.44-0.22-0.76-0.61-0.9-1.11c0,0,0,0,0,0c-0.88-3.08-1.45-6.26-1.7-9.47c-0.04-0.48,0.13-0.95,0.46-1.31
                            c0.35-0.38,0.82-0.58,1.34-0.58h30.62c0.29,0,0.54,0.2,0.58,0.46c0.03,0.22,0.07,0.43,0.11,0.65c0.05,0.26-0.11,0.53-0.38,0.63
                            l-28.68,10.8C13.45,23.44,12.93,23.42,12.49,23.2z"/>
                        <path fill="currentColor" d="M48.9,18.33c-1-1.53-2.99-2.08-4.63-1.28L15.87,31c-1.35,0.66-2.37,1.87-2.8,3.31
                            c-0.43,1.43-0.24,2.98,0.51,4.26c0.83,1.4,1.73,2.78,2.69,4.1c0.87,1.2,2.22,1.99,3.7,2.17c0.21,0.03,0.43,0.04,0.64,0.04
                            c1.28,0,2.53-0.45,3.51-1.29l24.11-20.5C49.62,21.91,49.92,19.88,48.9,18.33z M45.91,20.35L21.8,40.85
                            c-0.39,0.33-0.89,0.48-1.39,0.42c-0.49-0.06-0.93-0.31-1.21-0.71c-0.89-1.23-1.73-2.51-2.5-3.82c-0.25-0.42-0.31-0.92-0.17-1.4
                            c0.15-0.49,0.48-0.89,0.94-1.11l28.41-13.95l0.04,0.04C45.91,20.33,45.91,20.34,45.91,20.35z"/>
                        <path fill="currentColor" d="M54.59,23.08c-1.51-0.98-3.53-0.69-4.7,0.68L29.71,47.5c-0.98,1.15-1.43,2.66-1.25,4.16
                            c0.18,1.48,0.97,2.83,2.17,3.7c1.27,0.92,2.6,1.79,3.95,2.59c0.83,0.49,1.77,0.74,2.72,0.74c0.52,0,1.03-0.07,1.53-0.22
                            c1.44-0.43,2.65-1.45,3.31-2.8l13.73-27.95C56.69,26.07,56.14,24.09,54.59,23.08z"/>
                        <path fill="currentColor" d="M48.78,6.98c0,9.49,7.69,17.19,17.19,17.19V6.98H48.78z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const Swing90Icon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
	            
                <g>
                    <g>
                        <path fill="currentColor" d="M62.16,26.67c-0.16-0.03-0.33-0.05-0.49-0.09c-2-0.4-3.98,0.71-4.7,2.64l-10.8,28.68
                            c-0.54,1.43-0.46,3.01,0.21,4.35c0.68,1.35,1.86,2.32,3.34,2.74v0c3.31,0.95,6.74,1.56,10.19,1.83c0.13,0.01,0.27,0.02,0.4,0.02
                            c1.34,0,2.64-0.51,3.63-1.43c1.1-1.02,1.74-2.47,1.74-3.98V30.82C65.67,28.76,64.16,26.98,62.16,26.67z"/>
                        <path fill="currentColor" d="M15.22,26.62l28.68-10.8c1.93-0.73,3.04-2.71,2.64-4.7c-0.03-0.16-0.06-0.33-0.09-0.49
                            c-0.31-2-2.09-3.51-4.14-3.51H11.69c-1.5,0-2.95,0.63-3.98,1.74C6.7,9.94,6.19,11.41,6.3,12.89c0.26,3.45,0.88,6.88,1.83,10.19
                            c0.42,1.47,1.4,2.66,2.74,3.34c0.75,0.38,1.57,0.57,2.4,0.57C13.93,26.98,14.59,26.86,15.22,26.62z M12.49,23.2
                            c-0.44-0.22-0.76-0.61-0.9-1.11c0,0,0,0,0,0c-0.88-3.08-1.45-6.26-1.7-9.47c-0.04-0.48,0.13-0.95,0.46-1.31
                            c0.35-0.38,0.82-0.58,1.34-0.58h30.62c0.29,0,0.54,0.2,0.58,0.46c0.03,0.22,0.07,0.43,0.11,0.65c0.05,0.26-0.11,0.53-0.38,0.63
                            l-28.68,10.8C13.45,23.44,12.93,23.42,12.49,23.2z"/>
                        <path fill="currentColor" d="M48.9,18.33c-1-1.53-2.99-2.08-4.63-1.28L15.87,31c-1.35,0.66-2.37,1.87-2.8,3.31
                            c-0.43,1.43-0.24,2.98,0.51,4.26c0.83,1.4,1.73,2.78,2.69,4.1c0.87,1.2,2.22,1.99,3.7,2.17c0.21,0.03,0.43,0.04,0.64,0.04
                            c1.28,0,2.53-0.45,3.51-1.29l24.11-20.5C49.62,21.91,49.92,19.88,48.9,18.33z M45.91,20.35L21.8,40.85
                            c-0.39,0.33-0.89,0.48-1.39,0.42c-0.49-0.06-0.93-0.31-1.21-0.71c-0.89-1.23-1.73-2.51-2.5-3.82c-0.25-0.42-0.31-0.92-0.17-1.4
                            c0.15-0.49,0.48-0.89,0.94-1.11l28.41-13.95l0.04,0.04C45.91,20.33,45.91,20.34,45.91,20.35z"/>
                        <path fill="currentColor" d="M54.59,23.08c-1.51-0.98-3.53-0.69-4.7,0.68L29.71,47.5c-0.98,1.15-1.43,2.66-1.25,4.16
                            c0.18,1.48,0.97,2.83,2.17,3.7c1.27,0.92,2.6,1.79,3.95,2.59c0.83,0.49,1.77,0.74,2.72,0.74c0.52,0,1.03-0.07,1.53-0.22
                            c1.44-0.43,2.65-1.45,3.31-2.8l13.73-27.95C56.69,26.07,56.14,24.09,54.59,23.08z M38.93,54.07c-0.23,0.46-0.62,0.79-1.11,0.94
                            c-0.48,0.14-0.97,0.08-1.39-0.17c-1.26-0.74-2.49-1.55-3.68-2.41c-0.4-0.29-0.65-0.72-0.71-1.21c-0.06-0.51,0.09-1.01,0.42-1.4
                            L52.64,26.1l0.02,0.02L38.93,54.07z"/>
                        <path fill="currentColor" d="M48.78,6.98c0,9.49,7.69,17.19,17.19,17.19V6.98H48.78z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const SwingOffIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">  
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M62.16,26.67c-0.16-0.03-0.33-0.05-0.49-0.09c-2-0.4-3.98,0.71-4.7,2.64l-10.8,28.68
                            c-0.54,1.43-0.46,3.01,0.21,4.35c0.68,1.35,1.86,2.32,3.34,2.74v0c3.31,0.95,6.74,1.56,10.19,1.83c0.13,0.01,0.27,0.02,0.4,0.02
                            c1.34,0,2.64-0.51,3.63-1.43c1.1-1.02,1.74-2.47,1.74-3.98V30.82C65.67,28.76,64.16,26.98,62.16,26.67z M62.07,61.44
                            c0,0.51-0.21,0.99-0.58,1.34c-0.36,0.34-0.83,0.5-1.31,0.46c-3.21-0.25-6.39-0.82-9.47-1.7v0c-0.5-0.14-0.89-0.46-1.11-0.9
                            c-0.22-0.45-0.24-0.97-0.06-1.46l10.8-28.68c0.1-0.27,0.37-0.43,0.63-0.38c0.21,0.04,0.43,0.08,0.65,0.11
                            c0.26,0.04,0.46,0.29,0.46,0.59V61.44z"/>
                        <path fill="currentColor" d="M15.22,26.62l28.68-10.8c1.93-0.73,3.04-2.71,2.64-4.7c-0.03-0.16-0.06-0.33-0.09-0.49
                            c-0.31-2-2.09-3.51-4.14-3.51H11.69c-1.5,0-2.95,0.63-3.98,1.74C6.7,9.94,6.19,11.41,6.3,12.89c0.26,3.45,0.88,6.88,1.83,10.19
                            l0,0c0.42,1.48,1.4,2.66,2.74,3.34c0.75,0.38,1.57,0.56,2.4,0.56C13.93,26.98,14.59,26.86,15.22,26.62z M12.49,23.2
                            c-0.44-0.22-0.76-0.61-0.9-1.11c-0.88-3.08-1.45-6.26-1.7-9.47c-0.04-0.48,0.13-0.95,0.46-1.31c0.35-0.38,0.82-0.58,1.34-0.58
                            h30.62c0.29,0,0.54,0.2,0.59,0.46c0.03,0.22,0.07,0.43,0.11,0.65c0.05,0.26-0.11,0.53-0.38,0.63l-28.68,10.8
                            C13.45,23.44,12.93,23.42,12.49,23.2z"/>
                        <path fill="currentColor" d="M48.9,18.33c-1-1.53-2.99-2.08-4.63-1.28L15.87,31c-1.35,0.66-2.37,1.87-2.8,3.31
                            c-0.43,1.43-0.24,2.98,0.51,4.26c0.83,1.4,1.73,2.78,2.69,4.1c0.87,1.2,2.22,1.99,3.7,2.17c0.21,0.03,0.43,0.04,0.64,0.04
                            c1.28,0,2.53-0.45,3.51-1.29l24.11-20.5C49.62,21.91,49.92,19.88,48.9,18.33z M45.91,20.35L21.8,40.85
                            c-0.39,0.33-0.89,0.48-1.39,0.42c-0.49-0.06-0.93-0.31-1.21-0.71c-0.89-1.23-1.73-2.51-2.5-3.82c-0.25-0.42-0.31-0.92-0.17-1.4
                            c0.15-0.49,0.48-0.89,0.94-1.11l28.41-13.95l0.04,0.04C45.91,20.33,45.91,20.34,45.91,20.35z"/>
                        <path fill="currentColor" d="M54.59,23.08c-1.51-0.98-3.53-0.69-4.7,0.68L29.71,47.5c-0.98,1.15-1.43,2.66-1.25,4.16
                            c0.18,1.48,0.97,2.83,2.17,3.7c1.27,0.92,2.6,1.79,3.95,2.59c0.83,0.49,1.77,0.74,2.72,0.74c0.52,0,1.03-0.07,1.53-0.22
                            c1.44-0.43,2.65-1.45,3.31-2.8l13.73-27.95C56.69,26.07,56.14,24.09,54.59,23.08z M38.93,54.07c-0.23,0.46-0.62,0.79-1.11,0.94
                            c-0.48,0.14-0.97,0.08-1.39-0.17c-1.26-0.74-2.49-1.55-3.68-2.41c-0.4-0.29-0.65-0.72-0.71-1.21c-0.06-0.51,0.09-1.01,0.42-1.4
                            L52.64,26.1l0.02,0.02L38.93,54.07z"/>
                        <path fill="currentColor" d="M48.78,6.98c0,9.49,7.69,17.19,17.19,17.19V6.98H48.78z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const SwingAutoIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M62.16,26.67c-0.16-0.03-0.33-0.05-0.49-0.09c-2-0.4-3.98,0.71-4.7,2.64l-10.8,28.68
                            c-0.54,1.43-0.46,3.01,0.21,4.35c0.68,1.35,1.86,2.32,3.34,2.74v0c3.31,0.95,6.74,1.56,10.19,1.83c0.13,0.01,0.27,0.02,0.4,0.02
                            c1.34,0,2.64-0.51,3.63-1.43c1.1-1.02,1.74-2.47,1.74-3.98V30.82C65.67,28.76,64.16,26.98,62.16,26.67z"/>
                        <path fill="currentColor" d="M15.22,26.62l28.68-10.8c1.93-0.73,3.04-2.71,2.64-4.7c-0.03-0.16-0.06-0.33-0.09-0.49
                            c-0.31-2-2.09-3.51-4.14-3.51H11.69c-1.5,0-2.95,0.63-3.98,1.74C6.7,9.94,6.19,11.41,6.3,12.89c0.26,3.45,0.88,6.88,1.83,10.19
                            c0.42,1.47,1.4,2.66,2.74,3.34c0.75,0.38,1.57,0.57,2.4,0.57C13.93,26.98,14.59,26.86,15.22,26.62z"/>
                        <path fill="currentColor" d="M48.9,18.33c-1-1.53-2.99-2.08-4.63-1.28L15.87,31c-1.35,0.66-2.37,1.87-2.8,3.31
                            c-0.43,1.43-0.24,2.98,0.51,4.26c0.83,1.4,1.73,2.78,2.69,4.1c0.87,1.2,2.22,1.99,3.7,2.17c0.21,0.03,0.43,0.04,0.64,0.04
                            c1.28,0,2.53-0.45,3.51-1.29l24.11-20.5C49.62,21.91,49.92,19.88,48.9,18.33z"/>
                        <path fill="currentColor" d="M54.59,23.08c-1.51-0.98-3.53-0.69-4.7,0.68L29.71,47.5c-0.98,1.15-1.43,2.66-1.25,4.16
                            c0.18,1.48,0.97,2.83,2.17,3.7c1.27,0.92,2.6,1.79,3.95,2.59c0.83,0.49,1.77,0.74,2.72,0.74c0.52,0,1.03-0.07,1.53-0.22
                            c1.44-0.43,2.65-1.45,3.31-2.8l13.73-27.95C56.69,26.07,56.14,24.09,54.59,23.08z"/>
                        <path fill="currentColor" d="M48.78,6.98c0,9.49,7.69,17.19,17.19,17.19V6.98H48.78z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}