export const TrashIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M46.63,24.2H25.37c-2.98,0-5.4,2.42-5.4,5.4v31.64c0,2.98,2.42,5.4,5.4,5.4h21.25c2.98,0,5.4-2.42,5.4-5.4
                            V29.6C52.03,26.62,49.6,24.2,46.63,24.2z M48.43,61.24c0,0.99-0.81,1.8-1.8,1.8H25.37c-0.99,0-1.8-0.81-1.8-1.8V29.6
                            c0-0.99,0.81-1.8,1.8-1.8h21.25c0.99,0,1.8,0.81,1.8,1.8V61.24z"/>
                        <path fill="currentColor" d="M49.03,8.96H37.8V7.11c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8v1.85H22.97c-1.65,0-3,1.35-3,3v7.81
                            c0,1.65,1.35,3,3,3h26.05c1.65,0,3-1.35,3-3v-7.81C52.03,10.31,50.68,8.96,49.03,8.96z M48.43,19.18H23.57v-6.61h24.85V19.18z"/>
                        <path fill="currentColor" d="M31.02,33.71c-0.99,0-1.8,0.81-1.8,1.8v19.83c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8V35.51
                            C32.82,34.51,32.01,33.71,31.02,33.71z"/>
                        <path fill="currentColor" d="M40.98,33.71c-0.99,0-1.8,0.81-1.8,1.8v19.83c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8V35.51
                            C42.78,34.51,41.98,33.71,40.98,33.71z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}