import React, { useState } from 'react'
import TextInput from '../forms/TextInput'

export default function SelectIP({ onSumbitIp, onChange, defaultValue = ''}) {
    
    const [ipValue, setIpValue] = useState(defaultValue)

    const handleOnClick = (e) => {
        e.preventDefault()
        onSumbitIp(ipValue)
    }

    const handleOnChange = (label, value) => {
        setIpValue(value)
        onChange(value)
    }
    
    return (
        <div className="flex flex-col sm:flex-row items-end justify-center mt-4 mb-2 border-b pb-4">
            <TextInput label="" placeholder="Connect to IP" value={ipValue} onChange={handleOnChange} />
            
            <button 
                onClick={(e) => { handleOnClick(e) }}
                type="button" 
                className="
                    rounded-lg text-sm w-full border ml-2
                    sm:w-auto px-5 py-2.5 text-center mt-2 sm:mt-0
                ">Connect</button>
        </div>
    )
}