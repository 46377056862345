import { screenKeys } from '../constants/screenkeys.constant'
import { getModal, getScreen, updateScreen } from '../actions'
import store from '../store'
import { commandTypes } from '../constants'
import formatLegacyData from '../utils/formatLegacyData'
import { writeLog } from './log.service'
import { getAppSetting } from './settings.service'
import { getClient, setSocketStatus } from './socket.service'

class CommandService {
    constructor() {
        this.lastConnection = null
        this.client = null
        this.backStack = []

        this.commandHeader = {
            addressFrom: [],
            addressTo: [],
            instruction: [],
            data: null
        }

        // '', 'SENDING', 'RECEIVING'
        this.commandStatus = ''
        this.commandQueue = []

        this.lastCommand = null
        this.homeCommand = null

        this.refreshCommandTimer = null
        this.refreshCommandInterval = 200

        this.refreshCommand = {
            command: [],
            commandType: null,
            commandData: ''
        }

        this.shouldSendRefreshCommand = true
    }

    init() {
        this.client = getClient()
        if (!this.client) { throw new Error('Client failed to initialize') }
    }

    /** @public methods avaible to the application */
    setRefreshState(shouldRefresh) {
        this.shouldSendRefreshCommand = shouldRefresh
    }

    setRefreshRate(refreshRate) {
        this.refreshCommandInterval = refreshRate === 0 ? 200 : refreshRate
    }

    sendHomeCommand = () => {
        this.queueCommand(this.homeCommand)
    }

    sendBackCommand() {

        if (this.backStack.length > 1) {
            this.backStack.pop()
            this.queueCommand(this.backStack[this.backStack.length - 1])
            return
        }

        if (this.backStack.length < 2) {
            this.backStack = []
            this.sendHomeCommand()
            return
        }
    }

    queueCommand(command, data = '') {
        this.commandQueue = { command, data }
    }

    /** @private */

    // command queue
    dequeueCommand() {
        return this.commandQueue = null
    }

    processQueue() {
        this.queueTimer = setInterval(() => {
            setSocketStatus(this.shouldSendRefreshCommand ? 'REFRESHING' : 'REFRESH_PAUSED')

            if (this.commandStatus !== 'RECEIVING') {
                if (this.commandQueue) {
                    this.sendCommand(this.commandQueue.command, this.commandQueue.data)
                }

                if (!this.commandQueue) {
                    if (this.shouldSendRefreshCommand && this.refreshCommand.commandType !== null) {
                        this.sendCommand(this.refreshCommand, '', true)
                    }
                }
            }
        }, this.refreshCommandInterval)
    }

    stopQueue() {
        clearInterval(this.queueTimer)
        this.refreshCommandInterval = null
    }

    destroyQueue() {
        this.stopQueue()
        this.commandQueue = null
    }

    // refresh command
    setRefreshCommand(refreshCommand) {
        this.refreshCommand.command = refreshCommand.command
        this.refreshCommand.commandType = refreshCommand.commandType
        this.refreshCommand.commandData = ''
    }

    // command configuration
    setCommandHeader(discoveryObject) {
        discoveryObject.addressTo.push(4, 1)
        this.commandHeader.addressTo = discoveryObject.addressFrom
        this.commandHeader.addressFrom = discoveryObject.addressTo
        this.commandHeader.instruction = discoveryObject.instruction
    }

    // command handlers
    sendCommand(commandObject, data = '', isRefresh = false) {
        if (this.commandStatus !== 'RECEIVING') {
            this.commandStatus = 'SENDING'

            this.commandHeader.addressTo = commandObject.command
            this.commandHeader.instruction = commandObject.commandType
            this.commandHeader.data = data ? data : commandObject.commandData

            if (isRefresh) {
                this.client.send(JSON.stringify({ ataTouchMessage: this.commandHeader }))
            }

            if (!isRefresh) {
                this.lastCommand = commandObject
                this.commandStatus = 'RECEIVING'
                this.client.send(JSON.stringify({ ataTouchMessage: this.commandHeader }))
            }

            this.dequeueCommand()
            setTimeout(() => { this.commandStatus = '' }, 500)
        }
    }

    recieveCommand(data) {
        this.commandStatus = ''

        const primaryKey = Object.keys(data.data)[0]

        console.log("Received new data: " + (JSON.stringify(data)))

        if (!primaryKey) {
            writeLog('Error', `Invalid Command`, data)
            return
        }

        // update the refresh command
        if (data.data[primaryKey]?.refresh) {
            // writeLog('System', `Refresh command updated: ${primaryKey}`, data.data[primaryKey]?.refresh)
            this.setRefreshCommand(data.data[primaryKey]?.refresh)
            this.setRefreshRate(data.data[primaryKey]?.refreshRate)

        }

        // is it a modal
        if (primaryKey === 'popUpV3Info') {
            writeLog('System', `Received new modal: ${primaryKey}`, data)
            console.log("Received new modal:" + JSON.stringify(data))
            store.dispatch(getModal(data.data))
            return
        }

        // is it a new screen
        if (primaryKey) {
            let isImplementedScreen = false

            for (const [type, value] of Object.entries(screenKeys)) {

                if (value.includes(primaryKey) && primaryKey !== 'UserInfo') {
                    writeLog('System', `Received new screen: ${primaryKey}`, data)

                    // implements an old screen
                    if (type === 'legacy') {
                        const legacyData = formatLegacyData(data.data)
                        store.dispatch(getScreen(legacyData))
                    } else {
                        store.dispatch(getScreen(data.data))
                    }

                    isImplementedScreen = true
                    break
                }

                for (let i = 0; i < value.length; i++) {
                    if (value[i] + 'Info' === primaryKey) {
                        store.dispatch(updateScreen(data.data))
                        break
                    }
                }
            }

            if (isImplementedScreen) {
                const alreadyInStack = this.backStack.filter(i => i.command.join(',') === this.lastCommand.command.join(',')).length > 0
                if (!alreadyInStack) { this.backStack.push(this.lastCommand) }
            }
        }
    }

    setHomeCommand(command) {
        this.homeCommand = command
    }
}

let serviceInstance = null

export const setCommandService = () => {
    serviceInstance = new CommandService()

    return serviceInstance
}

export const getCommandService = () => {
    return serviceInstance
}

