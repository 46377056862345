const Auth = (state = null, action) => {
	const { type, payload } = action

	switch (type) {
		case 'LOGIN_SUCCESS':
			return {
				...state,
				...payload
			}
		case 'LOGIN_FAIL':
			return {
				...state
			}
        case 'LOGOUT_SUCCESS':
            return null
		case 'LOGOUT_FAIL':
            return null
		default:
			return state
	}
}

export default Auth