window['pwaUpdateReady'] = false

export function register(config) {
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    
    if (publicUrl.origin !== window.location.origin) {
      return
    }

    window.addEventListener('load', () => {
      const swUrl = `./service-worker.js`
      
        registerValidSW(swUrl, {
          onUpdate: registration => {
            window.pwaUpdateReady = true
          }
        })
    })
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        console.warn('update found')

        const installingWorker = registration.installing
        
        if (installingWorker == null) {
          return
        }

        installingWorker.onstatechange = () => {
          console.warn('state chanaged: ', installingWorker.state)

          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.'
              )

              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              console.log('Content is cached for offline use.')

              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }
        }
      }
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error)
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => {
        console.error(error.message)
      })
  }
}

export function forceUpdate(newVersion = null) {
  if ('serviceWorker' in navigator && window.pwaUpdateReady) {
    const registration = navigator.serviceWorker

    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }

    if (newVersion) {
      localStorage.setItem('installedVersion', newVersion)
    }
    
    window.location.reload()
  }
}
  