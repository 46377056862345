import React, { useEffect, useState } from 'react'
import SelectIP from '../components/auth/SelectIP'
import SelectProperty from '../components/auth/SelectProperty'
import DiscoverNetwork from '../components/auth/DiscoverNetwork'
import NetworkType from '../components/auth/NetworkType'
import { FragmentLoader } from '../components/Loaders'

export default function ConnectView({ connectionService }) {
    const [selectedIP, setSelectedIP] = useState('')
    const [manualConnect, setManualConnect] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [connectFailed, setConnectFailed] = useState(false)    
    const [DCconnectFailed, setDCconnectFailed] = useState(false)
    const [DCconnectFailedReason, setDCconnectFailedReason] = useState(false)
    const [connectingMsg, setConnectingMsg] = useState('')

    const handleOnConnectToIp = (IP) => {
        setSelectedIP(IP)
        if (IP) {
            setConnecting(true)
            connectionService.connect(IP, handleConnectionFailure)
        } 
    }

    const handleDCconnectionFailure = (err) => {
        setDCconnectFailedReason(err)
        setConnecting(false)
        setDCconnectFailed(true)
    }

    const handleOnConnectToDC = (dc) => {
        setSelectedIP(dc.dcName)
        setConnectingMsg(`connecting to ${dc.dcName} ...`)
        setConnecting(true)
        connectionService.connect(dc, handleDCconnectionFailure)
    }
    
    const handleConnectionFailure = (err) => {
        setConnecting(false)
        setConnectFailed(true)
    }

    const handleOnChangeIp = (IP) => {
        setSelectedIP(IP)
    }

    const handleNetworkstateChanged = (networkType, manualConnect) => {
        setManualConnect(manualConnect)
    }

    if (connecting) {
        return <FragmentLoader message={connectingMsg} />
    }

    return (<>
        <div className="w-full justify-center">
            
            <div className="w-full mx-auto p-2 pb-[55px]">
                
                {
                    manualConnect ?
                        <SelectIP 
                            onSumbitIp={handleOnConnectToIp} 
                            defaultValue={selectedIP} 
                            onChange={handleOnChangeIp}
                        /> : ''
                }  

                {
                    connectFailed ? <div className="text-ata-d-burnt-sienna text-center text-sm">
                        Failed to connect to: {selectedIP}, please check the IP address and port number is valid.
                    </div> : ''
                }

                {
                    DCconnectFailed ? <div className="text-ata-d-burnt-sienna text-center text-sm">
                        {DCconnectFailedReason}
                    </div> : ''
                }

                {
                    manualConnect ?
                        <SelectProperty 
                            onSelectProperty={handleOnConnectToIp}
                            currentIp={selectedIP} 
                        />
                        : <DiscoverNetwork
                            connectionService={connectionService}   
                            onSelectDevice={handleOnConnectToDC} 
                        /> 
                }
            </div>
            <div className="p-2 pb-0 fixed bottom-0 left-0 w-screen bg-ata-a-white dark:bg-ata-d-dark-liver">
                <NetworkType onNetworkstateChanged={handleNetworkstateChanged} />
            </div>
        </div>

    </>)
}