export const UnderFloorHeatingIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M65.47,35.59c-0.07-0.09-0.14-0.18-0.22-0.26l-3.61-3.48c-0.57-0.7-1.42-1.11-2.33-1.11H12.68
                            c-0.9,0-1.76,0.41-2.33,1.11l-3.62,3.48c-0.08,0.08-0.16,0.17-0.22,0.26c-0.65,0.92-0.73,2.11-0.21,3.11
                            c0.52,1,1.54,1.62,2.66,1.62h54.05c1.12,0,2.15-0.62,2.66-1.62C66.19,37.7,66.11,36.51,65.47,35.59z M10.49,36.72l2.46-2.36
                            c0,0,0,0,0.01-0.01h46.08c0,0,0,0,0.01,0.01l2.46,2.36H10.49z"/>
                        <path fill="currentColor" d="M53.84,44.19c-0.45-0.88-1.54-1.24-2.42-0.78c-0.88,0.45-1.23,1.54-0.78,2.42l5.46,10.64
                            c0.41,0.85,0.05,1.59-0.13,1.87c-0.4,0.63-1.08,1.01-1.81,1.01c-0.84,0-1.59-0.53-1.9-1.34l-4.6-11.76
                            c-0.68-1.82-2.44-3.04-4.38-3.04h-0.12c-2.58,0-4.67,2.1-4.67,4.67v8.92c0,1.41-1.15,2.55-2.55,2.55c-1.41,0-2.55-1.15-2.55-2.55
                            v-8.92c0-2.58-2.1-4.67-4.67-4.67c-1.93,0-3.69,1.21-4.36,2.99l-4.69,11.83c-0.3,0.79-1.06,1.32-1.91,1.32
                            c-0.72,0-1.34-0.35-1.72-0.95c-0.38-0.61-0.42-1.32-0.13-1.93l5.4-10.65c0.45-0.89,0.1-1.97-0.79-2.42
                            c-0.88-0.45-1.97-0.1-2.42,0.79l-5.42,10.68c-0.84,1.76-0.73,3.79,0.31,5.44c1.04,1.65,2.82,2.64,4.77,2.64
                            c2.33,0,4.45-1.47,5.26-3.62l4.69-11.83c0.16-0.41,0.56-0.69,1-0.69c0.59,0,1.07,0.48,1.07,1.07v8.92c0,3.39,2.76,6.15,6.15,6.15
                            c3.39,0,6.15-2.76,6.15-6.15v-8.92c0-0.59,0.48-1.07,1.07-1.07h0.12c0.44,0,0.85,0.28,1.01,0.72l4.6,11.76
                            c0.81,2.19,2.93,3.65,5.26,3.65c1.98,0,3.8-1.01,4.86-2.7c1.03-1.63,1.15-3.63,0.31-5.39L53.84,44.19z"/>
                        <path fill="currentColor" d="M19.24,19.61c0.91,1.59,0.91,3.56,0,5.15l-0.42,0.73c-0.49,0.86-0.19,1.96,0.67,2.46
                            c0.28,0.16,0.59,0.24,0.89,0.24c0.63,0,1.23-0.33,1.57-0.91l0.42-0.73c1.54-2.69,1.54-6.03,0-8.72l-0.84-1.46
                            c-0.91-1.59-0.91-3.56,0-5.15l0.42-0.73c0.49-0.86,0.19-1.96-0.67-2.46c-0.86-0.49-1.96-0.19-2.46,0.67L18.4,9.43
                            c-1.54,2.69-1.54,6.03,0,8.72L19.24,19.61z"/>
                        <path fill="currentColor" d="M34.86,19.61c0.91,1.59,0.91,3.56,0,5.15l-0.42,0.73c-0.49,0.86-0.19,1.96,0.67,2.46
                            c0.28,0.16,0.59,0.24,0.89,0.24c0.63,0,1.23-0.33,1.57-0.91l0.42-0.73c1.54-2.69,1.54-6.03,0-8.72l-0.84-1.46
                            c-0.91-1.59-0.91-3.56,0-5.15l0.42-0.73c0.49-0.86,0.19-1.96-0.67-2.46c-0.86-0.49-1.96-0.19-2.46,0.67l-0.42,0.73
                            c-1.54,2.69-1.54,6.03,0,8.72L34.86,19.61z"/>
                        <path fill="currentColor" d="M50.48,19.61c0.91,1.59,0.91,3.56,0,5.15l-0.42,0.73c-0.49,0.86-0.19,1.96,0.67,2.46
                            c0.28,0.16,0.59,0.24,0.89,0.24c0.62,0,1.23-0.33,1.56-0.91l0.42-0.73c1.54-2.69,1.54-6.03,0-8.72l-0.84-1.46
                            c-0.91-1.59-0.91-3.56,0-5.15l0.42-0.73c0.49-0.86,0.19-1.96-0.67-2.46c-0.86-0.49-1.96-0.19-2.46,0.67l-0.42,0.73
                            c-1.54,2.69-1.54,6.03,0,8.72L50.48,19.61z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const UnderTileHeatingIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M54.17,62.95c-2.33,0-4.45-1.47-5.26-3.65l-4.6-11.76c-0.17-0.44-0.57-0.72-1.01-0.72h-0.12
                            c-0.59,0-1.07,0.48-1.07,1.07v8.92c0,3.39-2.76,6.15-6.15,6.15c-3.39,0-6.15-2.76-6.15-6.15v-8.92c0-0.59-0.48-1.07-1.07-1.07
                            c-0.44,0-0.85,0.28-1,0.69l-4.69,11.83c-0.81,2.15-2.93,3.62-5.26,3.62c-1.95,0-3.73-0.98-4.77-2.63
                            c-1.04-1.65-1.15-3.68-0.31-5.44L18.1,44.2c0.45-0.89,1.53-1.24,2.42-0.79c0.89,0.45,1.24,1.53,0.79,2.42l-5.4,10.65
                            c-0.29,0.61-0.25,1.33,0.13,1.93s1.01,0.95,1.72,0.95c0.84,0,1.61-0.53,1.91-1.32l4.69-11.83c0.67-1.78,2.43-2.99,4.36-2.99
                            c2.58,0,4.67,2.1,4.67,4.67v8.92c0,1.41,1.15,2.55,2.55,2.55c1.41,0,2.55-1.15,2.55-2.55v-8.92c0-2.58,2.1-4.67,4.67-4.67h0.12
                            c1.94,0,3.7,1.22,4.38,3.04l4.6,11.76c0.3,0.81,1.06,1.34,1.9,1.34c0.74,0,1.42-0.38,1.82-1.01c0.18-0.28,0.54-1.02,0.13-1.87
                            l-5.46-10.64c-0.45-0.88-0.1-1.97,0.78-2.42c0.89-0.45,1.97-0.1,2.42,0.78l5.48,10.68c0.84,1.76,0.72,3.75-0.31,5.39
                            C57.97,61.95,56.15,62.95,54.17,62.95z"/>
                        <g>
                            <path fill="currentColor" d="M20.39,28.19c-0.3,0-0.61-0.08-0.89-0.24c-0.86-0.49-1.16-1.59-0.67-2.46l0.42-0.73
                                c0.91-1.59,0.91-3.56,0-5.15l-0.84-1.47c-1.54-2.69-1.54-6.03,0-8.72l0.42-0.73c0.49-0.86,1.59-1.16,2.46-0.67
                                c0.86,0.49,1.16,1.59,0.67,2.46l-0.42,0.73c-0.91,1.59-0.91,3.56,0,5.15l0.84,1.46c1.54,2.69,1.54,6.03,0,8.72l-0.42,0.73
                                C21.62,27.86,21.01,28.19,20.39,28.19z"/>
                            <path fill="currentColor" d="M36.01,28.19c-0.3,0-0.61-0.08-0.89-0.24c-0.86-0.49-1.16-1.59-0.67-2.46l0.42-0.73
                                c0.91-1.59,0.91-3.56,0-5.15l-0.84-1.46c-1.54-2.69-1.54-6.03,0-8.72l0.42-0.73c0.49-0.86,1.59-1.16,2.46-0.67
                                c0.86,0.49,1.16,1.59,0.67,2.46l-0.42,0.73c-0.91,1.59-0.91,3.56,0,5.15l0.84,1.46c1.54,2.69,1.54,6.03,0,8.72l-0.42,0.73
                                C37.24,27.86,36.63,28.19,36.01,28.19z"/>
                            <path fill="currentColor" d="M51.63,28.19c-0.3,0-0.61-0.08-0.89-0.24c-0.86-0.49-1.16-1.59-0.67-2.46l0.42-0.73
                                c0.91-1.59,0.91-3.56,0-5.15l-0.84-1.47c-1.54-2.69-1.54-6.03,0-8.72l0.42-0.73c0.49-0.86,1.59-1.16,2.46-0.67
                                c0.86,0.49,1.16,1.59,0.67,2.46l-0.42,0.73c-0.91,1.59-0.91,3.56,0,5.15l0.84,1.46c1.54,2.69,1.54,6.03,0,8.72l-0.42,0.73
                                C52.86,27.86,52.25,28.19,51.63,28.19z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M42.33,40.32H29.69c-0.9,0-1.75-0.4-2.32-1.1c-0.57-0.7-0.8-1.61-0.62-2.49l0.72-3.57
                                c0.28-1.4,1.52-2.41,2.94-2.41h11.21c1.42,0,2.66,1.01,2.94,2.41l0.72,3.57c0.18,0.88-0.05,1.79-0.62,2.49
                                C44.08,39.91,43.23,40.32,42.33,40.32z M41.74,37.43L41.74,37.43L41.74,37.43z M30.42,36.72H41.6l-0.48-2.37H30.9L30.42,36.72z"
                                />
                            <path fill="currentColor" d="M63.02,40.32H50.96c-1.56,0-2.85-1.2-2.99-2.73l-0.78-3.11c-0.23-0.9-0.03-1.84,0.54-2.58
                                c0.57-0.73,1.43-1.16,2.37-1.16h9.33c0.79,0,1.57,0.32,2.12,0.88l3.72,3.73c0.07,0.07,0.14,0.15,0.2,0.24
                                c0.65,0.92,0.73,2.11,0.21,3.11C65.17,39.7,64.15,40.32,63.02,40.32z M51.47,36.72h10.09l-2.36-2.37h-8.32L51.47,36.72z"/>
                            <path fill="currentColor" d="M21.04,40.32H8.98c-1.12,0-2.14-0.62-2.66-1.62c-0.52-1-0.44-2.19,0.21-3.11c0.06-0.08,0.13-0.16,0.2-0.24
                                l3.72-3.73c0.56-0.56,1.33-0.88,2.12-0.88h9.33c0.93,0,1.79,0.42,2.37,1.16c0.57,0.73,0.77,1.67,0.54,2.58l-0.78,3.11
                                C23.89,39.11,22.6,40.32,21.04,40.32z M10.45,36.72h10.09l0.6-2.37h-8.32L10.45,36.72z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}