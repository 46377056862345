let client = null
let socketStatus = 'INIT'

/**
 * statuses - INIT, OPEN, REFRESHING, CLOSED, ERROR, REFRESH_PAUSED
 */
 export const setSocketStatus = (status, isLocal) => {
     socketStatus = status
 }
  
 export const getSocketStatus = () => {
     return socketStatus
 }

export const getClient = () => {
    return client
}

export const setClient = (ip, isLocal) => {
    if (client) { 
        client.close()
        client = null
    }
    const protcol = (isLocal || process.env.REACT_APP_USE_SSL === 'false') ? 'ws' : 'wss'

    client = new WebSocket(`${protcol}://${ip}`) 
    return client
}