export const CameraIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M60.6,19.56h-9.69l-1.33-4.89c-0.64-2.34-2.78-3.98-5.21-3.98H27.64c-2.43,0-4.57,1.64-5.21,3.98l-1.33,4.89
                            H11.4c-2.98,0-5.4,2.42-5.4,5.4v31.09c0,2.98,2.42,5.4,5.4,5.4H60.6c2.98,0,5.4-2.42,5.4-5.4V24.96
                            C66,21.98,63.57,19.56,60.6,19.56z M25.9,15.62c0.21-0.78,0.93-1.33,1.74-1.33h16.72c0.81,0,1.52,0.55,1.74,1.33l1.08,3.94H24.83
                            L25.9,15.62z M62.4,56.04c0,0.99-0.81,1.8-1.8,1.8H11.4c-0.99,0-1.8-0.81-1.8-1.8V24.96c0-0.99,0.81-1.8,1.8-1.8H60.6
                            c0.99,0,1.8,0.81,1.8,1.8V56.04z"/>
                        <path fill="currentColor" d="M36,27.59c-7.12,0-12.91,5.79-12.91,12.91S28.88,53.41,36,53.41s12.91-5.79,12.91-12.91
                            S43.12,27.59,36,27.59z M36,49.81c-5.13,0-9.31-4.18-9.31-9.31s4.18-9.31,9.31-9.31s9.31,4.18,9.31,9.31S41.13,49.81,36,49.81z"
                            />
                        <circle cx="55.21" cy="28.16" r="2.24" fill="currentColor"/>
                    </g>
                </g>
            </g>     
        </svg>
    )
}