import React from 'react'
import { useDispatch } from 'react-redux'

import { getCommandService } from '../../services/command.service'
import { setFormState } from '../../actions'

import TextInput from './TextInput'
import Select from './Select'
import Slider from './Slider'
import FormButton from './FormButton'
import DatePicker from './DatePicker'

/** @todo: out of scope for MVP */
// import Carousel from './Carousel'
// import DayPicker from './DayPicker'
// import TimePicker from './TimePicker'

export default function FormContainer({ formElements }) {
    const commandService = getCommandService()
    
    const dispatch = useDispatch()
    // const [loading, setLoading] = useState(true)

    const handleOnChange = (command, value, formKey = null) => {
        
        if (command) {
            commandService.queueCommand(command, value.toString())
            // unpause refresh
        } else if (formKey) {
            const stateFragment = {}
            stateFragment[formKey] = value

            dispatch(setFormState(stateFragment))
        } else {
            console.error('no form key or command')
        }
    }
    const handleOnClick = () => {}
    const handleOnSelect = () => {}

    const handleOnTouchStart = () => {
        // pause refresh
    }

    return (
        <form>
            <div className="'w-full pt-2 relative flex flex-wrap h-full overflow-y-auto">
                { 
                    formElements ?
                        formElements.map((el, idx) => {
                            const label = el.name

                            if (el.type === 'text') {
                                const value = el.control.text

                                return (
                                    <div key={idx} className="mb-2 p-2 w-1/2">
                                        <TextInput
                                            command={el.control.editLabel}
                                            label={label}
                                            formKey={el.name.replace(' ', '_').toLowerCase()}
                                            value={value}
                                            onClick={handleOnChange}
                                        />
                                    </div>
                                )
                            }

                            if (el.type === 'value') {
                                return (
                                    <div key={idx} className="mb-2 pt-2 min-w-1/2 w-full">
                                        <Slider
                                            command={el.command}
                                            label={el.label}
                                            value={el.value}
                                            min={el.min}
                                            max={el.max}
                                            onChange={handleOnChange}
                                            unit={el.unit}
                                            vertical={false}
                                            showInput={el.useSliders}
                                            showButtons={true}
                                            enabled={el.enabled}
                                        />
                                    </div>
                                )
                            }

                            if (el.type === 'buttons') {
                                return el.buttons.map((b, i) => {
                                    return (
                                        <div key={i} className="mb-2 pt-2 w-1/6">
                                            <FormButton 
                                                button={b} 
                                                onChange={handleOnClick} 
                                            />
                                        </div>
                                    )
                                })
                            }

                            if (el.type === 'button') {
                                return (
                                    <div key={idx} className="mb-2 pt-2 w-1/6">
                                        <FormButton 
                                            label={label} 
                                            value={{control: el}} 
                                            onChange={handleOnClick} 
                                        />
                                    </div>
                                )
                            }

                            if (el.type === 'options') {
                                const mapOptions = el.control.map((i,idx) => { 
                                    return {
                                        id: idx, 
                                        value: i.text, 
                                        label: i.text, 
                                        selected: i.selected 
                                    } 
                                })
    
                                return (
                                    <div className="mb-2 pt-2 w-full" key={idx}>
                                        <Select
                                            label={label}
                                            options={mapOptions}
                                            multi={true}
                                            daySelector={true}
                                            onChange={handleOnSelect}
                                        />
                                    </div>
                                )    
                            }

                            if (el.type === 'datepicker') {
                                return (
                                    <div className="mb-2 pt-2 w-full" key={idx}>
                                        <DatePicker onChange={handleOnSelect} />
                                    </div>
                                )
                            }

                            return false
                    }) : 'No Form Elements' 
                }
            </div>
        </form>
    )
}