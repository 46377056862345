import { useEffect } from 'react'
import useMedia from './useMedia'
import useLocalStorage from './useLocalStorage'

function useDarkMode() {
    const prefersDarkMode = usePrefersDarkMode()
    const [isEnabled, setIsEnabled] = useLocalStorage('dark-mode', undefined)
  
    const enabled = isEnabled === undefined ? prefersDarkMode : isEnabled
  
    useEffect(() => {
      if (window === undefined) return

      const root = window.document.documentElement

      root.classList.remove(enabled ? 'light' : 'dark')
      root.classList.add(enabled ? 'dark' : 'light')
    }, [enabled])
  
    return [enabled, setIsEnabled]
}

function usePrefersDarkMode() {
  return useMedia(['(prefers-color-scheme: dark)'], [true], false);
}

export default useDarkMode