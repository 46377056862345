export const MirrorLightingIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,29.24c-0.99,0-1.8,0.81-1.8,1.8v8.54c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-8.54
                            C37.8,30.05,36.99,29.24,36,29.24z"/>
                        <path fill="currentColor" d="M45.12,29.74c-0.5-0.86-1.6-1.15-2.46-0.66c-0.86,0.5-1.16,1.6-0.66,2.46l4.27,7.4
                            c0.33,0.58,0.94,0.9,1.56,0.9c0.31,0,0.61-0.08,0.9-0.24c0.86-0.5,1.16-1.6,0.66-2.46L45.12,29.74z"/>
                        <path fill="currentColor" d="M58.01,30.71l-7.4-4.27c-0.86-0.5-1.96-0.2-2.46,0.66c-0.5,0.86-0.2,1.96,0.66,2.46l7.4,4.27
                            c0.28,0.16,0.59,0.24,0.9,0.24c0.62,0,1.23-0.32,1.56-0.9C59.17,32.31,58.88,31.21,58.01,30.71z"/>
                        <path fill="currentColor" d="M29.34,29.09c-0.86-0.5-1.96-0.2-2.46,0.66l-4.27,7.4c-0.5,0.86-0.2,1.96,0.66,2.46
                            c0.28,0.16,0.59,0.24,0.9,0.24c0.62,0,1.23-0.32,1.56-0.9l4.27-7.4C30.5,30.68,30.2,29.58,29.34,29.09z"/>
                        <path fill="currentColor" d="M23.84,27.1c-0.5-0.86-1.6-1.16-2.46-0.66l-7.4,4.27c-0.86,0.5-1.16,1.6-0.66,2.46
                            c0.33,0.58,0.94,0.9,1.56,0.9c0.31,0,0.61-0.08,0.9-0.24l7.4-4.27C24.04,29.06,24.34,27.96,23.84,27.1z"/>
                        <path fill="currentColor" d="M60.6,10.77H11.4c-2.98,0-5.4,2.42-5.4,5.4v39.8c0,2.98,2.42,5.4,5.4,5.4H60.6c2.98,0,5.4-2.42,5.4-5.4
                            v-39.8C66,13.19,63.57,10.77,60.6,10.77z M11.4,14.37H60.6c0.99,0,1.8,0.81,1.8,1.8v2.47H9.6v-2.47
                            C9.6,15.18,10.41,14.37,11.4,14.37z M60.6,57.77H11.4c-0.99,0-1.8-0.81-1.8-1.8V22.24H62.4v33.73
                            C62.4,56.96,61.59,57.77,60.6,57.77z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const MirrorDemisterIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M56.15,52.14c-0.99,0-1.8-0.81-1.8-1.8V25.83c0-1.23-1-2.23-2.23-2.23s-2.23,1-2.23,2.23v20.48
                            c0,3.21-2.62,5.83-5.83,5.83s-5.83-2.62-5.83-5.83V25.83c0-1.23-1-2.23-2.23-2.23s-2.23,1-2.23,2.23v20.48
                            c0,3.21-2.62,5.83-5.83,5.83s-5.83-2.62-5.83-5.83V25.83c0-1.23-1-2.23-2.23-2.23s-2.23,1-2.23,2.23v24.51
                            c0,0.99-0.81,1.8-1.8,1.8s-1.8-0.81-1.8-1.8V25.83c0-3.21,2.62-5.83,5.83-5.83s5.83,2.62,5.83,5.83v20.48
                            c0,1.23,1,2.23,2.23,2.23s2.23-1,2.23-2.23V25.83c0-3.21,2.62-5.83,5.83-5.83s5.83,2.62,5.83,5.83v20.48c0,1.23,1,2.23,2.23,2.23
                            s2.23-1,2.23-2.23V25.83c0-3.21,2.62-5.83,5.83-5.83s5.83,2.62,5.83,5.83v24.51C57.95,51.33,57.14,52.14,56.15,52.14z"/>
                        <path fill="currentColor" d="M60.6,61.37H11.4c-2.98,0-5.4-2.42-5.4-5.4v-39.8c0-2.98,2.42-5.4,5.4-5.4H60.6c2.98,0,5.4,2.42,5.4,5.4
                            v39.8C66,58.95,63.57,61.37,60.6,61.37z M11.4,14.37c-0.99,0-1.8,0.81-1.8,1.8v39.8c0,0.99,0.81,1.8,1.8,1.8H60.6
                            c0.99,0,1.8-0.81,1.8-1.8v-39.8c0-0.99-0.81-1.8-1.8-1.8H11.4z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}