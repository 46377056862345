import jwt_decode from 'jwt-decode'
import auth0 from 'auth0-js'
import SecureLS from 'secure-ls'

import { writeLog } from './log.service'
import { getAppSetting, saveAppSetting } from './settings.service'

const rememberMeStore = new SecureLS()

const auth0Client = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
    redirectUri: window.location.origin,
    scope: 'read:current_user update:current_user_metadata openid profile email',
    responseType: 'token id_token'
  })

export const login = (username, password, callback, errorCallback) => {
    const insecureRememberMe = getAppSetting('allow_insecure_remember_me')

    return auth0Client.client.login({
        realm: process.env.REACT_APP_AUTH0_DB_CONNECTION,
        username,
        password
      },(err, authResult) => {
        if (err) {
          writeLog('Error', 'Login Failed', err)
          errorCallback(err)
        }

        if (authResult) {
            writeLog('Success', 'User Logged In')
            localStorage.setItem('access_token', JSON.stringify(authResult))

            if (insecureRememberMe) {
                setInsecureRememberMe(username, password)
            }

            callback(authResult)
        }
      })
}

const setInsecureRememberMe = (username, password) => {
    rememberMeStore.set('remember_me', JSON.stringify({ u: username, p: password }))
}

export const getInsecureRememberMe = () => {
    return rememberMeStore.get('remember_me')
}

export const deleteInsecureRememberMe = () => {
    return rememberMeStore.removeAll()
}

export const logout = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('user')

    const url = buildLogoutUrl()
    const cookies = document.cookie.split(';')

    cookies.forEach((cookie) => {
        const eqPos = cookie.indexOf('=')
        const name = (eqPos > -1) ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    })

    writeLog('System', 'User Logged Out')

    const frame = document.createElement('iframe')
        frame.setAttribute('src', url)
        frame.style.width = '1px'
        frame.style.height = '1px'
        document.body.appendChild(frame)
        window.location.reload()
}

export const buildLogoutUrl = () => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN

    return `https://${domain}/v2/logout?
        returnTo=${window.location.origin}&clientID=${process.env.REACT_APP_AUTH0_CLIENT_ID}`
}

export const getUserProfile = async (callback) => {
    const token = JSON.parse(localStorage.getItem('access_token'))
    
    if (!token) {
        return null
    }

    auth0Client.client.userInfo(token.accessToken, (err, user) => {
        if (err) { 
            writeLog('Error', 'Failed to fetch user profile', err)
            return 
        }
        localStorage.setItem('user', JSON.stringify(user))
        writeLog('Success', 'fetched user profile', user)

        callback(user)
    })
}

export const isAuthenticated = () => {
    const token = JSON.parse(localStorage.getItem('access_token'))

    if (token) {
        const d_token = jwt_decode(token)
        const exp_date = d_token.exp
        const now = new Date().getTime() / 1000

        return (token && now < exp_date)
    }

    return false
}

export const resetPassword = async () => {
    const user = JSON.parse(localStorage.getItem('user'))

    await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({  
            email: user.email,
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
            connection: 'Username-Password-Authentication'
        })
    })

    return 'We\'ve sent a reset password link to your email address.'
}
