import React, { useEffect, useState } from 'react'
import MenuButton from './MenuButton'

export default function Sidebar(props) {
    const [sideBarItems, setSideBarItems] = useState([])

    useEffect(() => {
        setSideBarItems(props?.items)
    }, [props])

    return (
        <div 
            className={`
                flex items-left overflow-x-auto
                sm:items-center sm:border-l sm:flex-col sm:h-full sm:overflow-x-hidden
                border-l-ata-a-silver 
                ${props?.items.length < 4 ? 'justify-between' :'overflow-y-auto'}
            `}
        >
            { 
                sideBarItems ?
                    sideBarItems.map((b, index) => 
                        <div 
                            className="h-1/3 sidebar-item-xs-width py-1 sm:w-3/5" 
                            key={'sidebar-item-' + index}
                        >
                                <MenuButton context='sidebar' button={b} />
                        </div>
                    ) : 'No Menu Items' 
            }
        </div>
    )
}