import React, { useState, useEffect } from 'react'
import { deleteInsecureRememberMe } from '../services/auth.service'
import { deleteLogs } from '../services/log.service'
import { getInitalAppSettings, saveAppSetting, resetToDefaultSettings } from '../services/settings.service'

export default function SettingsView({ isLoggedIn }) {
    const cacheOptions = [
        {label: 'favorite connection', value: 'favorite_connection'},
        {label: 'event logs', value: 'event_logs'},
        {label: 'remember me', value: 'remember_me'}
    ]

    const [selectedCaches, setSelectedCaches] = useState([])
    const [settings, setAppSettings] = useState(getInitalAppSettings())

    const handleBooleanSetting = (key, e) => {
        const newSettings = {...settings}
        newSettings[key] = e.target.checked
        
        setAppSettings({...settings, ...newSettings})
        saveAppSetting(key, e.target.checked)
    }

    const handleInputSetting = (key, e) => {
        e.preventDefault()
        const newSettings = {...settings}
        newSettings[key] = e.target.value
        
        setAppSettings({...settings, ...newSettings})
        saveAppSetting(key, e.target.value)
    }

    const handleSelectCaches = (key, e) => {
        if (e.currentTarget.checked) {
            setSelectedCaches([...selectedCaches, key])
        } 
        if (!e.currentTarget.checked) {
            setSelectedCaches(selectedCaches.filter(i => i !== key)) 
        } 
    }

    const handleClearCaches = (e) => {
        e.preventDefault()

        if (selectedCaches.includes('event_logs')) {
            deleteLogs()
        }

        if (selectedCaches.includes('remember_me')) {
            deleteInsecureRememberMe()
        }

        selectedCaches.forEach((i) => {
            try {
                if (i !== 'event_logs') { localStorage.removeItem(i) } 
            } catch (error) {
                console.warn(`skipped cache ${i}, cache already empty`, error)
            }
        })
    }

    const handleResetToDefault = (e) => {
        resetToDefaultSettings()
        setAppSettings(getInitalAppSettings())
    }

    const checkSelected = (key) => {
        return selectedCaches.includes(key)
    }

    useEffect(()=> { setSelectedCaches(cacheOptions.map(i => i.value)) }, [])
    
    return (
        <div className="w-full p-4">
            <div className="mb-4">
                    <h1 className="font-bold text-lg border-b pb-2 mb-4">Caching</h1>
                    {
                        cacheOptions.map(option => {
                            return (
                                <div className="flex w-full items-center justify-left pr-2 my-1" key={option.value}>
                                    <input 
                                        onChange={(e) => { handleSelectCaches(option.value, e) }} 
                                        id={option.value}  
                                        type="checkbox"
                                        checked={ checkSelected(option.value) }
                                        className="
                                            w-4 h-4 accent-ata-a-outer-space-crayola dark:accent-ata-d-cultured  
                                            rounded border-ata-a-outer-space-crayola dark:border-ata-d-cultured
                                        " 
                                    />
                                    <label htmlFor={option.value}  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        {option.label}
                                    </label>
                                </div>
                            )
                        })
                    }
                    <button
                        onClick={(e) => { handleClearCaches(e) }} 
                        type="button" 
                        className="mt-4 w-full border border-ata-d-burnt-sienna text-ata-d-burnt-sienna font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                            Clear Caches
                    </button>

            </div>

            <div className="mb-4">      
                <h1 className="font-bold text-lg border-b pb-2 mb-4">Event Logging</h1>

                <div className="log-events-options px-2">
                    <label htmlFor="log_events" className="inline-flex relative items-center cursor-pointer">
                        <input 
                            type="checkbox" 
                            value="log_events" 
                            checked={settings.log_events} 
                            id="log_events" 
                            className="sr-only peer" 
                            onChange={(e) => handleBooleanSetting('log_events', e) } 
                        />
                        <div className="
                            w-11 h-6 rounded-full
                            peer peer-focus:outline-none 
                            peer-checked:after:translate-x-full peer-checked:after:border-white 
                            after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                            dark:border-ata-d-cultured peer-checked:bg-blue-600 border
                        "></div>
                        <span className="flex-1 ml-3 whitespace-nowrap font-medium text-ata-a-outer-space-crayola dark:text-ata-d-cultured">Log Events</span>
                    </label>
                </div>

                <div className="send-events-options px-2">
                    <label htmlFor="send_events" className="inline-flex relative items-center cursor-pointer">
                        <input 
                            type="checkbox" 
                            value="" 
                            checked={settings.send_events} 
                            id="send_events" 
                            className="sr-only peer"
                            onChange={(e) => handleBooleanSetting('send_events', e) } 
                        />
                        <div className="
                            w-11 h-6 rounded-full
                            peer peer-focus:outline-none 
                            peer-checked:after:translate-x-full peer-checked:after:border-white 
                            after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                            dark:border-ata-d-cultured peer-checked:bg-blue-600 border
                        "></div>
                        <span className="flex-1 ml-3 whitespace-nowrap font-medium text-ata-a-outer-space-crayola dark:text-ata-d-cultured">Send Events</span>
                    </label>
                </div>
            </div>


            <div className="mb-4">      
                <h1 className="font-bold text-lg border-b pb-2 mb-4">Connections</h1>

                <div className="send-events-options px-2">
                    <label htmlFor="auto_connect" className="inline-flex relative items-center cursor-pointer">
                        <input 
                            type="checkbox" 
                            value="auto_connect" 
                            checked={settings.auto_connect} 
                            id="auto_connect" 
                            className="sr-only peer"
                            onChange={(e) => handleBooleanSetting('auto_connect', e) } 
                        />
                        <div className="
                            w-11 h-6 rounded-full
                            peer peer-focus:outline-none 
                            peer-checked:after:translate-x-full peer-checked:after:border-white 
                            after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                            dark:border-ata-d-cultured peer-checked:bg-blue-600 border
                        "></div>
                        <span className="flex-1 ml-3 whitespace-nowrap font-medium text-ata-a-outer-space-crayola dark:text-ata-d-cultured">Enable auto connect</span>
                    </label>
                </div>

                <div className="pt-4">
                    <label htmlFor="dc_timeout" className="block mb-2 text-sm font-medium">Connect to DC Server timeout (ms)</label>
                    <input 
                        type="number" 
                        id="dc_timeout" 
                        value={settings.dc_timeout}
                        className="border text-sm rounded-lg block w-full p-2.5" 
                        placeholder="[0-9]" 
                        onChange={ (e) => { handleInputSetting('dc_timeout', e) } }
                        required
                    />
                </div>

                <div className="pt-4">
                    <label htmlFor="hmi_timeout" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Connect to HMI timeout (ms)</label>
                    <input 
                        type="number" 
                        id="hmi_timeout"
                        value={settings.hmi_timeout} 
                        className="border text-sm rounded-lg block w-full p-2.5" 
                        placeholder="[0-9]"
                        onChange={ (e) => { handleInputSetting('hmi_timeout', e) } }
                        required 
                    />
                </div>

                <div className="py-4">
                    <label htmlFor="refresh_interval" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Screen Refresh Interval (ms)</label>
                    <input 
                        type="number" 
                        id="refresh_interval"
                        value={settings.refresh_interval} 
                        className="border text-sm rounded-lg block w-full p-2.5"
                        onChange={ (e) => { handleInputSetting('refresh_interval', e) } } 
                        placeholder="[0-9]"
                        required 
                    />
                </div>
            </div>    

            <div className="mb-4">      
                <h1 className="font-bold text-lg border-b pb-2 mb-4">Developer</h1>

                <div className="log-events-options px-2">
                    <label htmlFor="allow_insecure_remember_me" className="inline-flex relative items-center cursor-pointer">
                        <input
                            type="checkbox" 
                            value="allow_insecure_remember_me" 
                            checked={settings.allow_insecure_remember_me} 
                            id="allow_insecure_remember_me" 
                            className="sr-only peer"
                            onChange={ (e) => handleBooleanSetting('allow_insecure_remember_me', e) } 
                        />
                        <div className="
                            w-11 h-6 rounded-full
                            peer peer-focus:outline-none 
                            peer-checked:after:translate-x-full peer-checked:after:border-white 
                            after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                            dark:border-ata-d-cultured peer-checked:bg-blue-600 border
                        "></div>
                        <span className="flex-1 ml-3 whitespace-nowrap font-medium text-ata-a-outer-space-crayola dark:text-ata-d-cultured">
                            Allow insecure remember me
                        </span>
                    </label>
                    <small className="text-xs block w-full">Probably don't do this but if you do, reset your password afterwards</small>
                </div>

            </div>

            <button
                onClick={(e) => { handleResetToDefault(e) }} 
                type="button" 
                className="mt-4 w-full border border-ata-d-burnt-sienna text-ata-d-burnt-sienna font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                    Reset to defaults
            </button>
       
        </div>
    )
}