export const RefreshCircleIcon = () => {
    return (
		<svg viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			 className="w-full" fill="none" stroke="currentColor" strokeWidth={0}>

			<g>
				
				<g>
					<g>
						<path fill="currentColor" d="M36,6.18c-16.48,0-29.89,13.41-29.89,29.89S19.52,65.96,36,65.96s29.89-13.41,29.89-29.89
							S52.48,6.18,36,6.18z M36,62.36c-14.5,0-26.29-11.8-26.29-26.29S21.5,9.78,36,9.78s26.29,11.8,26.29,26.29S50.5,62.36,36,62.36z"
							/>
						<path fill="currentColor" d="M36.01,16.49c-6.29,0-12.12,2.89-15.93,7.81l-0.73-2.72c-0.26-0.96-1.25-1.53-2.2-1.27
							c-0.96,0.26-1.53,1.24-1.27,2.2l1.26,4.71c0.5,1.85,2.18,3.08,4.01,3.08c0.35,0,0.72-0.05,1.07-0.14l4.71-1.26
							c0.96-0.26,1.53-1.24,1.27-2.2c-0.26-0.96-1.25-1.53-2.2-1.27l-2.81,0.75c3.13-3.84,7.8-6.08,12.83-6.08
							c9.14,0,16.57,7.43,16.57,16.57s-7.44,16.57-16.57,16.57c-6.89,0-13.14-4.34-15.54-10.8c-0.35-0.93-1.38-1.41-2.31-1.06
							c-0.93,0.35-1.41,1.38-1.06,2.31c2.92,7.86,10.52,13.15,18.92,13.15c11.12,0,20.17-9.05,20.17-20.17S47.14,16.49,36.01,16.49z"/>
					</g>
				</g>
			</g>
		</svg>
    )
}

export const RefreshIcon = () => {
    return (
		<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
			<g>
				
				<g>
					<path fill="currentColor" d="M36,6.18c-9.99,0-19.19,4.92-24.77,13.17L9.6,13.25c-0.26-0.96-1.25-1.53-2.2-1.27
						c-0.96,0.26-1.53,1.24-1.27,2.2l1.93,7.2c0.37,1.39,1.27,2.56,2.52,3.28c0.83,0.48,1.76,0.73,2.69,0.73
						c0.47,0,0.94-0.06,1.41-0.19l7.2-1.93c0.96-0.26,1.53-1.24,1.27-2.2c-0.26-0.96-1.24-1.53-2.2-1.27l-6.91,1.85
						C18.91,14.21,27.1,9.78,36,9.78c14.5,0,26.29,11.8,26.29,26.29S50.5,62.36,36,62.36c-10.94,0-20.85-6.89-24.65-17.13
						C11,44.3,9.96,43.82,9.03,44.17c-0.93,0.35-1.41,1.38-1.06,2.31C12.3,58.14,23.57,65.96,36,65.96c16.48,0,29.89-13.41,29.89-29.89
						S52.48,6.18,36,6.18z"/>
				</g>
			</g>
		</svg>
	)
}
