import React, { useState, useEffect } from 'react'
import DayPicker from './DayPicker'

import { dayLabels } from '../../constants'

export default function Select({
    label, 
    options, 
    multi, 
    daySelector,
    showSelect = false, 
    onSelect
}) {
    const [optionValues, setValues] = useState(options)
    let selectedValues = optionValues.filter(i => i.selected).map(i => i.id)

    useEffect(() => {
        setValues(options)
    }, [options])

    const onSelectDayPicker = (dayOptions) => {
        setValues(dayOptions)
        selectedValues = optionValues.filter(i => i.selected).map(i => i.value)
    }

    const handleOnChange = (e) => {
        const ov = [...optionValues]
        const indexToUpdate = ov.findIndex((i) => i.id === parseInt(e.target.value))

        if (multi) {
            ov[indexToUpdate].selected = !ov[indexToUpdate].selected
        } else {
            ov.map((i, idx) => {
                i.selected = (idx === indexToUpdate)
                return i
            })
        }
        
        setValues(ov)

        selectedValues = optionValues.filter(i => i.selected).map(i => i.value)
        onSelect(selectedValues)
    }

    return (
        <div>
            <label 
                htmlFor={label} 
                className="
                    block mb-2 text-sm font-medium 
                    dark:text-ata-d-cultured"
                >
                    {label}
                </label>
            { 
                daySelector 
                    ? <DayPicker onSelect={onSelectDayPicker} options={optionValues} /> 
                    : ''
            }

            {
                showSelect ?
                    <select
                        size={multi ? optionValues.length : 1}
                        onChange={(e) => { handleOnChange(e) }}
                        value={(multi) ? selectedValues : selectedValues[0]} 
                        multiple={multi} 
                        id={label} 
                        className="
                            h-auto border text-sm rounded-lg block w-full py-1 px-2.5
                            focus:ring-blue-500 
                            focus:border-blue-500  
                            dark:bg-ata-d-liver
                            dark:border-ata-d-cultured
                            dark:placeholder-ata-d-gray-web
                            dark:text-ata-d-cultured
                            dark:focus:ring-ata-d-cultured
                            dark:focus:border-ata-d-cultured
                        "
                    >
                        { 
                            optionValues ?
                                optionValues.map((option, idx) => {
                                    return (
                                        <option value={option.id} key={idx}>
                                            { daySelector ? dayLabels[idx].long : option.label }
                                        </option>
                                    )
                                }) : 'No Options Found'
                        }
                    </select> : ''
            }


        </div>
    )
}