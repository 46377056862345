import React, { useEffect, useState } from 'react'
import { MinusCircleIcon, PlusCircleIcon } from '../icons'
import './FormElements.css'

export default function Slider({
    label = '', 
    value,
    min = 0,
    max = 100,
    vertical = false,
    showInput = false,
    showButtons = true,
    unit = '',
    command = null,
    enabled = true,
    onChange
}) {
    const [val, setVal] = useState(value)
    const step = 1

    let debounceTimeout = null

    useEffect(() => {
        setVal(value)
    }, [value])

    const handleOnChange = (e) => {
        setVal(parseInt(e.target.value))
    }

    const handleOnTouchEnd = (e) => {
        clearTimeout(debounceTimeout)

        debounceTimeout = setTimeout(function () {
            onChange(command, e.target.value)
        }, 300)
    }

    const handleOnClick = (e, context) => {
        e.preventDefault()

        clearTimeout(debounceTimeout)

        let newvalue = val

        if (context === 'add') {
            newvalue = parseInt(val) + step
        } 
        
        if (context === 'subtract') {
            newvalue = parseInt(val) - step
        }

        if (newvalue >= min && newvalue <= max) {
            setVal(newvalue)

            debounceTimeout = setTimeout(function () {
                onChange(command, newvalue)
            }, 300)
        }
    }

    return (
        <div className={`
            flex flex-col 
            ${vertical || !showInput ? ' items-center' : ''}
        `}>
            {
                label ? <label className="block pb-8 text-sm">{label}</label> : ''
            }

            {
                val && unit ? 
                    <h1 className="block text-center pb-8 text-2xl">
                        {val} {unit}
                    </h1> : ''
            }

            <div className={`flex ${vertical ? 'flex-col-reverse items-center' : ''}`}>
                { 
                    showButtons && enabled ?
                        <button
                            className={`
                                w-10
                                text-ata-a-june-bud
                                dark:text-ata-d-cultured
                                ${showInput ? 'mt-1' : 'mx-4'} 
                                ${vertical ? 'ata-rotate-slider-margin' : ''}
                                active:bg-ata-a-gainsboro 
                                dark:active:bg-ata-d-liver
                            `} 
                            onClick={(e) => { handleOnClick(e, 'subtract')} }
                        >
                            <MinusCircleIcon />
                        </button>
                        : ''
                }

                <div 
                className={`
                    flex min-h-[45px] flex-grow px-2 items-center 
                    ${vertical ? 'ata-rotate-slider ' : ''}
                    ${showInput && enabled ? '' : 'hidden '}
                `}>
                    <input
                        type="range"
                        id={label.replace(' ', '')}
                        value={val}
                        min={min}
                        max={max}
                        className="w-full"
                        onChange={ handleOnChange }
                        onTouchEnd={ (e) => { handleOnTouchEnd(e) } }
                    />
                </div>
                { 
                    showButtons && enabled ?
                        <button 
                            className={`
                                w-10
                                text-ata-a-june-bud
                                dark:text-ata-d-cultured  
                                ${showInput ? 'mt-1' : 'mx-4'} 
                                ${vertical ? 'ata-rotate-slider-margin' : ''}
                                active:bg-ata-a-gainsboro 
                                dark:active:bg-ata-d-liver
                            `} 
                            onClick={ (e) => { handleOnClick(e, 'add') } }
                        >
                            <PlusCircleIcon />
                        </button>
                        : ''
                }
            </div>
        </div>
    )
}