export const BathroomIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" >
            <g>
                
                <g>
                    <path fill="currentColor" d="M61.04,5.35h-18.7c-1.65,0-3,1.35-3,3v5.64c0,1.65,1.35,3,3,3h1.45v15.87H17.18c-2.62,0-4.76,2.13-4.76,4.76
                        c0,0.42,0.06,0.83,0.16,1.22c-0.95,0.02-1.84,0.39-2.51,1.06C9.38,40.58,9,41.5,9,42.51C9,44.29,9.39,48.87,13.24,53.04
                        c3.03,3.3,8.51,6.26,10.8,7.41v0.27c-0.02,0.17-0.02,0.34-0.02,0.51c0,1.48,0.58,2.88,1.63,3.93c1.05,1.05,2.44,1.62,3.92,1.62
                        c0,0,0,0,0,0h24.47c3.06,0,5.55-2.49,5.55-5.55V40.63V16.99h1.45c1.65,0,3-1.35,3-3V8.35C64.04,6.7,62.69,5.35,61.04,5.35z
                        M17.18,36.45h26.61v2.37H17.61c-0.14-0.04-0.28-0.06-0.44-0.06c-0.64,0-1.16-0.52-1.16-1.16S16.54,36.45,17.18,36.45z
                        M54.04,63.19H29.57c0,0,0,0,0,0c-0.52,0-1.01-0.2-1.38-0.57c-0.36-0.36-0.57-0.86-0.57-1.38c0-0.08,0-0.15,0.01-0.22
                        c0.01-0.07,0.01-0.13,0.01-0.2v-1.48c0-0.7-0.4-1.33-1.04-1.63c-0.07-0.03-7.38-3.47-10.72-7.1c-2.98-3.24-3.29-6.73-3.28-8.13
                        h-1.8l1.85-0.05h32.93h10.41v18.81C55.99,62.31,55.12,63.19,54.04,63.19z M55.99,38.83h-8.61V16.99h8.61V38.83z M60.44,13.39
                        h-2.65H45.58h-2.65V8.95h17.5V13.39z"/>
                </g>
            </g>       
        </svg>
    )
}

export const BathroomHeatIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" >
            <g>
                
                <g>
                    <g>
                        <g>
                            <path fill="currentColor" d="M60.6,47.15H11.4c-2.98,0-5.4-2.42-5.4-5.4V10.67c0-2.98,2.42-5.4,5.4-5.4H60.6c2.98,0,5.4,2.42,5.4,5.4
                                v31.09C66,44.73,63.57,47.15,60.6,47.15z M11.4,8.87c-0.99,0-1.8,0.81-1.8,1.8v31.09c0,0.99,0.81,1.8,1.8,1.8H60.6
                                c0.99,0,1.8-0.81,1.8-1.8V10.67c0-0.99-0.81-1.8-1.8-1.8H11.4z"/>
                            <path fill="currentColor" d="M58.5,38.14h-8.04c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h8.04c0.99,0,1.8,0.81,1.8,1.8
                                S59.49,38.14,58.5,38.14z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M20.38,66.87c-0.39,0-0.78-0.13-1.12-0.39c-0.78-0.62-0.91-1.75-0.3-2.53l0.42-0.53
                                c0.71-0.9,0.71-1.89,0-2.78l-0.84-1.06c-1.74-2.2-1.74-5.05,0-7.25l0.42-0.53c0.62-0.78,1.75-0.91,2.53-0.29
                                c0.78,0.62,0.91,1.75,0.29,2.53l-0.42,0.53c-0.71,0.9-0.71,1.88,0,2.78l0.84,1.06c1.74,2.2,1.74,5.05,0,7.25l-0.42,0.53
                                C21.44,66.64,20.91,66.87,20.38,66.87z"/>
                            <path fill="currentColor" d="M36,66.87c-0.39,0-0.78-0.13-1.12-0.39c-0.78-0.62-0.91-1.75-0.3-2.53l0.42-0.53c0.71-0.9,0.71-1.89,0-2.78
                                l-0.84-1.06c-1.74-2.2-1.74-5.05,0-7.25l0.42-0.53c0.62-0.78,1.75-0.91,2.53-0.3c0.78,0.62,0.91,1.75,0.3,2.53l-0.42,0.53
                                c-0.71,0.9-0.71,1.89,0,2.78l0.84,1.06c1.74,2.2,1.74,5.05,0,7.25l-0.42,0.53C37.06,66.64,36.53,66.87,36,66.87z"/>
                            <path fill="currentColor" d="M51.62,66.87c-0.39,0-0.79-0.13-1.12-0.39c-0.78-0.62-0.91-1.75-0.29-2.53l0.42-0.53
                                c0.71-0.9,0.71-1.88,0-2.78l-0.84-1.06c-1.74-2.2-1.74-5.05,0-7.25l0.42-0.53c0.62-0.78,1.75-0.91,2.53-0.3
                                c0.78,0.62,0.91,1.75,0.3,2.53l-0.42,0.53c-0.71,0.9-0.71,1.89,0,2.78l0.84,1.06c1.74,2.2,1.74,5.05,0,7.25l-0.42,0.53
                                C52.68,66.64,52.15,66.87,51.62,66.87z"/>
                        </g>
                    </g>
                </g>
            </g>       
        </svg>
    )
}