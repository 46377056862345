export const LightMoodsIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none"  >
            <g>
                <path fill="currentColor" d="M7.84,15.73h36.35c0.81,3.02,3.56,5.25,6.83,5.25c3.27,0,6.02-2.23,6.83-5.25h6.29c0.99,0,1.8-0.81,1.8-1.8
                    s-0.81-1.8-1.8-1.8h-6.28c-0.79-3.04-3.55-5.3-6.84-5.3c-3.29,0-6.05,2.26-6.84,5.3H7.84c-0.99,0-1.8,0.81-1.8,1.8
                    S6.84,15.73,7.84,15.73z M51.02,10.42c1.92,0,3.47,1.56,3.47,3.47c0,1.92-1.56,3.47-3.47,3.47c-1.92,0-3.47-1.56-3.47-3.47
                    C47.54,11.98,49.1,10.42,51.02,10.42z"/>
                <path fill="currentColor" d="M64.11,56.49h-15.2c-0.78-3.06-3.55-5.33-6.85-5.33c-3.3,0-6.06,2.27-6.85,5.33H7.82
                    c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8h27.42c0.82,3,3.56,5.22,6.82,5.22c3.26,0,6-2.22,6.82-5.22h15.23
                    c0.99,0,1.8-0.81,1.8-1.8C65.91,57.3,65.11,56.49,64.11,56.49z M42.06,61.72c-1.92,0-3.47-1.56-3.47-3.47
                    c0-1.92,1.56-3.47,3.47-3.47c1.92,0,3.47,1.56,3.47,3.47C45.53,60.16,43.98,61.72,42.06,61.72z"/>
                <path fill="currentColor" d="M64.11,34.26H27.79c-0.8-3.03-3.56-5.27-6.83-5.27c-3.28,0-6.03,2.24-6.83,5.27H7.82
                    c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8h6.3c0.8,3.03,3.56,5.28,6.83,5.28c3.28,0,6.04-2.25,6.83-5.28h36.32
                    c0.99,0,1.8-0.81,1.8-1.8C65.91,35.07,65.11,34.26,64.11,34.26z M20.96,39.54c-1.92,0-3.47-1.56-3.47-3.47s1.56-3.47,3.47-3.47
                    c1.92,0,3.47,1.56,3.47,3.47S22.87,39.54,20.96,39.54z"/>
            </g>
        </svg>
    )
}