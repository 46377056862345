export const DirectionUpIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M63.97,51.12c-0.46,0-0.92-0.18-1.27-0.53L37.27,25.16c-0.7-0.7-1.84-0.7-2.55,0L9.3,50.59
                            c-0.7,0.7-1.84,0.7-2.55,0c-0.7-0.7-0.7-1.84,0-2.55l25.43-25.43c2.11-2.11,5.53-2.11,7.64,0l25.43,25.43
                            c0.7,0.7,0.7,1.84,0,2.55C64.89,50.94,64.43,51.12,63.97,51.12z"/>
                    </g>
                </g>
            </g>
        </svg>    
    )
}

export const DirectionUpCircleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <g>
                            <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                                S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78
                                z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M56.14,44.3c-0.46,0-0.92-0.18-1.27-0.53l-17.6-17.6c-0.7-0.7-1.84-0.7-2.55,0l-17.59,17.6
                                c-0.7,0.7-1.84,0.7-2.55,0c-0.7-0.7-0.7-1.84,0-2.55l17.6-17.6c2.11-2.11,5.53-2.11,7.64,0l17.6,17.6c0.7,0.7,0.7,1.84,0,2.55
                                C57.06,44.12,56.6,44.3,56.14,44.3z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>    
    )
}

export const DirectionDownIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,51.1c-1.38,0-2.77-0.53-3.82-1.58L6.75,24.09c-0.7-0.7-0.7-1.84,0-2.55c0.7-0.7,1.84-0.7,2.55,0
                            l25.43,25.43c0.7,0.7,1.84,0.7,2.55,0L62.7,21.55c0.7-0.7,1.84-0.7,2.55,0c0.7,0.7,0.7,1.84,0,2.55L39.82,49.52
                            C38.77,50.57,37.38,51.1,36,51.1z"/>
                    </g>
                </g>
            </g>
        </svg>    
    )
}

export const DirectionDownCircleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <g>
                            <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                                S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78
                                z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M36,50.09c-1.38,0-2.77-0.53-3.82-1.58l-17.6-17.6c-0.7-0.7-0.7-1.84,0-2.55c0.7-0.7,1.84-0.7,2.55,0
                                l17.6,17.6c0.7,0.7,1.84,0.7,2.55,0l17.59-17.6c0.7-0.7,1.84-0.7,2.55,0c0.7,0.7,0.7,1.84,0,2.55l-17.6,17.6
                                C38.77,49.56,37.38,50.09,36,50.09z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>    
    )
}

export const DirectionLeftIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">    
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M49.25,65.84c-0.46,0-0.92-0.18-1.27-0.53L22.55,39.89c-1.02-1.02-1.58-2.38-1.58-3.82s0.56-2.8,1.58-3.82
                            L47.98,6.82c0.7-0.7,1.84-0.7,2.55,0c0.7,0.7,0.7,1.84,0,2.55L25.09,34.8c-0.7,0.7-0.7,1.84,0,2.55l25.43,25.43
                            c0.7,0.7,0.7,1.84,0,2.55C50.17,65.67,49.71,65.84,49.25,65.84z"/>
                    </g>
                </g>
            </g>
        </svg>    
    )
}

export const DirectionLeftCircleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <g>
                            <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                                S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78
                                z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M42.43,58.01c-0.46,0-0.92-0.18-1.27-0.53l-17.6-17.6c-2.11-2.11-2.11-5.53,0-7.64l17.6-17.6
                                c0.7-0.7,1.84-0.7,2.55,0c0.7,0.7,0.7,1.84,0,2.55L26.1,34.8c-0.34,0.34-0.53,0.79-0.53,1.27s0.19,0.93,0.53,1.27l17.6,17.6
                                c0.7,0.7,0.7,1.84,0,2.55C43.35,57.83,42.89,58.01,42.43,58.01z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>    
    )
}

export const DirectionRightIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M22.75,65.84c-0.46,0-0.92-0.18-1.27-0.53c-0.7-0.7-0.7-1.84,0-2.55l25.43-25.43
                            c0.34-0.34,0.53-0.79,0.53-1.27s-0.19-0.93-0.53-1.27L21.48,9.37c-0.7-0.7-0.7-1.84,0-2.55c0.7-0.7,1.84-0.7,2.55,0l25.43,25.43
                            c1.02,1.02,1.58,2.38,1.58,3.82s-0.56,2.8-1.58,3.82L24.02,65.32C23.67,65.67,23.21,65.84,22.75,65.84z"/>
                    </g>
                </g>
            </g>
        </svg>    
    )
}

export const DirectionRightCircleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <g>
                            <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                                S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78
                                z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M29.57,58.01c-0.46,0-0.92-0.18-1.27-0.53c-0.7-0.7-0.7-1.84,0-2.55l17.6-17.6
                                c0.34-0.34,0.53-0.79,0.53-1.27s-0.19-0.93-0.53-1.27L28.3,17.2c-0.7-0.7-0.7-1.84,0-2.55c0.7-0.7,1.84-0.7,2.55,0l17.6,17.6
                                c1.02,1.02,1.58,2.38,1.58,3.82s-0.56,2.8-1.58,3.82l-17.59,17.6C30.5,57.83,30.03,58.01,29.57,58.01z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>    
    )
}