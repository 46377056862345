export const TickIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M25.69,66.71c-1.37,0-2.66-0.58-3.58-1.61c-0.03-0.03-0.05-0.06-0.08-0.09L6.38,44.86
                            c-0.61-0.79-0.47-1.92,0.32-2.53c0.79-0.61,1.92-0.47,2.53,0.32l15.61,20.09c0.33,0.35,0.73,0.38,0.94,0.37
                            c0.22-0.01,0.63-0.1,0.92-0.52l36-55.69c0.54-0.83,1.65-1.07,2.49-0.53c0.83,0.54,1.07,1.65,0.53,2.49L29.69,64.57
                            c-0.86,1.27-2.19,2.03-3.68,2.13C25.9,66.71,25.79,66.71,25.69,66.71z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const TickCircleIcon = ({colorClass = ''}) => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className={`w-full ${colorClass}`}  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                            S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78z
                            "/>
                    </g>
                    <path fill="currentColor" d="M26.43,50.77c-0.11,0-0.21,0-0.32-0.01c-1.22-0.09-2.34-0.71-3.06-1.69l-6.92-9.38
                        c-0.59-0.8-0.42-1.93,0.38-2.52c0.8-0.59,1.93-0.42,2.52,0.38l6.92,9.38c0.14,0.19,0.34,0.23,0.44,0.24c0.1,0.01,0.3,0,0.47-0.17
                        l25.1-25.1c0.7-0.7,1.84-0.7,2.55,0c0.7,0.7,0.7,1.84,0,2.55l-25.1,25.1C28.6,50.33,27.54,50.77,26.43,50.77z"/>
                </g>
            </g>
        </svg>
    )
}