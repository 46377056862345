export const GateClosedIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M7.76,7.08c-0.99,0-1.8,0.81-1.8,1.8v54.37c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8V8.88
                            C9.56,7.89,8.76,7.08,7.76,7.08z"/>
                        <path fill="currentColor" d="M64.15,7.08c-0.99,0-1.8,0.81-1.8,1.8v54.37c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8V8.88
                            C65.95,7.89,65.14,7.08,64.15,7.08z"/>
                        <path fill="currentColor" d="M54.82,7.08H17.18c-2.98,0-5.4,2.42-5.4,5.4v47.17c0,2.98,2.42,5.4,5.4,5.4h37.64c2.98,0,5.4-2.42,5.4-5.4
                            V12.48C60.22,9.51,57.8,7.08,54.82,7.08z M29.86,10.68h4.34v50.77h-4.34V10.68z M26.26,61.45h-4.34V10.68h4.34V61.45z
                            M37.8,10.68h4.34v50.77H37.8V10.68z M45.74,10.68h4.34v50.77h-4.34V10.68z M15.38,59.65V12.48c0-0.99,0.81-1.8,1.8-1.8h1.14
                            v50.77h-1.14C16.19,61.45,15.38,60.65,15.38,59.65z M56.62,59.65c0,0.99-0.81,1.8-1.8,1.8h-1.14V10.68h1.14
                            c0.99,0,1.8,0.81,1.8,1.8V59.65z"/>
                    </g>
                </g>
            </g>        
        </svg>
    )
}

export const GateOpenIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M7.76,7.08c-0.99,0-1.8,0.81-1.8,1.8v54.37c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8V8.88
                            C9.56,7.89,8.76,7.08,7.76,7.08z"/>
                        <path fill="currentColor" d="M64.15,7.08c-0.99,0-1.8,0.81-1.8,1.8v54.37c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8V8.88
                            C65.95,7.89,65.14,7.08,64.15,7.08z"/>
                        <path fill="currentColor" d="M32.4,7.08H17.18c-2.98,0-5.4,2.42-5.4,5.4v47.17c0,2.98,2.42,5.4,5.4,5.4H32.4c2.98,0,5.4-2.42,5.4-5.4
                            V12.48C37.8,9.51,35.38,7.08,32.4,7.08z M26.26,10.68v50.77h-4.34V10.68H26.26z M15.38,59.65V12.48c0-0.99,0.81-1.8,1.8-1.8h1.14
                            v50.77h-1.14C16.19,61.45,15.38,60.65,15.38,59.65z M34.2,59.65c0,0.99-0.81,1.8-1.8,1.8h-2.54V10.68h2.54
                            c0.99,0,1.8,0.81,1.8,1.8V59.65z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}