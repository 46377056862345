import React, { useState, useEffect } from 'react'
import TextInput from '../forms/TextInput'

export default function SelectProperty({ onSelectProperty, currentIp = '' }) {
    const [propertyList, setPropertyList] = useState([])
    const [propertyName, setPropertyName] = useState('')

    const handlePropertyNameChange = (label, value) => {
        setPropertyName(value)
    }

    const handleOnSave = (e) => {
        e.preventDefault()

        const newList = propertyList
        newList.push({ip: currentIp, name: propertyName})
        
        setPropertyList([...newList])
        localStorage.setItem('savedProperties', JSON.stringify(newList))
    }

    const handlePropertySelect = (e, property) => {
        e.preventDefault()
        onSelectProperty(property.ip)
    }

    const handleRemoveProperty = (e, index) => {
        e.preventDefault()
        const newList = propertyList

        newList.splice(index, 1)
        setPropertyList([...newList])
        localStorage.setItem('savedProperties', JSON.stringify(propertyList))
    }

    useEffect(() => {
        const savedProperties = localStorage.getItem('savedProperties')
        
        if (savedProperties) {
            setPropertyList(JSON.parse(savedProperties))
        }
    }, [])

    return (        
        <div className="flex flex-col w-full justify-center">
            {
                currentIp ?
                    <div className="flex items-end border-b pb-2">
                        <div className="flex w-full justify-center mt-4">
                            <TextInput 
                                placeholder="property name" 
                                label="save connection"
                                value={propertyName} 
                                onChange={handlePropertyNameChange} 
                            />
                        </div>
                        <div>
                            <button 
                                onClick={(e) => { handleOnSave(e) }}
                                type="button" 
                                className="
                                    rounded-lg text-sm w-full border ml-2
                                    sm:w-auto px-5 py-2.5 text-center mt-2 sm:mt-0
                                ">Save</button>
                        </div>
                    </div> : ''
            }

            {
                propertyList.length > 0 ?
                    propertyList.map((p, idx) => {
                        return (
                            <div key={idx} className="flex justify-between p-2 border-b" >
                                <div onClick={(e) => { handlePropertySelect(e, p) }}>
                                    <label className="block">{ p.name }</label>
                                    <small>{ p.ip }</small>
                                </div>

                                <button 
                                    onClick={ (e) => { handleRemoveProperty(e, idx) } } >x</button>
                            </div>
                        )
                    }) : <p className="py-4 mt-2 text-center">No Saved Properities</p>
            }
        </div>
    )
}


