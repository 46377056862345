/**
 * This hacky thing allows the app to perform normal routing 
 * functions automagically when the redux screen state is updated
 */

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function CommandRouter({ route = '/' }) {
    let navigate = useNavigate()

    useEffect(() => {
        if (route) {
            if (route !== window.location.pathname) {
                navigate(route)
            }
        }
    }, [route, navigate])

    return ( <></> )
}