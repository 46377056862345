import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getSidebar } from '../actions'
import FormContainer from '../components/forms/FormContainer'

export default function FormView() {
    const dispatch = useDispatch()
    const [formElements, setFormElements] = useState([])

    const screenKey = useSelector(state => (state?.screen?.data) ? Object.keys(state.screen.data)[0] : null)
    const screenData = useSelector(state => (state?.screen?.data) ? Object.values(state.screen.data)[0] : {})

    /** get form elements */
    useEffect(() => {
        const form = screenData[screenKey + 'Info']?.parameters ?? []
        setFormElements(form)
    
    // eslint-disable-next-line
    }, [screenData[screenKey + 'Info']?.parameters])

    /** get sidebar items */
    useEffect(() => {   
        const sidebar = screenData[screenKey + 'Info']?.sideBarEditor ?? []
        dispatch( getSidebar(sidebar) )
        
    // eslint-disable-next-line    
    }, [screenData[screenKey + 'Info']?.sideBarEditor])

    return (
        <div className="w-full h-full">
            <div className="'w-full pt-2 px-4 relative flex flex-col h-full overflow-y-auto">
                { 
                    formElements 
                        ? <FormContainer formElements={formElements} /> 
                        : 'No Form Elements' 
                }
            </div>
        </div>
    )
}