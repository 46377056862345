export const TowelRailIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M64.15,5.31c-0.99,0-1.8,0.81-1.8,1.8v9.92h-6.2c-0.2-2.8-2.53-5.02-5.38-5.02H21.23
                        c-2.85,0-5.18,2.22-5.38,5.02H9.56V7.11c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8v57.97c0,0.99,0.81,1.8,1.8,1.8
                        s1.8-0.81,1.8-1.8V54.25h52.79v10.82c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8V7.11C65.95,6.11,65.14,5.31,64.15,5.31z
                        M62.35,34.29h-6.18V20.63h6.18V34.29z M19.43,17.41c0-0.99,0.81-1.8,1.8-1.8h29.54c0.99,0,1.8,0.81,1.8,1.8v23.48
                        c0,0.99-0.81,1.8-1.8,1.8H21.23c-0.99,0-1.8-0.81-1.8-1.8V17.41z M15.83,20.63v13.66H9.56V20.63H15.83z M9.56,50.65V37.89h6.27v3
                        c0,2.98,2.42,5.4,5.4,5.4h29.54c2.98,0,5.4-2.42,5.4-5.4v-3h6.18v12.76H9.56z"/>
                </g>
            </g>   
        </svg>
    )
}  