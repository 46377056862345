export const BlindsClosedIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">      
            <g>
                
                <g>
                    <path fill="currentColor" d="M62.33,6.31H9.67c-1.99,0-3.6,1.61-3.6,3.6v3.57c0,1.99,1.61,3.6,3.6,3.6h2.6v30.41c0,2.98,2.42,5.4,5.4,5.4
                        H34.2v4.57c-1.56,0.69-2.66,2.26-2.66,4.08c0,2.46,2,4.46,4.46,4.46s4.46-2,4.46-4.46c0-1.82-1.09-3.38-2.66-4.08v-4.57h16.53
                        c2.98,0,5.4-2.42,5.4-5.4V17.08h2.6c1.99,0,3.6-1.61,3.6-3.6V9.91C65.93,7.93,64.32,6.31,62.33,6.31z M56.13,47.49
                        c0,0.99-0.81,1.8-1.8,1.8H17.67c-0.99,0-1.8-0.81-1.8-1.8V17.08h40.27V47.49z M9.67,13.48V9.91h52.67l0,3.57H9.67z"/>
                </g>
            </g>
        </svg>
    )
}