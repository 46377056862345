import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ children, isLoggedIn }) => {
    const [loggedIn, setLoggedIn] = useState(isLoggedIn)

    useEffect(() => {
        setLoggedIn(isLoggedIn)
    }, [isLoggedIn])

    return loggedIn ? children : <Navigate to="/" />
}

export default PrivateRoute