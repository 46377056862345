/* eslint-disable */

/**
 * Discover available HMI's on wifi
 */
import React, { useState, useEffect } from 'react'
import { getClient } from '../../services/socket.service' 
import { getAppSetting, saveAppSetting, getInitalAppSettings } from '../../services/settings.service'
import { logout } from '../../services/auth.service'
import { AutoConnectToggle } from '../AutoConnectToggle'
import { FragmentLoader } from '../Loaders'

export default function DiscoverNetwork({
    connectionService, 
    onSelectDevice = null
}) {
    const [favorite, setFavorite] = useState(null)
    const [autoConnect, setAutoConnect] = useState(false)
    const [connecting, setConnecting] = useState(true)
    const [connectionError, setConnectionError] = useState(false)
    const [authError, setAuthError] = useState(false)
    const [availableConnections, setAvailableConnections] = useState([])

    const handleSelectAutoConnect = (value) => {
        setAutoConnect(value)
        saveAppSetting('auto_connect', value)
    }

    const handleSetFavorite = (dc) => {
        setFavorite(dc)
        saveAppSetting('favorite_connection', JSON.stringify(dc))
    }

    const connectToServer = (dc) => {
        onSelectDevice(dc)
    }

    const getHMIs = async () => {
        connectionService.init()
       
        setConnectionError(false)
        setConnecting(true)

        const favoriteDC = getAppSetting('favorite_connection')
        const ac = getAppSetting('auto_connect')
        setAutoConnect(ac)


        try {
            let hmiList = await connectionService.getAvailableConnections()

            if (hmiList.status === 401) {
                setAuthError(true)
                setConnectionError(true)
                setConnecting(false)
            }
            
            if (hmiList.ok) {
                hmiList = await hmiList.json()
                
                const alreadyConnected = ( getClient() )

                setTimeout(() => {
                    setAvailableConnections(hmiList)
                    if (favoriteDC) { setFavorite(JSON.parse(favoriteDC)) }

                    if (favoriteDC && ac && !alreadyConnected) {
                        connectToServer(JSON.parse(favoriteDC))
                    } 
                    
                    if (!favoriteDC || !autoConnect) {
                        setConnecting(false)
                    } 
                }, 500)
            }          
        } catch (error) {
            setConnectionError(true)
            setConnecting(false)
        }
    }

    useEffect(() => {
        getInitalAppSettings()
        getHMIs()
    }, [])

    return ( 
        <>
            { connecting && !connectionError ? <FragmentLoader message="Looking for available connections" /> : '' }
            
            { 
                connectionError ? 
                    <div>
                        <div className="flex justify-center py-4 text-ata-d-burnt-sienna">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>
                        </div>

                        <p className="text-ata-d-burnt-sienna text-center">
                            { authError ? 'Unauthorized: Your session has expired' : 'Unable to find Connections' }
                        </p>

                        { 
                            authError ? 
                                <button 
                                    type="button" 
                                    className="
                                        text-ata-d-burnt-sienna border border-ata-d-burnt-sienna 
                                        font-medium rounded-lg text-sm px-5 py-2.5 text-center mx-auto block my-8
                                    "
                                    onClick={() => { logout() }}
                                >Login</button> : 
                                <button 
                                    type="button" 
                                    className="
                                        text-ata-d-burnt-sienna border border-ata-d-burnt-sienna 
                                        font-medium rounded-lg text-sm px-5 py-2.5 text-center mx-auto block my-8
                                    "
                                    onClick={() => { getHMIs() }}
                                >Try Again</button>
                        }
                    </div>
                    : '' 
            }

            {   
                availableConnections ?
                    availableConnections.map((dc, i) => {
                        return (
                            <div className="flex w-full py-4 justify-center" key={i}>
                                <button 
                                    type="button" 
                                    className="rounded-lg text-sm border ml-2 px-5 py-2.5 w-4/5 block text-center sm:mt-0"
                                        key={dc.installationID + '-' + dc.dcId}
                                        onClick={(e) => connectToServer(dc) }
                                        >
                                    {dc.dcName}
                                </button>
                                <button className="ml-2" onClick={(e) => handleSetFavorite(dc) } >
                                    {
                                        favorite?.dcName !== dc.dcName ?
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 opacity-40">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                            </svg> :
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clipRule="evenodd" />
                                            </svg>  
                                    }
                                </button>
                            </div>

                        )        
                    })
                : ''    
            }

            { favorite ? <div className="pt-4 mx-auto"><AutoConnectToggle autoConnect={autoConnect} setAutoConnect={handleSelectAutoConnect} /></div> : '' }
        </>  
    )
}
