import React from 'react'
import getIcon from '../../utils/getIcon'
import * as Icons from '../icons'

import { getCommandService } from '../../services/command.service'

export default function FormButton(props) {
    let Icon = null
    const commandService = getCommandService()
    const iconComponentName = getIcon(props?.button?.bitMapId)
    
    if (Icons[iconComponentName]) {
        Icon = Icons[iconComponentName]
    }

    const handleClick = (e) => {
        e.preventDefault()
        commandService.queueCommand(props?.button?.command)
    }

    return (

        <button 
            onClick={(e) => { handleClick(e) }} 
            className={`
                flex flex-col items-center relative w-full max-h-full rounded text-center active:bg-ata-a-gainsboro dark:active:bg-ata-d-liver
            `}
        >
            <small className={`
                absolute top-0 right-0 text-xs
                ${ props?.button?.colour === 1  ? 'text-ata-d-burnt-sienna' : '' }
                ${ props?.button?.colour === 2  ? 'text-ata-d-yellow-green' : '' }
            `}>
                {props?.button?.buttonSubTitle}
            </small>
            { 
                Icon 
                    ? <div
                        className={`
                            flex flex-col items-center
                            pt-2 px-2
                            overflow-hidden
                            mx-auto
                            max-w-full
                            icon-max-height
                            text-ata-a-june-bud
                            dark:text-ata-d-cultured
                        `}
                    >
                        <Icon />
                    </div> 
                    : <div className="text-center px-2 mb-2 py-6 w-4/5">...</div>
            } 
            <label className="block text-sm text-center">
                {props?.button?.buttonTitle}
            </label>
        </button>
    )
}