export const WindowsOpenIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M62.33,54.04h-2.49V11.96c0-2.98-2.42-5.4-5.4-5.4H17.55c-2.98,0-5.4,2.42-5.4,5.4v42.08H9.67
                        c-1.99,0-3.6,1.61-3.6,3.6v4.33c0,1.99,1.61,3.6,3.6,3.6h52.67c1.99,0,3.6-1.61,3.6-3.6v-4.33
                        C65.93,55.66,64.32,54.04,62.33,54.04z M28.55,15.62v14.7h-12.8V11.96c0-0.36,0.11-0.69,0.29-0.97L28.55,15.62z M15.75,33.91h12.8
                        v13.56l-12.8,5.67V33.91z M30.37,50.6c1.08-0.48,1.78-1.56,1.78-2.74V15.2c0-1.25-0.79-2.38-1.96-2.81l-6.01-2.22h23.65
                        l-6.01,2.22c-1.17,0.43-1.96,1.56-1.96,2.81v32.66c0,1.19,0.7,2.26,1.78,2.74l7.75,3.44H22.61L30.37,50.6z M43.45,33.91h12.8
                        v19.23l-12.8-5.67V33.91z M56.25,11.96v18.35h-12.8v-14.7l12.51-4.62C56.14,11.27,56.25,11.6,56.25,11.96z M9.67,61.98v-4.33h4.44
                        c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0h43.77c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0h4.44l0,4.33H9.67z"/>
                </g>
            </g>
        </svg>
    )
}

export const WindowsClosedIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M62.33,54.04h-2.49V11.96c0-2.98-2.42-5.4-5.4-5.4H17.55c-2.98,0-5.4,2.42-5.4,5.4v42.08H9.67
                        c-1.99,0-3.6,1.61-3.6,3.6v4.33c0,1.99,1.61,3.6,3.6,3.6h52.67c1.99,0,3.6-1.61,3.6-3.6v-4.33
                        C65.93,55.66,64.32,54.04,62.33,54.04z M37.8,54.04V33.91h18.45v20.13H37.8z M56.25,11.96v18.35H37.8V10.16h16.65
                        C55.44,10.16,56.25,10.97,56.25,11.96z M17.55,10.16H34.2v20.15H15.75V11.96C15.75,10.97,16.56,10.16,17.55,10.16z M15.75,33.91
                        H34.2v20.13H15.75V33.91z M9.67,61.98v-4.33h52.67l0,4.33H9.67z"/>
                </g>
            </g>
        </svg>
    )
}

export const WindowSeatIcon = () => {
    return (
        <svg viewBox="0 0 72 72" className="w-full" xmlns="http://www.w3.org/2000/svg" fill="none">
            <g>
                <g>
                    <g>
                        <path fill="currentColor" d="M18.13,50.06h35.75c2.98,0,5.4-2.42,5.4-5.4v-34c0-2.98-2.42-5.4-5.4-5.4H18.13c-2.98,0-5.4,2.42-5.4,5.4v34
                            C12.73,47.64,15.15,50.06,18.13,50.06z M16.33,44.66v-15.2H34.2v17H18.13C17.13,46.46,16.33,45.65,16.33,44.66z M53.87,46.46
                            H37.8v-17h17.87v15.2C55.67,45.65,54.87,46.46,53.87,46.46z M55.67,10.67v15.2H37.8v-17h16.07C54.87,8.87,55.67,9.67,55.67,10.67
                            z M18.13,8.87H34.2v17H16.33v-15.2C16.33,9.67,17.13,8.87,18.13,8.87z"/>
                        <path fill="currentColor" d="M64.25,55.63H7.75c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8h5.48c0,0.04-0.01,0.08-0.01,0.13v5.62
                            c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-5.62c0-0.04-0.01-0.09-0.01-0.13h38.38c0,0.04-0.01,0.08-0.01,0.13v5.62
                            c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-5.62c0-0.04-0.01-0.09-0.01-0.13h5.48c0.99,0,1.8-0.81,1.8-1.8
                            C66.05,56.43,65.24,55.63,64.25,55.63z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}