export const HiWallIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M21.37,49.63c-0.71-0.9-0.71-1.89,0-2.78l0.42-0.53c0.62-0.78,0.48-1.91-0.29-2.53
                            c-0.78-0.62-1.91-0.48-2.53,0.29l-0.42,0.53c-1.74,2.2-1.74,5.04,0,7.25l0.84,1.06c0.71,0.9,0.71,1.89,0,2.78l-0.42,0.53
                            c-0.62,0.78-0.48,1.91,0.3,2.53c0.33,0.26,0.72,0.39,1.11,0.39c0.53,0,1.06-0.23,1.41-0.68l0.42-0.53c1.74-2.2,1.74-5.04,0-7.25
                            L21.37,49.63z"/>
                        <path fill="currentColor" d="M36.99,49.63c-0.71-0.9-0.71-1.88,0-2.78l0.42-0.53c0.62-0.78,0.48-1.91-0.3-2.53
                            c-0.78-0.62-1.91-0.48-2.53,0.3l-0.42,0.53c-1.74,2.2-1.74,5.05,0,7.25l0.84,1.06c0.71,0.9,0.71,1.88,0,2.78l-0.42,0.53
                            c-0.62,0.78-0.48,1.91,0.3,2.53c0.33,0.26,0.72,0.39,1.11,0.39c0.53,0,1.06-0.23,1.41-0.68l0.42-0.53c1.74-2.2,1.74-5.05,0-7.25
                            L36.99,49.63z"/>
                        <path fill="currentColor" d="M52.61,49.63c-0.71-0.9-0.71-1.89,0-2.78l0.42-0.53c0.62-0.78,0.48-1.91-0.3-2.53
                            c-0.78-0.62-1.91-0.48-2.53,0.3l-0.42,0.53c-1.74,2.2-1.74,5.04,0,7.25l0.84,1.06c0.71,0.9,0.71,1.89,0,2.78l-0.42,0.53
                            c-0.62,0.78-0.48,1.91,0.29,2.53c0.33,0.26,0.72,0.39,1.12,0.39c0.53,0,1.06-0.23,1.41-0.68l0.42-0.53c1.74-2.2,1.74-5.04,0-7.25
                            L52.61,49.63z"/>
                        <path fill="currentColor" d="M60.6,12.99H11.4c-2.98,0-5.4,2.42-5.4,5.4v15.25c0,2.98,2.42,5.4,5.4,5.4H60.6c2.98,0,5.4-2.42,5.4-5.4
                            V18.39C66,15.41,63.57,12.99,60.6,12.99z M11.4,16.59H60.6c0.99,0,1.8,0.81,1.8,1.8v11.37H9.6V18.39
                            C9.6,17.4,10.41,16.59,11.4,16.59z M60.6,35.44H11.4c-0.99,0-1.8-0.81-1.8-1.8v-0.28H62.4v0.28
                            C62.4,34.63,61.59,35.44,60.6,35.44z"/>
                        <path fill="currentColor" d="M12.67,21.98h5.39c0.99,0,1.8-0.81,1.8-1.8c0-0.99-0.81-1.8-1.8-1.8h-5.39c-0.99,0-1.8,0.81-1.8,1.8
                            C10.87,21.17,11.68,21.98,12.67,21.98z"/>
                    </g>
                </g>
            </g>     
        </svg>
    )
}

export const HeatIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,51.79c-8.67,0-15.72-7.05-15.72-15.72S27.33,20.35,36,20.35s15.72,7.05,15.72,15.72
                            S44.67,51.79,36,51.79z M36,23.95c-6.68,0-12.12,5.44-12.12,12.12S29.32,48.19,36,48.19s12.12-5.44,12.12-12.12
                            S42.68,23.95,36,23.95z"/>
                        <g>
                            <path fill="currentColor" d="M36,17.11c-0.99,0-1.8-0.81-1.8-1.8V7.77c0-0.99,0.81-1.8,1.8-1.8s1.8,0.81,1.8,1.8v7.54
                                C37.8,16.31,36.99,17.11,36,17.11z"/>
                            <path fill="currentColor" d="M36,66.17c-0.99,0-1.8-0.81-1.8-1.8v-7.54c0-0.99,0.81-1.8,1.8-1.8s1.8,0.81,1.8,1.8v7.54
                                C37.8,65.36,36.99,66.17,36,66.17z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M64.3,37.87h-7.54c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h7.54c0.99,0,1.8,0.81,1.8,1.8
                                S65.29,37.87,64.3,37.87z"/>
                            <path fill="currentColor" d="M15.24,37.87H7.7c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h7.54c0.99,0,1.8,0.81,1.8,1.8
                                S16.24,37.87,15.24,37.87z"/>
                        </g>
                        <g>
                            <g>
                                <path fill="currentColor" d="M50.68,23.19c-0.46,0-0.92-0.18-1.27-0.53c-0.7-0.7-0.7-1.84,0-2.55l5.33-5.33c0.7-0.7,1.84-0.7,2.55,0
                                    c0.7,0.7,0.7,1.84,0,2.55l-5.33,5.33C51.6,23.02,51.14,23.19,50.68,23.19z"/>
                                <path fill="currentColor" d="M15.99,57.88c-0.46,0-0.92-0.18-1.27-0.53c-0.7-0.7-0.7-1.84,0-2.55l5.33-5.33c0.7-0.7,1.84-0.7,2.55,0
                                    c0.7,0.7,0.7,1.84,0,2.55l-5.33,5.33C16.91,57.7,16.45,57.88,15.99,57.88z"/>
                            </g>
                            <g>
                                <path fill="currentColor" d="M56.01,57.88c-0.46,0-0.92-0.18-1.27-0.53l-5.33-5.33c-0.7-0.7-0.7-1.84,0-2.55c0.7-0.7,1.84-0.7,2.55,0
                                    l5.33,5.33c0.7,0.7,0.7,1.84,0,2.55C56.93,57.7,56.47,57.88,56.01,57.88z"/>
                                <path fill="currentColor" d="M21.32,23.19c-0.46,0-0.92-0.18-1.27-0.53l-5.33-5.33c-0.7-0.7-0.7-1.84,0-2.55c0.7-0.7,1.84-0.7,2.55,0
                                    l5.33,5.33c0.7,0.7,0.7,1.84,0,2.55C22.24,23.02,21.78,23.19,21.32,23.19z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>     
        </svg>
    )
}

export const CoolIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M61.63,38.95l-11.64,3.12l-10.39-6l10.39-6l11.64,3.12c0.16,0.04,0.31,0.06,0.47,0.06
                        c0.79,0,1.52-0.53,1.74-1.33c0.26-0.96-0.31-1.95-1.27-2.2l-8.16-2.19l7.73-4.46c0.86-0.5,1.16-1.6,0.66-2.46
                        c-0.5-0.86-1.6-1.16-2.46-0.66l-7.73,4.46l2.19-8.16c0.26-0.96-0.31-1.95-1.27-2.2c-0.96-0.26-1.95,0.31-2.2,1.27l-3.12,11.64
                        l-10.39,6V20.96l8.52-8.52c0.7-0.7,0.7-1.84,0-2.55c-0.7-0.7-1.84-0.7-2.55,0l-5.98,5.98V6.94c0-0.99-0.81-1.8-1.8-1.8
                        s-1.8,0.81-1.8,1.8v8.93l-5.98-5.98c-0.7-0.7-1.84-0.7-2.55,0c-0.7,0.7-0.7,1.84,0,2.55l8.52,8.52v11.99l-10.39-6l-3.12-11.64
                        c-0.26-0.96-1.24-1.53-2.2-1.27c-0.96,0.26-1.53,1.24-1.27,2.2l2.19,8.16l-7.73-4.46c-0.86-0.5-1.96-0.2-2.46,0.66
                        c-0.5,0.86-0.2,1.96,0.66,2.46l7.73,4.46l-8.16,2.19c-0.96,0.26-1.53,1.24-1.27,2.2c0.22,0.8,0.94,1.33,1.74,1.33
                        c0.15,0,0.31-0.02,0.47-0.06l11.64-3.12l10.39,6l-10.39,6l-11.64-3.12c-0.96-0.26-1.95,0.31-2.2,1.27
                        c-0.26,0.96,0.31,1.95,1.27,2.2l8.16,2.19l-7.73,4.46c-0.86,0.5-1.16,1.6-0.66,2.46c0.33,0.58,0.94,0.9,1.56,0.9
                        c0.31,0,0.61-0.08,0.9-0.24l7.73-4.46l-2.19,8.16c-0.26,0.96,0.31,1.95,1.27,2.2c0.16,0.04,0.31,0.06,0.47,0.06
                        c0.79,0,1.52-0.53,1.74-1.33l3.12-11.64l10.39-6v11.99l-8.52,8.52c-0.7,0.7-0.7,1.84,0,2.55c0.7,0.7,1.84,0.7,2.55,0l5.98-5.98
                        v8.93c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-8.93l5.98,5.98c0.35,0.35,0.81,0.53,1.27,0.53c0.46,0,0.92-0.18,1.27-0.53
                        c0.7-0.7,0.7-1.84,0-2.55l-8.52-8.52V39.19l10.39,6l3.12,11.64c0.22,0.8,0.94,1.33,1.74,1.33c0.15,0,0.31-0.02,0.47-0.06
                        c0.96-0.26,1.53-1.24,1.27-2.2l-2.19-8.16l7.73,4.46c0.28,0.16,0.59,0.24,0.9,0.24c0.62,0,1.23-0.32,1.56-0.9
                        c0.5-0.86,0.2-1.96-0.66-2.46l-7.73-4.46l8.16-2.19c0.96-0.26,1.53-1.24,1.27-2.2C63.57,39.26,62.59,38.69,61.63,38.95z"/>
                </g>
            </g>     
        </svg>
    )
}

export const FanIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M58.1,15.9c-3.68-3.68-7.45-3.82-9.97-3.29c-5.02,1.06-9.56,5.66-12.14,12.31c-0.2,0.52-0.73,0.9-1.35,0.98
                            c-0.01,0-0.06,0.01-0.07,0.01c-0.69,0.09-1.39-0.36-1.66-1.08c-0.56-1.5-0.64-2.88-0.24-4.1c1.38-4.12,0.05-8.42-3.23-10.45
                            c-1.44-0.89-6.69-3.27-13.61,3.64c-3.68,3.68-3.83,7.46-3.3,9.98c1.06,5.02,5.67,9.57,12.33,12.16c0.51,0.2,0.88,0.71,0.97,1.32
                            c0,0.01,0.01,0.06,0.01,0.07c0.1,0.71-0.36,1.42-1.08,1.68c-1.48,0.53-2.84,0.6-4.05,0.19c-4.13-1.38-8.44-0.03-10.49,3.28
                            c-2.38,3.86-1.03,8.94,3.62,13.59c3.7,3.7,7.5,3.84,10.03,3.31c5.04-1.07,9.58-5.69,12.16-12.36c0.2-0.51,0.68-0.89,1.27-0.96
                            c0.76-0.1,1.47,0.28,1.72,0.91c0.62,1.58,0.73,3.03,0.31,4.31c-1.38,4.13-0.05,8.44,3.25,10.48c0.66,0.41,2.12,1.13,4.16,1.13
                            c2.43,0,5.69-1.01,9.43-4.76c3.69-3.69,3.83-7.47,3.31-9.99c-1.04-5.01-5.62-9.55-12.23-12.15c-0.62-0.24-1.06-0.78-1.15-1.44
                            c-0.1-0.69,0.28-1.35,0.92-1.6c1.56-0.61,3-0.7,4.27-0.27c4.11,1.37,8.41,0.03,10.44-3.26C62.63,28.07,65.01,22.81,58.1,15.9z
                            M58.67,27.62c-1.15,1.87-3.72,2.58-6.24,1.74c-2.09-0.71-4.36-0.6-6.73,0.33c-2.19,0.86-3.5,3.1-3.17,5.48
                            c0.26,1.91,1.56,3.54,3.39,4.26c5.4,2.12,9.24,5.77,10.02,9.53c0.5,2.41-0.28,4.66-2.33,6.71c-3.4,3.4-6.82,4.56-9.16,3.11
                            c-1.87-1.16-2.58-3.74-1.73-6.29c0.69-2.1,0.57-4.38-0.37-6.76c-0.77-1.95-2.69-3.2-4.84-3.2c-0.24,0-0.48,0.02-0.72,0.05
                            c-1.87,0.23-3.46,1.47-4.13,3.23c-2.1,5.45-5.76,9.34-9.55,10.14c-2.42,0.51-4.68-0.27-6.74-2.33c-3.39-3.39-4.55-6.81-3.1-9.15
                            c1.16-1.88,3.75-2.6,6.29-1.76c1.99,0.66,4.14,0.59,6.4-0.22c2.32-0.83,3.77-3.18,3.44-5.54c0-0.01-0.01-0.06-0.01-0.07
                            c-0.26-1.92-1.5-3.54-3.23-4.21c-5.45-2.12-9.32-5.78-10.11-9.55c-0.5-2.4,0.28-4.65,2.32-6.69c3.41-3.4,6.83-4.57,9.17-3.13
                            c1.86,1.15,2.56,3.72,1.71,6.26c-0.67,2.03-0.57,4.22,0.28,6.5c0.87,2.31,3.13,3.7,5.54,3.38c1.95-0.25,3.58-1.5,4.26-3.25
                            c2.11-5.43,5.76-9.3,9.53-10.09c2.4-0.5,4.65,0.27,6.68,2.31C58.96,21.84,60.12,25.28,58.67,27.62z"/>
                        <circle fill="currentColor" cx="36" cy="36.07" r="4.02"/>
                    </g>
                </g>
            </g>     
        </svg>
    )
}

export const DehumidifyIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,66.94c-12.4,0-22.5-10.09-22.5-22.5c0-3.81,0.99-7.59,2.86-10.94c0.05-0.12,0.11-0.23,0.18-0.35
                            L31.86,7.6c0.01-0.03,0.03-0.06,0.05-0.08C32.78,6.06,34.31,5.2,36,5.2c0,0,0,0,0,0c1.69,0,3.22,0.87,4.09,2.32
                            c0.01,0.02,0.03,0.04,0.04,0.07l15.17,25.3c0.18,0.23,0.32,0.49,0.42,0.76c1.82,3.31,2.78,7.04,2.78,10.8
                            C58.5,56.85,48.4,66.94,36,66.94z M19.66,34.95c-0.03,0.07-0.06,0.15-0.1,0.22c-1.6,2.84-2.45,6.05-2.45,9.28
                            c0,10.42,8.48,18.9,18.9,18.9c10.42,0,18.89-8.48,18.89-18.9c0-3.2-0.83-6.38-2.41-9.2c-0.05-0.08-0.09-0.17-0.12-0.26
                            c-0.03-0.05-0.06-0.09-0.09-0.14L37,9.37c-0.01-0.01-0.02-0.03-0.03-0.04C36.67,8.85,36.19,8.8,36,8.8l0,0
                            c-0.19,0-0.66,0.05-0.97,0.51C35.02,9.33,35.01,9.35,35,9.37L19.66,34.95z"/>
                    </g>
                </g>
            </g>     
        </svg>
    )
}