import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import getIcon from '../utils/getIcon'
import * as Icons from './icons'
import { getCommandService } from '../services/command.service'

export default function MenuButton(props) {
    let Icon = null
    const iconComponentName = getIcon(props?.button?.bitMapId)
    const commandService = getCommandService()

    const [form, setForm] = useState(null)
    const formData = useSelector(state => state?.form)
    
    if (Icons[iconComponentName]) {
        Icon = Icons[iconComponentName]
    }

    useEffect(() => {
        if (formData) { setForm(formData)}
      }, [formData])

    const handleClick = (e) => {
        if (props?.context === 'sidebar' && !['disarmed', 'armed'].includes(props?.button?.buttonTitle.toLowerCase())) {
            // @todo: find out if it's a submit button and submit the form
            if (props?.button?.buttonTitle.toLowerCase() === 'done') {
                commandService.queueCommand(props?.button?.command, JSON.stringify(formData))
                return;
            }
            console.warn('sidebar button pressed, check form data: ', form)
        }

        if (props?.onChange) {
            props?.onChange(props?.button)
        }

        commandService.queueCommand(props?.button?.command, props?.button?.commandData)
    }

    return (
        (props?.button?.enabled) ?
            <button
                onClick={(e) => { handleClick(e) }}
                className={`
                flex flex-col items-center relative w-full max-h-full rounded text-center active:bg-ata-a-gainsboro dark:active:bg-ata-d-liver 
                ${props?.context === 'sidebar' ? '' : 'sm:mx-1'}
                ${props?.option ? props?.button?.selected ? 'border border-ata-d-yellow-green' : '' : ''}
            `}
            >
                <small className={`
                absolute top-2 right-2 text-xs
                ${props?.button?.colour === 1 ? 'text-ata-d-burnt-sienna' : ''}
                ${props?.button?.colour === 2 ? 'text-ata-d-yellow-green' : ''}
            `}>
                    {props?.button?.buttonSubTitle}
                </small>
                {
                    Icon
                        ? <div
                            className={`
                            flex flex-col items-center
                            pt-2 px-2
                            overflow-hidden
                            mx-auto
                            w-4/5
                            icon-max-height
                            text-ata-a-june-bud
                            dark:text-ata-d-cultured
                        `}
                        >
                            <Icon />
                        </div>
                        : <div className="text-center px-2 mb-2 py-6 w-4/5">...</div>
                }
                <label className="block text-sm text-center mb-2">
                    {props?.button?.buttonTitle}
                </label>
            </button> : null
    )
}