import React, { useState, useEffect } from 'react'
import MenuButton from '../MenuButton'


export default function OptionsInput({
    label,
    options,
    multi,
    onChange
}) {

    const [optionValues, setValues] = useState(options)

    const handleOnChange = (e) => {
        const ov = [...optionValues]
        const indexToUpdate = ov.findIndex((i) => i.buttonID === parseInt(e.buttonID))

        if (multi) {
            ov[indexToUpdate].selected = !ov[indexToUpdate].selected
        } else {
            ov.map((i, idx) => {
                i.selected = (idx === indexToUpdate)
                return i
            })
        }

        setValues(ov)

        const selectedIndices = ov.reduce((acc, item, index) => {
            if (item.selected) {
                acc.push(index);
            }
            return acc;
        }, []);
        onChange(label, selectedIndices.join(","))
    }

    return (
        <div className="flex w-full h-full items-start">
            <div className="w-full px-1 relative flex flex-wrap overflow-x-auto" >
                {
                    (optionValues) ?
                        optionValues.map((b) =>
                            <div
                                className={`sm:w-1/5 w-1/5 max-button-height px-2`}
                                key={b.buttonID}
                            >
                                <MenuButton button={b} option={true} onChange={(e) => { handleOnChange(e) }} />
                            </div>
                        ) : 'No Menu Items'
                }
            </div>
        </div>
    )
}