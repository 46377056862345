import React, { useEffect, useState } from 'react'
import useDarkMode from '../../utils/useDarkMode'

import drawerlinks from '../../constants/drawerlinks.constant'
import { logout } from '../../services/auth.service'
import { getCommandService } from '../../services/command.service'

import { ThemeToggle } from '../ThemeToggle'
import { RefreshToggle } from '../RefreshToggle'
import { forceUpdate } from '../../serviceWorkerRegistration'

export default function Drawer({closeDrawer, isOpen, isLoggedIn, onNavigate, connectionService}) {
    const commandService = getCommandService()
    const [darkMode, setDarkMode] = useDarkMode()
    const [homeEnabled, setHomeEnabled] = useState(false)
    const links = drawerlinks
    const [hasUpdate, setHasUpdate] = useState(false)
    const [installedVersion, setInstalledVersion] = useState('')
    const [availableVersion, setAvailableVersion] = useState('')

    const handleUpdate = (e) => {
        e.preventDefault()
        window['pwaUpdateReady'] = true
        forceUpdate(availableVersion)
    }

    const handleOnReceivedStatus = (status) => {
        setHomeEnabled( !(['INIT', 'CLOSED', 'ERROR'].includes(status)) ) 
    } 

    const handleLogout = () => {
        connectionService.closeConnection()
        closeDrawer()
        logout()
    }

    const handleCloseDrawer = (e) => {
        e.preventDefault()
        closeDrawer()
    }

    const handleNavigation = (e, route) => {
        e.preventDefault()
        
        if (route === '/' && !homeEnabled) {
            return
        }

        closeDrawer()

        if (route === '/' && homeEnabled) {
            commandService.sendHomeCommand()
            commandService.setRefreshState(true)
            return
        }

        onNavigate(route)
    }

    // if the service worker automatically detects it should update
    const checkForUpdates = (updateReady) => {
        let tries = 0
        const limit = 5

        const checkUpdateInterval = setInterval(() => { 
            setHasUpdate(updateReady)

            if (updateReady || tries === limit) {
                clearInterval(checkUpdateInterval)
                return
            }

            tries++
        }, 2000)
    }

    // if the developer has explictly updated the version number
    const getLatestAppVersion = async (currentVersion) => {
        const availableVersion = await fetch(window.location.href + 'data/latest-version.json', {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            }
          })
        const version = await availableVersion.json()

        if (currentVersion !== version?.currentVersion) {
            setAvailableVersion(version?.currentVersion)
            setHasUpdate(true)
            return
        }

        checkForUpdates(window['pwaUpdateReady'])
    }
    
    useEffect(() => {
        const currentVersion = localStorage.getItem('installedVersion')

        setInstalledVersion(currentVersion)
        getLatestAppVersion(currentVersion)
    }, [])

    return (
        <div 
            id="drawer-navigation" 
            className={`fixed transition-transform left-0 top-0 z-40 h-screen p-4 overflow-y-auto bg-ata-a-gainsboro w-80 dark:bg-ata-d-dark-liver ${isOpen ? 'transform-none' : '-translate-x-full'}`} 
            tabIndex="-1" 
            aria-labelledby="drawer-navigation-label"
        >
            <h5 id="drawer-navigation-label" className="text-base font-semibold uppercase text-ata-a-outer-space-crayola dark:text-ata-d-cultured">Menu</h5>
            <button 
                onClick={ (e) => { handleCloseDrawer(e) } }
                type="button" 
                data-drawer-dismiss="drawer-navigation" 
                aria-controls="drawer-navigation" 
                className="text-ata-a-outer-space-crayola dark:text-ata-d-cultured bg-transparent rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center" 
            >
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Close menu</span>
            </button>
            <div className="py-2 overflow-y-auto">
                <ul className="space-y-2">

                    <li className="w-full flex justify-between border-b pb-2 border-ata-a-outer-space-crayola dark:border-ata-d-cultured">
                        <small className="text-ata-a-outer-space-crayola p-1 dark:text-ata-d-cultured">v {installedVersion}</small>        
                        {
                            hasUpdate ?
                            <button onClick={(e) => handleUpdate(e) } className="p-1 px-2 text-xs font-normal rounded-lg bg-ata-d-burnt-sienna text-ata-a-outer-space-crayola">
                                <span>Update available</span>
                            </button> :
                            <small className="text-ata-d-yellow-green p-1">up to date</small>
                        }
                    </li>

                    {
                        links.map(function(link, i){
                            return (
                                <li key={i} className={!homeEnabled && link.title === 'Home' ? 'opacity-60' : ''}>
                                    <button onClick={(e) => handleNavigation(e, link.route) } className="flex items-center p-2 text-base font-normal rounded-lg text-ata-a-outer-space-crayola dark:text-ata-d-cultured">
                                        <span className="h-5 w-5" dangerouslySetInnerHTML={{__html:link.icon}}></span>
                                        <span className="flex-1 ml-3 whitespace-nowrap">{link.title}</span>
                                    </button>
                                </li>
                            )
                        })
                    }

                    { isLoggedIn ? 
                        <li>
                            <button onClick={() => handleLogout()} className="flex items-center p-2 text-base font-normal rounded-lg text-ata-a-outer-space-crayola dark:text-ata-d-cultured">
                                <span className="h-5 w-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                    </svg>
                                </span>
                                <span className="flex-1 ml-3 whitespace-nowrap">Log out</span>
                            </button>
                        </li> : '' 
                    }
                </ul>
            </div>

            <div className="flex w-full absolute md:relative bottom-0 left-0 justify-between items-center p-2 rounded-lg text-ata-a-outer-space-crayola dark:text-ata-d-cultured">
                <ThemeToggle darkMode={darkMode} setDarkMode={setDarkMode} />
                <RefreshToggle onReceiveStatus={handleOnReceivedStatus} />
            </div>
        </div>
    )

}