export const PlayIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M14.21,66.95c-0.93,0-1.85-0.24-2.7-0.73c-1.69-0.98-2.7-2.72-2.7-4.68V10.6c0-1.95,1.01-3.7,2.7-4.68
                            c1.69-0.97,3.71-0.98,5.4,0l44.11,25.47l0,0c1.69,0.98,2.7,2.72,2.7,4.68s-1.01,3.7-2.7,4.68L16.91,66.21
                            C16.07,66.7,15.14,66.95,14.21,66.95z M14.22,8.79c-0.42,0-0.74,0.15-0.91,0.25c-0.27,0.16-0.9,0.62-0.9,1.56v50.94
                            c0,0.94,0.63,1.4,0.9,1.56c0.27,0.16,0.99,0.47,1.8,0l44.11-25.47c0.81-0.47,0.9-1.25,0.9-1.56s-0.09-1.09-0.9-1.56L15.11,9.04
                            C14.79,8.86,14.49,8.79,14.22,8.79z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const PlayCircleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                            S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78z
                            "/>
                    </g>
                    <path fill="currentColor" d="M25.65,55.54c-0.93,0-1.85-0.24-2.7-0.73c-1.69-0.98-2.7-2.72-2.7-4.68V22.01c0-1.95,1.01-3.7,2.7-4.68
                        c1.69-0.98,3.71-0.98,5.4,0L52.7,31.39l0,0c1.69,0.98,2.7,2.72,2.7,4.68s-1.01,3.7-2.7,4.68L28.35,54.8
                        C27.5,55.29,26.57,55.54,25.65,55.54z M25.66,20.21c-0.42,0-0.74,0.15-0.91,0.25c-0.27,0.16-0.9,0.62-0.9,1.56v28.12
                        c0,0.94,0.63,1.4,0.9,1.56c0.27,0.16,0.99,0.47,1.8,0L50.9,37.63c0.81-0.47,0.9-1.25,0.9-1.56s-0.09-1.09-0.9-1.56L26.55,20.45
                        C26.23,20.27,25.92,20.21,25.66,20.21z"/>
                </g>
            </g>
        </svg>
    )
}

export const StopIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M60.49,66.87H11.51c-2.98,0-5.4-2.42-5.4-5.4V10.67c0-2.98,2.42-5.4,5.4-5.4h48.97c2.98,0,5.4,2.42,5.4,5.4
                            v50.81C65.89,64.45,63.46,66.87,60.49,66.87z M11.51,8.87c-0.99,0-1.8,0.81-1.8,1.8v50.81c0,0.99,0.81,1.8,1.8,1.8h48.97
                            c0.99,0,1.8-0.81,1.8-1.8V10.67c0-0.99-0.81-1.8-1.8-1.8H11.51z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const StopCircleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M48.03,52.59H23.97c-2.98,0-5.4-2.42-5.4-5.4V24.94c0-2.98,2.42-5.4,5.4-5.4h24.06c2.98,0,5.4,2.42,5.4,5.4
                        v22.25C53.43,50.17,51.01,52.59,48.03,52.59z M23.97,23.14c-0.99,0-1.8,0.81-1.8,1.8v22.25c0,0.99,0.81,1.8,1.8,1.8h24.06
                        c0.99,0,1.8-0.81,1.8-1.8V24.94c0-0.99-0.81-1.8-1.8-1.8H23.97z"/>
                    <g>
                        <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                            S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78z
                            "/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const CrossIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M7.8,66.07c-0.46,0-0.92-0.18-1.27-0.53c-0.7-0.7-0.7-1.84,0-2.55L62.93,6.59c0.7-0.7,1.84-0.7,2.55,0
                            c0.7,0.7,0.7,1.84,0,2.55L9.07,65.55C8.72,65.9,8.26,66.07,7.8,66.07z"/>
                        <path fill="currentColor" d="M64.2,66.07c-0.46,0-0.92-0.18-1.27-0.53L6.52,9.14c-0.7-0.7-0.7-1.84,0-2.55c0.7-0.7,1.84-0.7,2.55,0
                            L65.48,63c0.7,0.7,0.7,1.84,0,2.55C65.12,65.9,64.66,66.07,64.2,66.07z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const CrossCircleIcon = ({colorClass = ''}) => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className={`w-full ${colorClass}`}   fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                            S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78z
                            "/>
                    </g>
                    <g>
                        <path fill="currentColor" d="M22.46,51.41c-0.46,0-0.92-0.18-1.27-0.53c-0.7-0.7-0.7-1.84,0-2.55l27.08-27.07c0.7-0.7,1.84-0.7,2.55,0
                            s0.7,1.84,0,2.55L23.73,50.88C23.38,51.23,22.92,51.41,22.46,51.41z"/>
                        <path fill="currentColor" d="M49.54,51.41c-0.46,0-0.92-0.18-1.27-0.53L21.19,23.8c-0.7-0.7-0.7-1.84,0-2.55c0.7-0.7,1.84-0.7,2.55,0
                            l27.08,27.07c0.7,0.7,0.7,1.84,0,2.55C50.46,51.23,50,51.41,49.54,51.41z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const PlusIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M64.15,37.87H7.85c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h56.3c0.99,0,1.8,0.81,1.8,1.8
                            S65.14,37.87,64.15,37.87z"/>
                        <path fill="currentColor" d="M36,66.02c-0.99,0-1.8-0.81-1.8-1.8V7.92c0-0.99,0.81-1.8,1.8-1.8s1.8,0.81,1.8,1.8v56.3
                            C37.8,65.21,36.99,66.02,36,66.02z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const PlusCircleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M55.34,37.87H16.66c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h38.68c0.99,0,1.8,0.81,1.8,1.8
                            S56.33,37.87,55.34,37.87z"/>
                        <path fill="currentColor" d="M36,57.21c-0.99,0-1.8-0.81-1.8-1.8V16.73c0-0.99,0.81-1.8,1.8-1.8s1.8,0.81,1.8,1.8v38.68
                            C37.8,56.4,36.99,57.21,36,57.21z"/>
                    </g>
                    <g>
                        <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                            S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78z
                            "/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const MinusIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M64.15,37.87H7.85c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h56.3c0.99,0,1.8,0.81,1.8,1.8
                        S65.14,37.87,64.15,37.87z"/>
                </g>
            </g>
        </svg>
    )
}

export const MinusCircleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                            S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78z
                            "/>
                    </g>
                    <path fill="currentColor" d="M55.34,37.87H16.66c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h38.68c0.99,0,1.8,0.81,1.8,1.8
                        S56.33,37.87,55.34,37.87z"/>
                </g>
            </g>
        </svg>
    )
}
