const shadeRed = '500'
const shadeGreen = '500'
const shadeBlue = '500'

export const commandTypes = {
	ADDR_DEVICE_WRAPPER_SCREEN: 10001,
	ADDR_DEVICE_WRAPPER_SETUP: 10002,
	ADDR_DEVICE_WRAPPER_HEALTH: 10004,
	ADDR_DEVICE_WRAPPER_FAULT_SCREEN_ACBE: 10006,
	ADDR_DEVICE_WRAPPER_FAULT_SCREEN: 10007
}

export const BorderStatusClass = {
	NONE: 	'',
	RED: 	`border-red-${shadeRed}`,
	GREEN: 	`border-green-${shadeGreen}`,
	BLUE: 	`border-blue-${shadeBlue}`,
}

export const ColorStatusClass = {
	NONE: 	'',
	RED: 	`text-red-${shadeRed}`,
	GREEN: 	`text-green-${shadeGreen}`,
	BLUE: 	`text-blue-${shadeBlue}`,
}

export const BackgroundStatusClass = {
	NONE: 	'',
	RED: 	`bg-red-${shadeRed}`,
	GREEN: 	`bg-green-${shadeGreen}`,
	BLUE: 	`bg-blue-${shadeBlue}`,
}

export const dayLabels = {
    0: { short: 'Mon', long: 'Monday' },
    1: { short: 'Tue', long: 'Tuesday' },
    2: { short: 'Wed',  long: 'Wednesday' },
    3: { short: 'Thu', long: 'Thursday' },
    4: { short: 'Fri', long: 'Friday' },
    5: { short: 'Sat', long: 'Saturday' },
    6: { short: 'Sun', long: 'Sunday' }
}