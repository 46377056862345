export const DiningIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M46.79,29.13c2.47,0,4.48-2.01,4.48-4.48v-0.86c0-2.47-2.01-4.48-4.48-4.48H25.21
                            c-2.47,0-4.48,2.01-4.48,4.48v0.86c0,2.47,2.01,4.48,4.48,4.48h8.99v30.06c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8V29.13H46.79
                            z M24.33,24.65v-0.86c0-0.48,0.4-0.88,0.88-0.88h21.59c0.48,0,0.88,0.4,0.88,0.88v0.86c0,0.48-0.4,0.88-0.88,0.88H25.21
                            C24.73,25.53,24.33,25.12,24.33,24.65z"/>
                        <path fill="currentColor" d="M65.96,41.77V16.31c0-2.84-2.31-5.16-5.16-5.16s-5.16,2.31-5.16,5.16v18.56c0,0.62-0.51,1.13-1.13,1.13
                            h-6.63c-2.89,0-5.25,2.35-5.25,5.25c0,2.28,1.47,4.22,3.51,4.95L44.4,58.94c-0.13,0.98,0.55,1.89,1.54,2.03
                            c0.08,0.01,0.17,0.02,0.25,0.02c0.89,0,1.66-0.65,1.78-1.56l1.77-12.94h10.51l1.77,12.94c0.12,0.9,0.9,1.56,1.78,1.56
                            c0.08,0,0.16-0.01,0.25-0.02c0.98-0.14,1.67-1.04,1.54-2.03l-1.81-13.2C65.09,44.9,65.96,43.43,65.96,41.77z M47.89,39.6h6.63
                            c2.61,0,4.73-2.12,4.73-4.73V16.31c0-0.86,0.7-1.56,1.56-1.56c0.86,0,1.56,0.7,1.56,1.56v25.46c0,0.62-0.51,1.13-1.13,1.13H47.89
                            c-0.91,0-1.65-0.74-1.65-1.65C46.24,40.34,46.98,39.6,47.89,39.6z"/>
                        <path fill="currentColor" d="M24.11,36h-6.63c-0.62,0-1.13-0.51-1.13-1.13V16.31c0-2.84-2.31-5.16-5.16-5.16s-5.16,2.31-5.16,5.16v25.46
                            c0,1.67,0.87,3.13,2.18,3.98l-1.81,13.2c-0.13,0.98,0.55,1.89,1.54,2.03c0.08,0.01,0.17,0.02,0.25,0.02
                            c0.89,0,1.66-0.65,1.78-1.56l1.77-12.94h10.51l1.77,12.94c0.12,0.9,0.9,1.56,1.78,1.56c0.08,0,0.16-0.01,0.25-0.02
                            c0.98-0.14,1.67-1.04,1.54-2.03l-1.75-12.75c2.04-0.72,3.51-2.66,3.51-4.95C29.36,38.35,27,36,24.11,36z M9.64,16.31
                            c0-0.86,0.7-1.56,1.56-1.56c0.86,0,1.56,0.7,1.56,1.56v18.56c0,2.61,2.12,4.73,4.73,4.73h6.63c0.91,0,1.65,0.74,1.65,1.65
                            c0,0.91-0.74,1.65-1.65,1.65H10.77c-0.62,0-1.13-0.51-1.13-1.13V16.31z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}