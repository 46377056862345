const Form = (state = null, action) => {
	const { type, payload } = action

	switch (type) {
		case 'SET_FORM_SUCCESS':
			return {
				...state,
				...payload
			}
		case 'SET_FORM_FAIL':
			return state
		case 'CLEAR_FORM':
			return null
		default:
			return state
	}
}

export default Form
