export const SpaIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,43.85c14.51,0,29.92-4.18,29.92-11.93c0-3.56-3.41-6.73-9.61-8.93c-0.94-0.33-1.97,0.16-2.3,1.1
                            c-0.33,0.94,0.16,1.97,1.1,2.3c4.45,1.58,7.22,3.69,7.22,5.53c0,3.48-10.01,8.33-26.32,8.33S9.68,35.39,9.68,31.91
                            c0-1.84,2.77-3.96,7.22-5.53c0.94-0.33,1.43-1.36,1.1-2.3c-0.33-0.94-1.36-1.43-2.3-1.1c-6.2,2.19-9.61,5.36-9.61,8.93
                            C6.08,39.66,21.49,43.85,36,43.85z"/>
                        <path fill="currentColor" d="M64.12,48.3c-0.99,0-1.8,0.81-1.8,1.8c0,3.48-10.01,8.33-26.32,8.33S9.68,53.58,9.68,50.1
                            c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8c0,7.75,15.42,11.93,29.92,11.93s29.92-4.18,29.92-11.93
                            C65.92,49.11,65.12,48.3,64.12,48.3z"/>
                        <path fill="currentColor" d="M23.3,25.11c0.91,1.59,0.91,3.56,0,5.15l-0.42,0.73c-0.49,0.86-0.19,1.96,0.67,2.46
                            c0.28,0.16,0.59,0.24,0.89,0.24c0.62,0,1.23-0.33,1.56-0.91l0.42-0.73c1.54-2.69,1.54-6.03,0-8.72l-0.84-1.46
                            c-0.91-1.59-0.91-3.56,0-5.15l0.42-0.73c0.49-0.86,0.19-1.96-0.67-2.46c-0.86-0.49-1.96-0.19-2.46,0.67l-0.42,0.73
                            c-1.54,2.69-1.54,6.03,0,8.72L23.3,25.11z"/>
                        <path fill="currentColor" d="M35.03,22.28c0.91,1.59,0.91,3.56,0,5.15l-0.42,0.73c-0.49,0.86-0.19,1.96,0.67,2.46
                            c0.28,0.16,0.59,0.24,0.89,0.24c0.62,0,1.23-0.33,1.56-0.91l0.42-0.73c1.54-2.69,1.54-6.03,0-8.72l-0.84-1.46
                            c-0.91-1.59-0.91-3.56,0-5.15l0.42-0.73c0.49-0.86,0.19-1.96-0.67-2.46c-0.86-0.49-1.96-0.19-2.46,0.67l-0.42,0.73
                            c-1.54,2.69-1.54,6.03,0,8.72L35.03,22.28z"/>
                        <path fill="currentColor" d="M46.75,25.11c0.91,1.59,0.91,3.56,0,5.15l-0.42,0.73c-0.49,0.86-0.19,1.96,0.67,2.46
                            c0.28,0.16,0.59,0.24,0.89,0.24c0.62,0,1.23-0.33,1.56-0.91l0.42-0.73c1.54-2.69,1.54-6.03,0-8.72l-0.84-1.46
                            c-0.91-1.59-0.91-3.56,0-5.15l0.42-0.73c0.49-0.86,0.19-1.96-0.67-2.46c-0.86-0.49-1.96-0.19-2.46,0.67l-0.42,0.73
                            c-1.54,2.69-1.54,6.03,0,8.72L46.75,25.11z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}