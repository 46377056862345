export const UserIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M44.49,40.06c5.9-3.08,9.93-9.24,9.93-16.34C54.42,13.56,46.16,5.3,36,5.3s-18.42,8.26-18.42,18.42
                        c0,7.1,4.04,13.27,9.93,16.34c-9.33,3.46-16,12.45-16,22.97c0,0.74,0.03,1.49,0.1,2.23c0.09,0.99,0.97,1.72,1.95,1.63
                        c0.99-0.09,1.72-0.96,1.63-1.95c-0.06-0.63-0.09-1.27-0.09-1.9c0-11.52,9.37-20.88,20.88-20.88s20.88,9.37,20.88,20.88
                        c0,0.63-0.03,1.27-0.09,1.9c-0.09,0.99,0.64,1.87,1.63,1.95c0.06,0,0.11,0.01,0.16,0.01c0.92,0,1.71-0.7,1.79-1.64
                        c0.07-0.74,0.1-1.49,0.1-2.23C60.48,52.51,53.82,43.52,44.49,40.06z M21.18,23.72C21.18,15.55,27.83,8.9,36,8.9
                        s14.82,6.65,14.82,14.82S44.17,38.54,36,38.54S21.18,31.9,21.18,23.72z"/>
                </g>
            </g>      
        </svg>
    )
}

export const UsersIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M54.83,37.43c4.01-2.47,6.68-6.9,6.68-11.94c0-7.73-6.29-14.02-14.02-14.02c-3.66,0-7.05,1.42-9.58,3.79
                        c-1.77-5.79-7.17-9.93-13.4-9.93c-7.73,0-14.02,6.29-14.02,14.02c0,4.97,2.63,9.44,6.67,11.93c-6.64,2.86-11.13,9.44-11.13,16.96
                        c0,0.56,0.03,1.12,0.08,1.68c0.08,0.94,0.87,1.64,1.79,1.64c0.05,0,0.11,0,0.16-0.01c0.99-0.09,1.72-0.96,1.63-1.95
                        c-0.04-0.45-0.06-0.91-0.06-1.36c0-6.77,4.51-12.58,10.9-14.33c-0.01,0.22-0.02,0.45-0.02,0.68c0,5.04,2.68,9.47,6.68,11.94
                        c-6.55,2.84-11.14,9.37-11.14,16.95c0,0.56,0.03,1.13,0.08,1.68c0.09,0.99,0.96,1.72,1.96,1.63c0.99-0.09,1.72-0.97,1.63-1.96
                        c-0.04-0.45-0.06-0.9-0.06-1.36c0-8.2,6.67-14.87,14.87-14.87s14.87,6.67,14.87,14.87c0,0.45-0.02,0.91-0.06,1.36
                        c-0.09,0.99,0.64,1.87,1.63,1.96c0.06,0,0.11,0.01,0.16,0.01c0.92,0,1.71-0.7,1.79-1.64c0.05-0.55,0.08-1.12,0.08-1.68
                        c0-7.58-4.59-14.11-11.14-16.95c2.63-1.62,4.69-4.09,5.79-7.01c8.13,0.09,14.71,6.72,14.71,14.87c0,0.45-0.02,0.91-0.06,1.36
                        c-0.09,0.99,0.64,1.87,1.63,1.95c0.06,0,0.11,0.01,0.16,0.01c0.92,0,1.71-0.7,1.79-1.64c0.05-0.56,0.08-1.12,0.08-1.68
                        C65.96,46.8,61.37,40.28,54.83,37.43z M47.49,15.08c5.74,0,10.42,4.67,10.42,10.42c0,5.41-4.14,9.86-9.42,10.37
                        c0.04-0.42,0.06-0.84,0.06-1.27c0-6.5-4.45-11.99-10.47-13.56C39.78,17.45,43.41,15.08,47.49,15.08z M14.09,19.36
                        c0-5.74,4.67-10.42,10.42-10.42c5.22,0,9.66,3.9,10.33,9.06c0.06,0.43,0.08,0.86,0.08,1.3c-0.2,0.41-0.39,0.84-0.56,1.28
                        c-5.8,0.07-10.77,3.68-12.83,8.76C17.17,28.05,14.09,23.99,14.09,19.36z M34.53,45.01c-5.74,0-10.42-4.67-10.42-10.42
                        s4.67-10.42,10.42-10.42s10.42,4.67,10.42,10.42S40.28,45.01,34.53,45.01z"/>
                </g>
            </g>
        </svg>
    )
}

export const AdminUserIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" >
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M51.82,46.67c0.63-0.77,0.52-1.9-0.24-2.53c-2.13-1.76-4.53-3.12-7.09-4.07c5.9-3.07,9.94-9.25,9.94-16.35
                            C54.42,13.56,46.16,5.3,36,5.3s-18.42,8.26-18.42,18.42c0,7.1,4.04,13.27,9.93,16.34c-9.33,3.46-16,12.45-16,22.97
                            c0,0.74,0.03,1.49,0.1,2.23c0.08,0.94,0.87,1.64,1.79,1.64c0.05,0,0.11,0,0.16-0.01c0.99-0.09,1.72-0.96,1.63-1.95
                            c-0.06-0.63-0.09-1.27-0.09-1.9c0-11.52,9.37-20.88,20.88-20.88c4.84,0,9.56,1.69,13.28,4.77
                            C50.05,47.55,51.18,47.44,51.82,46.67z M21.18,23.72C21.18,15.55,27.83,8.9,36,8.9s14.82,6.65,14.82,14.82S44.17,38.54,36,38.54
                            S21.18,31.9,21.18,23.72z"/>
                        <path fill="currentColor" d="M65.76,59.19l-1.7-1.5c0.06-0.41,0.09-0.82,0.09-1.25c0-0.45-0.04-0.88-0.11-1.31l1.67-1.47
                            c0.19-0.17,0.24-0.45,0.11-0.67l-1.98-3.44c-0.13-0.22-0.4-0.33-0.64-0.24l-2.14,0.73c-0.65-0.52-1.38-0.94-2.16-1.24l-0.44-2.22
                            c-0.05-0.26-0.28-0.44-0.54-0.43h-3.95c-0.26-0.01-0.49,0.18-0.54,0.43l-0.45,2.24c-0.77,0.3-1.49,0.72-2.12,1.23l-2.21-0.75
                            c-0.24-0.08-0.51,0.02-0.64,0.24l-1.99,3.46c-0.12,0.23-0.07,0.51,0.13,0.67l1.72,1.51c-0.06,0.41-0.1,0.83-0.1,1.26
                            c0,0.43,0.03,0.85,0.1,1.26l-1.71,1.49c-0.19,0.17-0.24,0.45-0.11,0.67l1.98,3.44c0.13,0.22,0.4,0.33,0.64,0.24l2.15-0.73
                            c0.65,0.53,1.39,0.96,2.19,1.27l0.45,2.23c0.05,0.26,0.28,0.44,0.54,0.43h3.96c0.26,0.01,0.49-0.18,0.54-0.43l0.45-2.24
                            c0.79-0.31,1.52-0.73,2.17-1.26l2.17,0.73c0.24,0.08,0.51-0.02,0.64-0.24l1.98-3.44C66.03,59.63,65.97,59.35,65.76,59.19z
                            M55.96,61.77c-2.94,0-5.33-2.39-5.33-5.33c0-2.94,2.39-5.33,5.33-5.33s5.33,2.39,5.33,5.33C61.29,59.39,58.91,61.77,55.96,61.77
                            z"/>
                    </g>
                </g>
            </g>      
        </svg>
    )
}