export const SensorOnIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M36,6.18c-16.48,0-29.89,13.41-29.89,29.89S19.52,65.96,36,65.96s29.89-13.41,29.89-29.89S52.48,6.18,36,6.18
                        z M54.5,24.44l-25.1,25.1c-0.79,0.79-1.86,1.23-2.97,1.23c-0.1,0-0.21,0-0.32-0.01c-1.22-0.09-2.34-0.71-3.07-1.69l-6.92-9.38
                        c-0.59-0.8-0.42-1.93,0.38-2.52c0.8-0.59,1.93-0.42,2.52,0.38l6.92,9.38c0.14,0.19,0.34,0.23,0.44,0.24c0.1,0.01,0.3,0,0.47-0.17
                        l25.1-25.1c0.7-0.7,1.84-0.7,2.55,0C55.2,22.6,55.2,23.74,54.5,24.44z"/>
                    <g>
                        <g>
                            <g>
                                <path fill="currentColor" d="M69.33,13.68c-0.08,0-0.16-0.01-0.24-0.03c-0.54-0.13-0.88-0.68-0.75-1.22c0.59-2.46-0.13-5-1.92-6.79
                                    c-1.79-1.79-4.33-2.51-6.79-1.92c-0.54,0.13-1.09-0.21-1.22-0.75c-0.13-0.54,0.21-1.09,0.75-1.22c3.15-0.75,6.41,0.17,8.7,2.46
                                    c2.29,2.29,3.21,5.54,2.46,8.7C70.21,13.37,69.79,13.68,69.33,13.68z"/>
                                <path fill="currentColor" d="M65.75,13.69c-0.08,0-0.16-0.01-0.24-0.03c-0.54-0.13-0.88-0.68-0.75-1.22c0.35-1.45-0.08-2.95-1.13-4
                                    c-1.05-1.05-2.55-1.48-4-1.13c-0.54,0.13-1.09-0.21-1.22-0.75c-0.13-0.54,0.21-1.09,0.75-1.22C61.3,4.82,63.51,5.45,65.06,7
                                    c1.56,1.56,2.18,3.77,1.67,5.91C66.62,13.38,66.21,13.69,65.75,13.69z"/>
                            </g>
                        </g>
                        <circle  cx="60.35" cy="11.71" r="2.13"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const SensorOffIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                <rect  width="72" height="72"/>
                <g>
                    <g>
                        <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                            S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78z
                            "/>
                    </g>
                    <g>
                        <path fill="currentColor" d="M22.46,51.41c-0.46,0-0.92-0.18-1.27-0.53c-0.7-0.7-0.7-1.84,0-2.55l27.08-27.07c0.7-0.7,1.84-0.7,2.55,0
                            s0.7,1.84,0,2.55L23.73,50.88C23.38,51.23,22.92,51.41,22.46,51.41z"/>
                        <path fill="currentColor" d="M49.54,51.41c-0.46,0-0.92-0.18-1.27-0.53L21.19,23.8c-0.7-0.7-0.7-1.84,0-2.55c0.7-0.7,1.84-0.7,2.55,0
                            l27.08,27.07c0.7,0.7,0.7,1.84,0,2.55C50.46,51.23,50,51.41,49.54,51.41z"/>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path fill="currentColor" d="M69.33,13.68c-0.08,0-0.16-0.01-0.24-0.03c-0.54-0.13-0.88-0.68-0.75-1.22c0.59-2.46-0.13-5-1.92-6.79
                                    c-1.79-1.79-4.33-2.51-6.79-1.92c-0.54,0.13-1.09-0.21-1.22-0.75c-0.13-0.54,0.21-1.09,0.75-1.22c3.15-0.75,6.41,0.17,8.7,2.46
                                    c2.29,2.29,3.21,5.54,2.46,8.7C70.21,13.37,69.79,13.68,69.33,13.68z"/>
                                <path fill="currentColor" d="M65.75,13.69c-0.08,0-0.16-0.01-0.24-0.03c-0.54-0.13-0.88-0.68-0.75-1.22c0.35-1.45-0.08-2.95-1.13-4
                                    c-1.05-1.05-2.55-1.48-4-1.13c-0.54,0.13-1.09-0.21-1.22-0.75c-0.13-0.54,0.21-1.09,0.75-1.22C61.3,4.82,63.51,5.45,65.06,7
                                    c1.56,1.56,2.18,3.77,1.67,5.91C66.62,13.38,66.21,13.69,65.75,13.69z"/>
                            </g>
                        </g>
                        <circle fill="currentColor" cx="60.35" cy="11.71" r="2.13"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const DeviceOffIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                <rect  width="72" height="72"/>
                <g>
                    <g>
                        <path fill="currentColor" d="M36,28.78c-0.99,0-1.8-0.81-1.8-1.8V7.66c0-0.99,0.81-1.8,1.8-1.8s1.8,0.81,1.8,1.8v19.31
                            C37.8,27.97,36.99,28.78,36,28.78z"/>
                        <path fill="currentColor" d="M36,66.87c-14.72,0-26.69-11.97-26.69-26.69c0-10.27,6-19.75,15.29-24.14c0.9-0.43,1.97-0.04,2.4,0.86
                            c0.42,0.9,0.04,1.97-0.86,2.4c-8.04,3.8-13.23,12-13.23,20.89c0,12.73,10.36,23.09,23.09,23.09s23.09-10.36,23.09-23.09
                            c0-8.89-5.19-17.09-13.23-20.89c-0.9-0.43-1.28-1.5-0.86-2.4c0.43-0.9,1.5-1.28,2.4-0.86c9.29,4.39,15.29,13.87,15.29,24.14
                            C62.69,54.89,50.72,66.87,36,66.87z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const DeviceOffCircleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
	            <rect  width="72" height="72"/>
                <g>
                    <g>
                        <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                            S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78z
                            "/>
                    </g>
                    <g>
                        <path fill="currentColor" d="M36,28.93c-0.99,0-1.8-0.81-1.8-1.8V16.07c0-0.99,0.81-1.8,1.8-1.8s1.8,0.81,1.8,1.8v11.06
                            C37.8,28.12,36.99,28.93,36,28.93z"/>
                        <path fill="currentColor" d="M36,56.93c-10.99,0-19.93-8.94-19.93-19.93c0-7.67,4.48-14.74,11.41-18.02c0.9-0.43,1.97-0.04,2.4,0.86
                            c0.42,0.9,0.04,1.97-0.86,2.4c-5.68,2.69-9.35,8.48-9.35,14.77c0,9,7.32,16.33,16.33,16.33S52.33,46.01,52.33,37
                            c0-6.28-3.67-12.08-9.35-14.77c-0.9-0.43-1.28-1.5-0.86-2.4c0.43-0.9,1.5-1.28,2.4-0.86C51.45,22.26,55.93,29.34,55.93,37
                            C55.93,47.99,46.99,56.93,36,56.93z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}