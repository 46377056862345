export const SettingsIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <circle fill="currentColor" cx="55.35" cy="55.06" r="2.83"/>
                        <path fill="currentColor" d="M62.92,49.11L43.85,30.04c1.64-6.52-0.23-13.39-5.01-18.18c-4.04-4.04-9.62-6.04-15.32-5.5
                            c-0.85,0.08-1.53,0.75-1.62,1.6l-0.3,2.87c-0.06,0.54,0.13,1.08,0.52,1.46l6.88,6.88l-2.09,7.8l-7.8,2.09l-6.88-6.88
                            c-0.38-0.38-0.91-0.58-1.46-0.52l-2.87,0.3c-0.85,0.09-1.52,0.77-1.6,1.62c-0.54,5.7,1.47,11.29,5.5,15.32
                            c4.78,4.78,11.66,6.65,18.18,5.01l19.06,19.06c1.91,1.91,4.43,2.87,6.94,2.87c2.51,0,5.03-0.96,6.94-2.87
                            C66.75,59.16,66.75,52.93,62.92,49.11z M60.37,60.44c-2.42,2.42-6.37,2.42-8.79,0L31.77,40.63c-0.34-0.34-0.8-0.53-1.27-0.53
                            c-0.18,0-0.36,0.03-0.54,0.08c-5.54,1.73-11.53,0.27-15.62-3.82c-2.93-2.93-4.54-6.86-4.54-10.98l0.48-0.05l7,7
                            c0.45,0.45,1.12,0.63,1.74,0.47l9.81-2.63c0.62-0.17,1.11-0.65,1.27-1.27l2.63-9.81c0.17-0.62-0.01-1.28-0.47-1.74l-7-7
                            l0.05-0.48c0.01,0,0.01,0,0.02,0c4.1,0,8.03,1.61,10.96,4.54c4.09,4.09,5.56,10.08,3.82,15.62c-0.2,0.64-0.03,1.34,0.45,1.81
                            l19.81,19.81C62.8,54.07,62.8,58.02,60.37,60.44z"/>
                    </g>
                </g>
            </g>
        </svg>
	)
}