export const StarOutlineIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                <rect  width="72" height="72"/>
                <g>
                    <path fill="currentColor" d="M20.04,64.09c-0.86,0-1.72-0.27-2.47-0.82c-1.5-1.09-2.1-2.93-1.53-4.7l5.02-15.44
                        c0.08-0.25-0.01-0.52-0.22-0.67L7.71,32.93c-1.5-1.09-2.1-2.93-1.53-4.7c0.57-1.76,2.14-2.9,3.99-2.9h16.24
                        c0.26,0,0.49-0.17,0.57-0.41l5.02-15.44c0.57-1.76,2.14-2.9,3.99-2.9s3.42,1.14,3.99,2.9l0,0l5.02,15.44
                        c0.08,0.25,0.31,0.41,0.57,0.41h16.24c1.85,0,3.42,1.14,3.99,2.9c0.57,1.76-0.03,3.61-1.53,4.7l-13.13,9.54
                        c-0.21,0.15-0.3,0.42-0.22,0.67l5.02,15.44c0.57,1.76-0.03,3.61-1.53,4.7c-1.5,1.09-3.44,1.09-4.94,0l-13.13-9.54
                        c-0.21-0.15-0.49-0.15-0.71,0l-13.14,9.54C21.76,63.82,20.9,64.09,20.04,64.09z M10.18,28.93c-0.36,0-0.51,0.23-0.57,0.41
                        c-0.06,0.19-0.07,0.46,0.22,0.67l13.13,9.54c1.48,1.07,2.09,2.96,1.53,4.7l-5.02,15.44c-0.11,0.34,0.06,0.55,0.22,0.67
                        c0.16,0.12,0.41,0.21,0.71,0l13.14-9.54c1.48-1.07,3.46-1.07,4.94,0l13.14,9.54c0.29,0.21,0.54,0.12,0.71,0
                        c0.16-0.12,0.33-0.33,0.22-0.67l-5.02-15.44c-0.56-1.74,0.05-3.62,1.53-4.7l13.13-9.54c0.29-0.21,0.28-0.48,0.22-0.67
                        c-0.06-0.19-0.21-0.41-0.57-0.41H45.58c-1.83,0-3.43-1.17-3.99-2.9l-5.02-15.44c-0.11-0.34-0.37-0.41-0.57-0.41
                        s-0.46,0.07-0.57,0.41l-5.02,15.44c-0.56,1.74-2.17,2.9-3.99,2.9H10.18z"/>
                </g>
            </g>
        </svg>
    )
}

export const StarFilledIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M65.81,28.23c-0.57-1.76-2.14-2.9-3.99-2.9H45.58c-0.26,0-0.49-0.17-0.57-0.41L39.99,9.47c0,0,0,0,0,0
                        c-0.57-1.76-2.14-2.9-3.99-2.9s-3.42,1.14-3.99,2.9l-5.02,15.44c-0.08,0.25-0.31,0.42-0.57,0.42H10.18c-1.85,0-3.42,1.14-3.99,2.9
                        c-0.57,1.76,0.03,3.61,1.53,4.7l13.14,9.54c0.21,0.15,0.3,0.42,0.22,0.67l-5.02,15.44c-0.57,1.76,0.03,3.61,1.53,4.7
                        c0.75,0.54,1.61,0.82,2.47,0.82c0.86,0,1.72-0.27,2.47-0.82l13.13-9.54c0.21-0.15,0.5-0.15,0.71,0l13.13,9.54
                        c1.5,1.09,3.44,1.09,4.94,0c1.5-1.09,2.1-2.93,1.53-4.7l-5.02-15.44c-0.08-0.25,0.01-0.52,0.22-0.67l13.14-9.54
                        C65.79,31.84,66.39,29.99,65.81,28.23z"/>
                </g>
            </g>
        </svg>
    )
}

export const StarCircleOutlineIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                <rect  width="72" height="72"/>
                <g>
                    <g>
                        <g>
                            <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                                S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78
                                z"/>
                        </g>
                        <path fill="currentColor" d="M47.54,56.71c-0.74,0-1.47-0.23-2.12-0.7L36,49.17l-9.42,6.84c-1.29,0.93-2.95,0.93-4.23,0
                            c-1.29-0.93-1.8-2.51-1.31-4.02l3.6-11.07l-9.42-6.84c-1.29-0.93-1.8-2.51-1.31-4.02s1.83-2.49,3.42-2.49h11.64l3.6-11.07
                            C33.07,14.97,34.41,14,36,14s2.93,0.98,3.42,2.49l3.6,11.07h11.64c1.59,0,2.93,0.98,3.42,2.49s-0.02,3.09-1.31,4.02l-9.42,6.84
                            l3.6,11.07c0.49,1.51-0.02,3.09-1.31,4.02C49.01,56.48,48.27,56.71,47.54,56.71z M36,45.56c0.74,0,1.48,0.23,2.12,0.69l9.42,6.84
                            l-3.6-11.07c-0.48-1.49,0.04-3.11,1.31-4.02l9.42-6.84H43.02c-1.56,0-2.94-1-3.42-2.49L36,17.6l0,0c0-0.02-0.01-0.02-0.01-0.02
                            L32.4,28.67c-0.48,1.49-1.86,2.49-3.42,2.49H17.34L26.75,38c1.27,0.92,1.79,2.54,1.31,4.02l-3.6,11.07l9.42-6.84
                            C34.52,45.79,35.26,45.56,36,45.56z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const StarCircleFilledIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                            S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78z
                            "/>
                    </g>
                    <path fill="currentColor" d="M58.09,30.04c-0.49-1.51-1.83-2.49-3.42-2.49H43.02l-3.6-11.07C38.93,14.97,37.59,14,36,14
                        s-2.93,0.98-3.42,2.49l-3.6,11.07H17.34c-1.59,0-2.93,0.98-3.42,2.49s0.02,3.09,1.31,4.02l9.42,6.84l-3.6,11.07
                        c-0.49,1.51,0.02,3.09,1.31,4.02c1.29,0.93,2.95,0.93,4.23,0L36,49.17l9.42,6.84c0.64,0.47,1.38,0.7,2.12,0.7
                        c0.74,0,1.47-0.23,2.12-0.7c1.29-0.93,1.8-2.51,1.31-4.02l-3.6-11.07l9.42-6.84C58.07,33.14,58.58,31.56,58.09,30.04z"/>
                </g>
            </g>
        </svg>
    )
}