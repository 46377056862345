import React, { useEffect, useState } from 'react'
import { Camera, CameraResultType } from '@capacitor/camera'
import { getCommandService } from '../services/command.service'

export default function EventLogView() {
    const commandService = getCommandService()
    const [imageSaved, setImageSaved] = useState(false)

    const [newImageSource, setNewImageSource] = useState(null)
    const [recentPictures, setRecentPictures] = useState([])

    const takePicture = async () => {
        setImageSaved(false)
        
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          saveToGallery: true,
          resultType: CameraResultType.Uri,
          width: 640,
          height: 480
        })
        
        setNewImageSource(image.webPath)
    }

    const formatPicture = (imgUri) => {
        return new Promise(async (resolve, _) => {
            const response = await fetch(imgUri)
            const blob = await response.blob()
            
            const fileReader = new FileReader()

            fileReader.readAsDataURL(blob)
    
            fileReader.onloadend = function(){
              resolve(fileReader.result)
            }
          })
    }

    const setPicture = (e, src) => {
        e.preventDefault()
        setImageSaved(true)
        setNewImageSource(src)
    }

    const deletePicture = (e, src) => {
        e.preventDefault()

        setNewImageSource(null)
        setRecentPictures(recentPictures.filter(i => i !== src))
        localStorage.setItem('photoGallery')
        setImageSaved(false)
    }

    const savePicture = () => {
        setRecentPictures([...recentPictures, newImageSource])
        localStorage.setItem('photoGallery', recentPictures)
        setImageSaved(true)
    }

    const sendPicture = async (img) => {
        const formattedImg = await formatPicture(img)
        console.warn('@todo send image via command', formattedImg)

        // commandService.queueCommand({
        //     command: [],
        //     commandType: null,
        //     commandData: formattedImg
        // })
     }

    const getRecentPictures = () => {
        const galleryItems = localStorage.getItem('photoGallery')
        
        if (galleryItems) {
            setRecentPictures(galleryItems.split(','))
        }
    }

    useEffect(() => {
        localStorage.removeItem('photoGallery')
        // getRecentPictures()
    }, [])


    return (
        <div className="w-full my-2">
            <p className="text-ata-d-burnt-sienna text-center pb-1">Demo Mode</p>

            <div className="w-full px-2.5">
                <div className="md:flex md:justify-center">
                    <div className="max-w-80vw md:w-1/2">
                        { newImageSource ? 
                            <img 
                                src={newImageSource} 
                                className="border-ata-a-outer-space-crayola dark:border-ata-l-baby-powder border rounded block mx-auto"
                                style={{ maxHeight: `70vh`, maxWidth: `100%` }} 
                            /> : '' 
                        }
                    </div>

                    <div className="my-2 md:mt-0 md:ml-2 flex md:block md:flex-grow-0 md:w-1/5 w-full pb-2 overflow-y-auto border-ata-a-outer-space-crayola dark:border-ata-l-baby-powder">
                        {
                            recentPictures && recentPictures.length > 0 ? 
                                recentPictures.map((imgSrc, index) => {
                                    return (
                                        <div key={index} className="w-1/3 md:w-full m-2 md:mx-0 md:mt-0 flex-shrink-0 border rounded h-16 overflow-hidden" style={{ height: `90px` }}>
                                            <img className="min-h-full" src={imgSrc} onClick={(e) => { setPicture(e, imgSrc) }} />
                                        </div>
                                    )
                                }) : ''
                        }
                    </div>
                </div>


                <div className="p-2 flex justify-between fixed bottom-0 left-0 w-screen">
                    {
                        newImageSource && imageSaved ? 
                            <button 
                                className="border p-2 rounded-lg block"
                                onClick={(e) => { deletePicture(e, newImageSource) }}>Delete</button>
                            : ''
                    }

                    { 
                        newImageSource && !imageSaved ? 
                            <button className="border p-2 rounded-lg block" onClick={(e) => { savePicture(e) }}>Save</button> : ''
                    }

                    <button onClick={(e) => { takePicture(e) }} className="border p-2 rounded-lg mx-auto block">Take Picture</button>

                    { 
                        newImageSource ?
                            <button className="border p-2 rounded-lg block" onClick={(e) => { sendPicture(e) }}>Send</button> : ''
                    }
                </div>
                
            </div>
        </div>
    )
}