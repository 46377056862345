import React, { useState } from 'react'
import { getLogs, deleteLogs } from '../services/log.service'
import { useLiveQuery } from 'dexie-react-hooks'
import Table from '../components/table/Table'

export default function EventLogView() {
    const [formattedLog, setFormattedLog] = useState({
        header: {cells: ['timestamp', 'type', 'description', 'detail']},
        rows: [] 
    })

    const db = getLogs()
    
    useLiveQuery(async () => {
        const events = await db.events?.toArray()

        if (events) {
            try {
                const format = events?.map((event) => {
                    return {
                        cells: [event.timestamp, event.type, event.details, event.stacktrace]
                    }
                })
                setFormattedLog({ ...formattedLog, ...{rows: format} })
            } catch (error) {
                console.error(error)
            }
        }

        return events
    })

    const clearLogs = () => {
        deleteLogs()
    }

    return (
        <div className="w-full my-2">
            <div className="flex w-full justify-end px-2.5">
                <button 
                    className="border rounded border-ata-d-burnt-sienna text-ata-d-burnt-sienna px-2 h-8 mt-1.5"
                    onClick={(e) => { clearLogs(e) }}
                >Clear Logs</button>
            </div>
            <div className="w-full">
                {formattedLog ? <Table tableData={formattedLog} enableIcons={true} /> : ''}
            </div>
        </div>
    )
}