import React from 'react'

export function AutoConnectToggle({ autoConnect, setAutoConnect }) {
    return (
        <div className="auto-connect-toggle px-2 justify-center flex">
          <label htmlFor="auto-connect-toggle" className="inline-flex relative items-center cursor-pointer">
            <input type="checkbox" value="" checked={autoConnect} id="auto-connect-toggle" className="sr-only peer" onChange={() => setAutoConnect(!autoConnect)} />
            <div className="
              w-11 h-6 rounded-full
              peer peer-focus:outline-none 
               peer-checked:after:translate-x-full peer-checked:after:border-white 
              after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
              dark:border-ata-d-cultured peer-checked:bg-blue-600 border
            "></div>
            <span className="flex-1 ml-3 whitespace-nowrap text-xs font-medium text-ata-a-outer-space-crayola dark:text-ata-d-cultured">Connect to favorite automatically</span>
          </label>
        </div>
    )
  }