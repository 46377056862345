import React from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../../actions'
import { getCommandService } from '../../services/command.service'

import { 
    HomeFilledIcon,
    ChevronLeftLargeIcon,
    LockClosedFilledIcon 
} from '../icons'

export default function RouteLink({ button }) {
    const commandService = getCommandService()
    const dispatch = useDispatch()

    const handleClick = (e) => {
        e.preventDefault()

        if (button?.buttonTitle === 'back') {
            commandService.sendBackCommand()
        }

        if (button?.buttonTitle === 'home') {
            commandService.sendHomeCommand()
        }

        if(button?.buttonTitle === 'lock') {
            dispatch(logout())
            window.location.href = '/'
        }

    }

    const setIcon = () => {
        if(button?.buttonTitle === 'back') {
            return (
                <ChevronLeftLargeIcon />
            )
        }

        if(button?.buttonTitle === 'home') {
            return (
                <HomeFilledIcon />
            )
        }

        if(button?.buttonTitle === 'lock') {
            return (
                <LockClosedFilledIcon />
            )
        }
    }

    return (
        <button onClick={(e) => { handleClick(e) }} className="block mx-2 w-5 rounded active:bg-ata-a-gainsboro dark:active:bg-ata-d-liver">
            { setIcon() }
        </button>
    )

}