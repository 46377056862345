import { useEffect, useState } from 'react'
import { DayPicker as ReactDayPicker } from 'react-day-picker'
/**
 * @description - a wrapper for react-day-picker
 * 
 * modes - single, range, multiple
 * full documentation: https://react-day-picker.js.org/
 * 
 * @returns react-day-picker
 */
export default function DatePicker2({
    label,
    preSelectedDay,
    onChange
}) {

    const [selectedDay, setSelectedDay] = useState(preSelectedDay)

    const handleOnDayClick = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
        const year = date.getFullYear();

        const formattedDate = `${day}-${month}-${year}`;
        setSelectedDay(formattedDate)
        onChange(label, formattedDate)
    }

    function convertToDate(dateString) {
        const [day, month, year] = dateString.split('-').map(Number);

        const date = new Date(year, month - 1, day);
        return date
    }

    return (
        <div className="w-full">
            <ReactDayPicker
                mode='single'
                selected={convertToDate(selectedDay)}
                onDayClick={handleOnDayClick}
            />
        </div>
    )
}