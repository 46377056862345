import { useEffect, useState } from 'react'
import { DayPicker as ReactDayPicker } from 'react-day-picker'
/**
 * @description - a wrapper for react-day-picker
 * 
 * modes - single, range, multiple
 * full documentation: https://react-day-picker.js.org/
 * 
 * @returns react-day-picker
 */
export default function DatePicker({
        preSelectedDates = [], 
        mode = 'range', 
        onChange = () => {}
    }) {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date().setMonth(startDate.getMonth() + 1))
    const [selectedDates, setSelectedDates] = useState(preSelectedDates)

    const handleOnSelect = (selection, modifiers) => {        
        if (mode === 'multiple') {
            const selected = [...selectedDates]
            
            modifiers.selected 
                ? selected.splice(selectedDates.indexOf(selected), 1)
                : selected.push(selection)

            setSelectedDates(selection)
            onChange(selectedDates)
        } else {
            setSelectedDates(selection)
        }

    }
    useEffect(() => {
        if (startDate > endDate) setStartDate(endDate)
        if (startDate > endDate) setEndDate(startDate)
    }, [startDate, endDate])

    return (
        <ReactDayPicker
            mode={mode}
            selected={selectedDates}
            onSelect={handleOnSelect}
        />
    )
}