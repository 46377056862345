export const EntranceIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M51.97,5.27H19.99c-2.98,0-5.4,2.42-5.4,5.4v50.81c0,2.98,2.42,5.4,5.4,5.4h31.98c2.98,0,5.4-2.42,5.4-5.4
                        V10.67C57.37,7.69,54.94,5.27,51.97,5.27z M18.19,61.56c0-0.03-0.01-0.06-0.01-0.09V10.67c0-0.05,0.01-0.1,0.02-0.15l19.15,6.73
                        c0.72,0.25,1.2,0.94,1.2,1.7v33.23c0,0.74-0.44,1.39-1.13,1.67L18.19,61.56z M53.77,61.47c0,0.99-0.81,1.8-1.8,1.8H23.59
                        l15.17-6.09c2.06-0.83,3.39-2.79,3.39-5.01V18.94c0-2.29-1.45-4.34-3.61-5.09L24.37,8.87h27.6c0.99,0,1.8,0.81,1.8,1.8V61.47z"/>
                </g>
            </g>
        </svg>
    )
}