
const appDefaults = {
    hmi_timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT_DEFAULT),
    dc_timeout: parseInt(process.env.REACT_APP_DC_CONNECTION_TIMEOUT_DEFAULT),
    log_events: (process.env.REACT_APP_LOG_EVENTS.toLowerCase() === 'true'),
    send_events: (process.env.REACT_APP_SEND_EVENTS.toLowerCase() === 'true'),
    log_limit: parseInt(process.env.REACT_APP_LOG_LIMIT),
    refresh_interval: parseInt(process.env.REACT_APP_REFRESH_COMMAND_INTERVAL),
    favorite_connection: null,
    insecure_remember_me: false,
    auto_connect: false
}

let appSettings = appDefaults

export const getInitalAppSettings = () => {
    const settings = localStorage.getItem('settings')

    if (settings) {
        appSettings = JSON.parse(settings)
    }

    if (!settings) {
        localStorage.setItem('settings', JSON.stringify(appSettings))
    }

    return appSettings
}

export const getAppSetting = (key) => {
    return appSettings[key]
}

export const getAppSettings = () => {
    return appSettings
}

export const saveAppSetting = (key, value) => {
    appSettings[key] = value
    localStorage.setItem('settings', JSON.stringify(appSettings))
}

export const resetToDefaultSettings = (key) => {
    appSettings = appDefaults
    localStorage.setItem('settings', JSON.stringify(appSettings))
}