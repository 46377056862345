import { getAppSetting } from './settings.service'
import Dexie from 'dexie'

let db = null

export const initLogDB = async () => {
    if (!db) {
        db = true
        const dbExists = await Dexie.exists('EventLogs')
        db = new Dexie('EventLogs')

        db.version(2).stores({
            events: '++id, type, details, stacktrace, timestamp'
        })

        if (!dbExists) {
            writeLog('Setup ', 'Log Database Created')
        }
    } 
}

export const getLogs = () => {
    return db
}

export const deleteLogs = () => {
     db.events.clear()
}

export const writeLog = async (type, details, stacktrace = '') => {
    const logDBsize = await db.events.count()

    if ( getAppSetting('log_events') && logDBsize < parseInt(getAppSetting('log_limit')) ) {
        try {
            const timestamp = new Date().toLocaleString()
            
            if (typeof stacktrace === 'object' && stacktrace !== null) { 
                stacktrace = JSON.stringify(stacktrace) 
            }
            
            await db.events.add({ type, details, stacktrace, timestamp })
            sendLog() 
        } catch (error) {
            console.error(`Failed to add ${type}: ${error}`)
        }
    }
}

const sendLog = (key) => {
    if (getAppSetting('send_events')) {
        console.log('send log...')
        // send a log to somewhere
    }
}