const Screen = (state = null, action) => {
	const { type, payload } = action

	switch (type) {
		case 'FETCH_SCREEN_SUCCESS':
			return {
				...state,
				...payload
			}
		case 'FETCH_SCREEN_FAIL':
			return {
				...state
			}
		case 'UPDATE_SCREEN_SUCCESS':
			const primaryKey = Object.keys(state.data)[0]
			const newState = state.data[primaryKey][primaryKey+'Info'] = payload.data[primaryKey+'Info']

			return {
				...state,
				...newState
			}
		case 'UPDATE_SCREEN_FAIL':
			return {
				...state
			}
		default:
			return state
	}
}

export default Screen
