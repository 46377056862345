export const ScheduleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M60.6,66.64H11.4c-2.98,0-5.4-2.42-5.4-5.4V15.31c0-2.98,2.42-5.4,5.4-5.4H60.6c2.98,0,5.4,2.42,5.4,5.4
                        v45.93C66,64.22,63.57,66.64,60.6,66.64z M11.4,13.51c-0.99,0-1.8,0.81-1.8,1.8v45.93c0,0.99,0.81,1.8,1.8,1.8H60.6
                        c0.99,0,1.8-0.81,1.8-1.8V15.31c0-0.99-0.81-1.8-1.8-1.8H11.4z"/>
                    <g>
                        <path fill="currentColor" d="M24.7,18.03c-0.99,0-1.8-0.81-1.8-1.8V7.16c0-0.99,0.81-1.8,1.8-1.8s1.8,0.81,1.8,1.8v9.07
                            C26.5,17.23,25.7,18.03,24.7,18.03z"/>
                        <path fill="currentColor" d="M47.81,18.03c-0.99,0-1.8-0.81-1.8-1.8V7.16c0-0.99,0.81-1.8,1.8-1.8c0.99,0,1.8,0.81,1.8,1.8v9.07
                            C49.61,17.23,48.8,18.03,47.81,18.03z"/>
                    </g>
                    <g>
                        <path fill="currentColor" d="M28.89,37.73h-9.26c-1.65,0-3-1.35-3-3v-8.44c0-1.65,1.35-3,3-3h9.26c1.65,0,3,1.35,3,3v8.44
                            C31.89,36.38,30.55,37.73,28.89,37.73z M20.23,34.13h8.06v-7.24h-8.06V34.13z"/>
                        <path fill="currentColor" d="M52.37,37.73h-9.26c-1.65,0-3-1.35-3-3v-8.44c0-1.65,1.35-3,3-3h9.26c1.65,0,3,1.35,3,3v8.44
                            C55.37,36.38,54.02,37.73,52.37,37.73z M43.71,34.13h8.06v-7.24h-8.06V34.13z"/>
                        <path fill="currentColor" d="M28.89,57.67h-9.26c-1.65,0-3-1.35-3-3v-8.44c0-1.65,1.35-3,3-3h9.26c1.65,0,3,1.35,3,3v8.44
                            C31.89,56.33,30.55,57.67,28.89,57.67z M20.23,54.07h8.06v-7.24h-8.06V54.07z"/>
                        <path fill="currentColor" d="M52.37,57.67h-9.26c-1.65,0-3-1.35-3-3v-8.44c0-1.65,1.35-3,3-3h9.26c1.65,0,3,1.35,3,3v8.44
                            C55.37,56.33,54.02,57.67,52.37,57.67z M43.71,54.07h8.06v-7.24h-8.06V54.07z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}