import { screenKeys } from '../constants/screenkeys.constant' 

/**
 * @description - helps the react app generate an internal http route
 * @param {string} screenKey - the name of primary key for the screen to be rendered
 * @returns {string|false} - returns a string if the screenKey is registered in screenKeys.constant
 */
 export const formatCommandRoute = (screenKey = '') => {

    if (!screenKey) {
        // return the first screen
        return '/button'
    }  
    if (screenKey.includes('modal')) {
        // it's a model do not return a route
        return false
    }  
    
    if (screenKey) {
        let routeValue = ''

        // it's a screen find which screen type this screen belongs to and return it's custom route
        for (const [type, value] of Object.entries(screenKeys)) {
            if (value.includes(screenKey)) {
                routeValue = `/${type}/${screenKey}`
                break
            }
        }

        if (routeValue) { return routeValue }
    } 
    
    return false
}