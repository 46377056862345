import React, { useState, useEffect } from 'react'
import LoginForm from '../components/auth/LoginForm'
import { login } from '../actions'
import { useDispatch } from 'react-redux'
import useDarkMode from '../utils/useDarkMode'

export default function LoginView({ isLoggedIn }) {
    const dispatch = useDispatch()
    const [loggedIn, setLoggedIn] = useState(isLoggedIn)

    const handleOnLogin = (user) => {
        dispatch( login( user ) )
        setLoggedIn(true)
    }

    useDarkMode()

    useEffect(() => {
        setLoggedIn(isLoggedIn)
    }, [isLoggedIn])

    return (
        <div className="flex w-full justify-center">
            { !loggedIn  ? <LoginForm onLogin={handleOnLogin} /> : '' }
        </div>
    )
}