export const HomeOutlineIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
    )
}

export const HomeFilledIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-full" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
        </svg>
    )
}

export const HomeIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M65,31.44l-8.44-8.24V11.43c0-1.65-1.35-3-3-3h-4.6c-1.65,0-3,1.35-3,3v1.44l-6.19-6.04
                        c-2.11-2.06-5.43-2.06-7.54,0L7,31.44c-0.88,0.86-1.15,2.14-0.68,3.28c0.46,1.14,1.55,1.87,2.78,1.87h2.89v24.93
                        c0,2.98,2.42,5.4,5.4,5.4h36.89c2.98,0,5.4-2.42,5.4-5.4V36.59h3.23c1.23,0,2.32-0.73,2.78-1.87C66.14,33.59,65.88,32.3,65,31.44z
                        M57.88,32.99c-0.99,0-1.8,0.81-1.8,1.8c0,0.06,0.01,0.13,0.02,0.19c-0.01,0.06-0.02,0.12-0.02,0.19v26.35
                        c0,0.99-0.81,1.8-1.8,1.8H17.38c-0.99,0-1.8-0.81-1.8-1.8V35.17c0-0.06-0.01-0.13-0.02-0.19c0.01-0.06,0.02-0.12,0.02-0.19
                        c0-0.99-0.81-1.8-1.8-1.8h-3.21L34.74,9.41c0.71-0.69,1.81-0.69,2.51,0l9.25,9.02c0.71,0.69,1.85,0.68,2.55-0.03
                        c0.38-0.39,0.54-0.91,0.5-1.42c0-0.05,0.01-0.1,0.01-0.15v-4.8h3.4v11.82c0,0.02,0.01,0.04,0.01,0.06
                        c-0.01,0.49,0.16,0.98,0.54,1.34l7.93,7.74H57.88z"/>
                </g>
            </g>
        </svg>
    )
}

