export const ClockIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M36,65.96c-16.48,0-29.89-13.41-29.89-29.89S19.52,6.18,36,6.18s29.89,13.41,29.89,29.89
                            S52.48,65.96,36,65.96z M36,9.78c-14.5,0-26.29,11.8-26.29,26.29S21.5,62.36,36,62.36s26.29-11.8,26.29-26.29S50.5,9.78,36,9.78z
                            "/>
                        <path fill="currentColor" d="M36.01,38.28c-0.46,0-0.92-0.18-1.27-0.53l-13.5-13.5c-0.7-0.7-0.7-1.84,0-2.55c0.7-0.7,1.84-0.7,2.55,0
                            l12.23,12.23l7.81-7.81c0.7-0.7,1.84-0.7,2.55,0c0.7,0.7,0.7,1.84,0,2.55l-9.08,9.08C36.93,38.11,36.47,38.28,36.01,38.28z"/>
                    </g>
                </g>
            </g>     
        </svg>
    )
}