import React from 'react'

export default function TimePicker({label, value}) {

    const handleStartTimeChange = () => {

    } 

    const handleEndTimeChange = () => {
        
    } 

    return (
        <div>
            <label 
                htmlFor={label} 
                className="
                    block mb-2 text-sm font-medium text-gray-900 
                    dark:text-gray-300
                "
            >
                {label}
            </label>
            <div className="flex justify-center pt-2">

                <div className="mr-1">
                    <input
                        className="
                            block w-full p-2.5 border text-sm rounded-lg 
                            focus:ring-ata-a-june-bud
                            focus:border-ata-a-june-bud   
                            dark:bg-ata-d-dark-liver 
                            dark:border-ata-d-cultured
                            dark:placeholder-ata-d-gray-web
                            dark:text-ata-d-cultured
                            dark:focus:ring-ata-d-yellow-green 
                            dark:focus:border-ata-d-yellow-green
                        "  
                        type="time" 
                        id="start" 
                        name="start"
                        onChange={handleStartTimeChange}  
                    />
                </div>

                <div className="ml-1">
                    <input
                        className="
                            block w-full p-2.5 border text-sm rounded-lg 
                            focus:ring-ata-a-june-bud
                            focus:border-ata-a-june-bud   
                            dark:bg-ata-d-dark-liver 
                            dark:border-ata-d-cultured
                            dark:placeholder-ata-d-gray-web
                            dark:text-ata-d-cultured
                            dark:focus:ring-ata-d-yellow-green 
                            dark:focus:border-ata-d-yellow-green
                        "   
                        type="time" 
                        id="end" 
                        name="end"
                        onChange={handleEndTimeChange} 
                    />
                </div>

            </div>


        </div>
    )
}