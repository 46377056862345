export const ThermometerIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M44.96,38.81V14.49c0-4.68-3.54-8.69-8.07-9.14c-2.51-0.25-5.02,0.58-6.9,2.28
                            c-1.88,1.7-2.95,4.12-2.95,6.63v24.55c-4.36,3.11-6.77,8.13-6.46,13.5c0.45,7.78,6.66,14.02,14.44,14.5
                            c0.33,0.02,0.67,0.03,1,0.03c3.95,0,7.67-1.47,10.56-4.19c3.09-2.91,4.87-7.01,4.87-11.26C51.44,46.39,49.03,41.71,44.96,38.81z
                            M44.11,60.02c-2.41,2.26-5.55,3.39-8.87,3.19c-5.86-0.36-10.72-5.25-11.06-11.11c-0.24-4.19,1.68-8.11,5.14-10.48
                            c0.83-0.57,1.33-1.5,1.33-2.49V14.26c0-1.5,0.64-2.95,1.77-3.97c1-0.91,2.26-1.39,3.58-1.39c0.18,0,0.36,0.01,0.54,0.03
                            c2.7,0.27,4.82,2.7,4.82,5.55v24.64c0,0.99,0.5,1.92,1.33,2.49c3.23,2.21,5.16,5.87,5.16,9.78
                            C47.84,54.69,46.52,57.76,44.11,60.02z"/>
                        <path fill="currentColor" d="M37.8,42.9V21.17c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8V42.9c-3.94,0.83-6.91,4.34-6.91,8.52
                            c0,4.8,3.91,8.71,8.71,8.71s8.71-3.91,8.71-8.71C44.71,47.23,41.74,43.73,37.8,42.9z M36,56.54c-2.82,0-5.11-2.29-5.11-5.11
                            s2.29-5.11,5.11-5.11s5.11,2.29,5.11,5.11S38.82,56.54,36,56.54z"/>
                        <path fill="currentColor" d="M48.42,15.81h1.81c0.99,0,1.8-0.81,1.8-1.8s-0.81-1.8-1.8-1.8h-1.81c-0.99,0-1.8,0.81-1.8,1.8
                            S47.43,15.81,48.42,15.81z"/>
                        <path fill="currentColor" d="M50.24,17.9h-1.81c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8h1.81c0.99,0,1.8-0.81,1.8-1.8
                            C52.04,18.7,51.23,17.9,50.24,17.9z"/>
                        <path fill="currentColor" d="M50.24,23.58h-1.81c-0.99,0-1.8,0.81-1.8,1.8s0.81,1.8,1.8,1.8h1.81c0.99,0,1.8-0.81,1.8-1.8
                            S51.23,23.58,50.24,23.58z"/>
                        <path fill="currentColor" d="M50.24,29.26h-1.81c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8h1.81c0.99,0,1.8-0.81,1.8-1.8
                            C52.04,30.07,51.23,29.26,50.24,29.26z"/>
                        <path fill="currentColor" d="M50.24,34.94h-1.81c-0.99,0-1.8,0.81-1.8,1.8s0.81,1.8,1.8,1.8h1.81c0.99,0,1.8-0.81,1.8-1.8
                            S51.23,34.94,50.24,34.94z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}