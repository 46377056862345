import React from 'react'

import {
    AdjustIcon,
    AdminUserIcon,
    AudioIcon,
    AwayIcon,
    BarIcon,
    BathIcon,
    BathroomHeatIcon,
    BathroomIcon,
    BedDoubleIcon,
    BedIcon,
    BlindsClosedIcon,
    BlindsOpenIcon,
    CameraIcon,
    CellarIcon,
    ChevronDownIcon,
    ChevronDownLargeIcon,
    ChevronLeftIcon,
    ChevronLeftLargeIcon,
    ChevronRightIcon,
    ChevronRightLargeIcon,
    ChevronUpIcon,
    ChevronUpLargeIcon,
    CinemaIcon,
    ClimateIcon,
    ClockIcon,
    CoolIcon,
    CropIcon,
    CrossCircleIcon,
    CrossIcon,
    CupboardIcon,
    CurtainsClosedIcon,
    CurtainsOpenIcon,
    DamperIcon,
    DehumidifyIcon,
    DeviceOffCircleIcon,
    DeviceOffIcon,
    DiningIcon,
    DirectionDownCircleIcon,
    DirectionDownIcon,
    DirectionLeftCircleIcon,
    DirectionLeftIcon,
    DirectionRightCircleIcon,
    DirectionRightIcon,
    DirectionUpCircleIcon,
    DirectionUpIcon,
    DownloadIcon,
    EntranceIcon,
    FanIcon,
    FireIcon,
    GarageDoorClosedIcon,
    GarageDoorOpenIcon,
    GateClosedIcon,
    GateOpenIcon,
    HeatIcon,
    HiWallIcon,
    HomeFilledIcon,
    HomeIcon,
    HomeOutlineIcon,
    KitchenIcon,
    LightingFeatureIcon,
    LightingIcon,
    LightingPathIcon,
    LightingWallIcon,
    LivingIcon,
    LockClosedFilledIcon,
    LockClosedIcon,
    LockClosedOutlineIcon,
    LockOpenFilledIcon,
    MinusCircleIcon,
    MinusIcon,
    MirrorDemisterIcon,
    MirrorLightingIcon,
    PaletteIcon,
    PatioIcon,
    PhoneIcon,
    PhotoIcon,
    PlayCircleIcon,
    PlayIcon,
    PlusCircleIcon,
    PlusIcon,
    PoolIcon,
    RefreshCircleIcon,
    RefreshIcon,
    ScheduleIcon,
    SculleryIcon,
    SensorOffIcon,
    SensorOnIcon,
    ServeryIcon,
    SettingsIcon,
    SetupIcon,
    SleepIcon,
    SpaIcon,
    SprinklerIcon,
    StarCircleFilledIcon,
    StarCircleOutlineIcon,
    StarFilledIcon,
    StarOutlineIcon,
    StopCircleIcon,
    StopIcon,
    Swing0Icon,
    Swing30Icon,
    Swing60Icon,
    Swing90Icon,
    SwingAutoIcon,
    SwingOffIcon,
    ThermometerIcon,
    TickCircleIcon,
    TickIcon,
    TouchIcon,
    TowelRailIcon,
    TrashIcon,
    UnderFloorHeatingIcon,
    UnderTileHeatingIcon,
    UserIcon,
    UsersIcon,
    WaterIcon,
    WindowSeatIcon,
    WindowsClosedIcon,
    WindowsOpenIcon,
    DevicesIcon,
    LightMoodsIcon,
    UnlockedIcon,
    Unlocked2Icon
} from '../components/icons'

export default function IconView() {

    return (
        <div className="flex w-full h-full items-start">
            <div
                className={`'w-full h-full pt-2 px-1 relative flex flex-wrap overflow-y-auto`}
            >
                <div className="block p-2 rounded text-center w-1/6">
                    <AdjustIcon />
                    <label>Adjust</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">    
                    <AdminUserIcon />
                    <label>Admin User</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <AudioIcon />
                    <label>Audio</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <AwayIcon />
                    <label>Away</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <BarIcon />
                    <label>Bar</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <BathIcon />
                    <label>Bath</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <BathroomHeatIcon />
                    <label>Bathroom Heat</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <BathroomIcon />
                    <label>Bathroom</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <BedDoubleIcon />
                    <label>Bed Double</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <BedIcon />
                    <label>Bed</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <BlindsClosedIcon />
                    <label>Blinds Closed</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <BlindsOpenIcon />
                    <label>Blinds Open</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <CameraIcon />
                    <label>Camera</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <CellarIcon />
                    <label>Cellar</label>
                </div>
                <div className="p-2 rounded text-center w-1/6 flex flex-col justify-between">
                    <div className="block mx-auto pt-8"><ChevronDownIcon /></div>   
                    <div className="pb-6">
                        <label>Chevron Down</label>
                    </div>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <ChevronDownLargeIcon />
                    <label>Chevron Down Large</label>
                </div>
                <div className="p-2 rounded text-center w-1/6 flex flex-col justify-between">   
                    <div className="block mx-auto pt-8"><ChevronLeftIcon /></div>
                    <div className="pb-6">
                        <label>Chevron Left</label>
                    </div>
                </div>
                <div className="block p-2 rounded text-center w-1/6">       
                    <ChevronLeftLargeIcon />
                    <label>Chevron Left Large</label>
                </div>
                <div className="p-2 rounded text-center w-1/6 flex flex-col justify-between">   
                    <div className="block mx-auto pt-8"><ChevronRightIcon /></div>
                    <div className="pb-6">
                        <label>Chevron Right</label>
                    </div>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <ChevronRightLargeIcon />
                    <label>Chevron Right Large</label>
                </div>
                <div className="p-2 rounded text-center w-1/6 flex flex-col justify-between">
                    <div className="block mx-auto pt-8"><ChevronUpIcon /></div>
                    <div className="pb-6">
                        <label>Chevron Up</label>
                    </div>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <ChevronUpLargeIcon />
                    <label>Chevron Up Large</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <CinemaIcon />
                    <label>Cinema</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <ClimateIcon />
                    <label>Climate</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <ClockIcon />
                    <label>Clock</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <CoolIcon />
                    <label>Cool</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <CropIcon />
                    <label>Crop</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <CrossCircleIcon />
                    <label>CrossCircle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <CrossIcon />
                    <label>Cross</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <CupboardIcon />
                    <label>Cupboard</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <CurtainsClosedIcon />
                    <label>Curtains Closed</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <CurtainsOpenIcon />
                    <label>Curtains Open</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DamperIcon />
                    <label>Damper</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DehumidifyIcon />
                    <label>Dehumidify</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DeviceOffCircleIcon />
                    <label>Device Off Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DeviceOffIcon />
                    <label>Device Off</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DiningIcon />
                    <label>Dining</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DirectionDownCircleIcon />
                    <label>Direction Down Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DirectionDownIcon />
                    <label>Direction Down</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DirectionLeftCircleIcon />
                    <label>Direction Left Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DirectionLeftIcon />
                    <label>Direction Left</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DirectionRightCircleIcon />
                    <label>Direction Right Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DirectionRightIcon />
                    <label>Direction Right</label> 
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DirectionUpCircleIcon />
                    <label>Direction Up Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DirectionUpIcon />
                    <label>Direction Up</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <DownloadIcon />
                    <label>Download</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <EntranceIcon />
                    <label>Entrance</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <FanIcon />
                    <label>Fan</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <FireIcon />
                    <label>Fire</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <GarageDoorClosedIcon />
                    <label>Garage Door Closed</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <GarageDoorOpenIcon />
                    <label>Garage Door Open</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <GateClosedIcon />
                    <label>Gate Closed</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <GateOpenIcon />
                    <label>Gate Open</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <HeatIcon />
                    <label>Heat</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <HiWallIcon />
                    <label>Hi Wall</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <HomeFilledIcon />
                    <label>Home Filled</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <HomeIcon />
                    <label>Home</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <HomeOutlineIcon />
                    <label>Home Outline</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <KitchenIcon />
                    <label>Kitchen</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <LightingFeatureIcon />
                    <label>Lighting Feature</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <LightingIcon />
                    <label>Lighting</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <LightingPathIcon />
                    <label>Lighting Path</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <LightingWallIcon />
                    <label>Lighting Wall</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <LivingIcon />
                    <label>Living</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <LockClosedFilledIcon />
                    <label>Lock Closed Filled</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <LockClosedIcon />
                    <label>Lock Closed</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <LockClosedOutlineIcon />
                    <label>Lock Closed Outline</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <LockOpenFilledIcon />
                    <label>Lock Open Filled</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <MinusCircleIcon />
                    <label>Minus Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <MinusIcon />
                    <label>Minus</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <MirrorDemisterIcon />
                    <label>Mirror Demister</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <MirrorLightingIcon />
                    <label>Mirror Lighting</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <PaletteIcon />
                    <label>Palette</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <PatioIcon />
                    <label>Patio</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <PhoneIcon />
                    <label>Phone</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <PhotoIcon />
                    <label>Photo</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <PlayCircleIcon />
                    <label>Play Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <PlayIcon />
                    <label>Play</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <PlusCircleIcon />
                    <label>Plus Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <PlusIcon />
                    <label>Plus</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <PoolIcon />
                    <label>Pool</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <RefreshCircleIcon />
                    <label>Refresh Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <RefreshIcon />
                    <label>Refresh</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <ScheduleIcon />
                    <label>Schedule</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <SculleryIcon />
                    <label>Scullery</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <SensorOffIcon />
                    <label>Sensor Off</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <SensorOnIcon />
                    <label>Sensor On</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <ServeryIcon />
                    <label>Servery</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <SettingsIcon />
                    <label>Settings</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <SetupIcon />
                    <label>Setup</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <SleepIcon />
                    <label>Sleep</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <SpaIcon />
                    <label>Spa</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <SprinklerIcon />
                    <label>Sprinkler</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <StarCircleFilledIcon />
                    <label>Star Circle Icon Filled</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <StarCircleOutlineIcon />
                    <label>Star Circle Icon Outline</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <StarFilledIcon />
                    <label>Star Icon Filled</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <StarOutlineIcon />
                    <label>Star Icon Outline</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <StopCircleIcon />
                    <label>Stop Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <StopIcon />
                    <label>Stop</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <Swing0Icon />
                    <label>Swing0</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <Swing30Icon />
                    <label>Swing30</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <Swing60Icon />
                    <label>Swing60</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <Swing90Icon />
                    <label>Swing90</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <SwingAutoIcon />
                    <label>Swing Auto</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <SwingOffIcon />
                    <label>Swing Off</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <ThermometerIcon />
                    <label>Thermometer</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <TickCircleIcon />
                    <label>Tick Circle</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <TickIcon />
                    <label>Tick</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <TouchIcon />
                    <label>Touch</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <TowelRailIcon />
                    <label>Towel Rail</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <TrashIcon />
                    <label>Trash</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <UnderFloorHeatingIcon />
                    <label>Under Floor Heating</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <UnderTileHeatingIcon />
                    <label>Under Title Heating</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <UserIcon />
                    <label>User</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <UsersIcon />
                    <label>Users</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <WaterIcon />
                    <label>Water</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <WindowSeatIcon />
                    <label>Window Seat</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <WindowsClosedIcon />
                    <label>Windows Closed</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">   
                    <WindowsOpenIcon />
                    <label>Windows Open</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">
                    <DevicesIcon />
                    <label>Devices</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">
                    <LightMoodsIcon />
                    <label>Light Moods</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">
                    <UnlockedIcon />
                    <label>Unlocked</label>
                </div>
                <div className="block p-2 rounded text-center w-1/6">
                    <Unlocked2Icon />
                    <label>Unlocked 2</label>
                </div>
            </div>
        </div>
    )
}