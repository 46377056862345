export const TouchIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M21.56,24.17c0.32,0,0.64-0.08,0.93-0.26c0.85-0.52,1.12-1.62,0.6-2.47c-0.78-1.28-1.19-2.76-1.19-4.27
                            c0-4.54,3.69-8.23,8.23-8.23c4.54,0,8.23,3.69,8.23,8.23c0,0.91-0.15,1.8-0.44,2.65c-0.32,0.94,0.18,1.96,1.12,2.28
                            c0.94,0.32,1.96-0.18,2.28-1.12c0.42-1.23,0.63-2.51,0.63-3.81c0-6.52-5.31-11.83-11.83-11.83c-6.52,0-11.83,5.31-11.83,11.83
                            c0,2.17,0.59,4.3,1.72,6.14C20.36,23.86,20.95,24.17,21.56,24.17z"/>
                        <path fill="currentColor" d="M53.67,30.29c-0.77,0-1.5,0.15-2.17,0.43c-0.35-2.82-2.77-5.01-5.68-5.01c-0.94,0-1.82,0.23-2.61,0.63
                            c-0.89-2.01-2.9-3.42-5.24-3.42c-0.75,0-1.47,0.15-2.12,0.41v-6.29c0-3.16-2.57-5.72-5.72-5.72s-5.72,2.57-5.72,5.72v23.71
                            l-1.56-1.56c-2.29-2.29-6.01-2.29-8.3,0c-2.29,2.29-2.29,6.01,0,8.3l12.77,12.77c3.46,4.22,8.56,6.64,14.03,6.64
                            c9.58,0,17.45-7.38,18.05-16.85c0.03-0.12,0.04-0.25,0.04-0.38V35.97C59.38,32.84,56.8,30.29,53.67,30.29z M55.83,49.38
                            c-0.01,0.07-0.02,0.13-0.02,0.2c-0.36,7.69-6.72,13.72-14.47,13.72c-4.4,0-8.52-1.96-11.29-5.38c-0.04-0.05-0.08-0.1-0.13-0.14
                            L17.09,44.94c-0.43-0.43-0.66-1-0.66-1.61c0-0.61,0.23-1.18,0.66-1.61c0.44-0.44,1.02-0.66,1.61-0.66c0.58,0,1.16,0.22,1.6,0.66
                            l4.64,4.64c0.51,0.51,1.29,0.67,1.96,0.39c0.67-0.28,1.11-0.93,1.11-1.66V17.04c0-1.17,0.95-2.12,2.12-2.12s2.12,0.95,2.12,2.12
                            v11.6c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8c0-1.17,0.95-2.12,2.12-2.12s2.12,0.95,2.12,2.12v2.79c0,0.99,0.81,1.8,1.8,1.8
                            s1.8-0.81,1.8-1.8c0-1.17,0.95-2.12,2.12-2.12c1.17,0,2.12,0.95,2.12,2.12v4.58c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8
                            c0-1.17,0.95-2.12,2.12-2.12c1.15,0,2.14,0.98,2.16,2.11V49.38z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}