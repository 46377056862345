import React, { useState } from 'react'

export default function NumberInput({
    label,
    placeholder = '',
    min,
    max,
    step = 1,
    value,
    onChange,
    inputType = "numeric"
}) {
    const [inputValue, setValue] = useState(value)

    const handleOnChange = (event) => {
        setValue(event.target.value)
        onChange(label, event.target.value)
    } 

    return (
        <div className="w-full">
            <label 
                htmlFor={label} 
                className="
                    block mb-2 text-sm font-medium text-gray-900 
                    dark:text-gray-300
                "
            >
                {label}
            </label>
            <input
                type={inputType}
                id={label}
                min={min}
                max={max}
                step={step}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={inputValue} 
                className="
                    block w-full p-2.5 border text-sm rounded-lg 
                    focus:ring-ata-a-june-bud
                    focus:border-ata-a-june-bud   
                    dark:bg-ata-d-dark-liver 
                    dark:border-ata-d-cultured
                    dark:placeholder-ata-d-gray-web
                    dark:text-ata-d-cultured
                    dark:focus:ring-ata-d-yellow-green 
                    dark:focus:border-ata-d-yellow-green" 
            />
        </div>
    )
}