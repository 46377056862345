export const CinemaIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M28.93,31.75c0-4.11-3.34-7.44-7.45-7.44s-7.45,3.34-7.45,7.44c0,4.11,3.34,7.45,7.45,7.45
                            S28.93,35.85,28.93,31.75z M17.63,31.75c0-2.12,1.73-3.84,3.85-3.84s3.85,1.72,3.85,3.84c0,2.12-1.73,3.85-3.85,3.85
                            S17.63,33.87,17.63,31.75z"/>
                        <path fill="currentColor" d="M50.52,24.3c-4.11,0-7.45,3.34-7.45,7.44c0,4.11,3.34,7.45,7.45,7.45s7.45-3.34,7.45-7.45
                            C57.97,27.64,54.63,24.3,50.52,24.3z M50.52,35.59c-2.12,0-3.85-1.72-3.85-3.85c0-2.12,1.72-3.84,3.85-3.84
                            c2.12,0,3.85,1.72,3.85,3.84C54.37,33.87,52.64,35.59,50.52,35.59z"/>
                        <path fill="currentColor" d="M36,28.61c4.11,0,7.44-3.34,7.44-7.45s-3.34-7.45-7.44-7.45c-4.11,0-7.45,3.34-7.45,7.45
                            S31.89,28.61,36,28.61z M36,17.32c2.12,0,3.84,1.73,3.84,3.85s-1.72,3.85-3.84,3.85c-2.12,0-3.85-1.73-3.85-3.85
                            S33.88,17.32,36,17.32z"/>
                        <path fill="currentColor" d="M27.04,41.13c-4.11,0-7.44,3.34-7.44,7.45c0,4.11,3.34,7.44,7.44,7.44c4.11,0,7.45-3.34,7.45-7.44
                            C34.49,44.47,31.15,41.13,27.04,41.13z M27.04,52.42c-2.12,0-3.84-1.72-3.84-3.84c0-2.12,1.72-3.85,3.84-3.85
                            s3.85,1.72,3.85,3.85C30.89,50.7,29.16,52.42,27.04,52.42z"/>
                        <path fill="currentColor" d="M44.82,41.13c-4.11,0-7.45,3.34-7.45,7.45c0,4.11,3.34,7.44,7.45,7.44c4.11,0,7.44-3.34,7.44-7.44
                            C52.27,44.47,48.93,41.13,44.82,41.13z M44.82,52.42c-2.12,0-3.85-1.72-3.85-3.84c0-2.12,1.72-3.85,3.85-3.85
                            c2.12,0,3.84,1.72,3.84,3.85C48.67,50.7,46.94,52.42,44.82,52.42z"/>
                        <path fill="currentColor" d="M56.32,14.17c3.2-0.1,6.97-0.52,8.82-1.85c0.81-0.58,0.99-1.7,0.42-2.51C64.98,9,63.85,8.82,63.05,9.4
                            c-1.59,1.14-7.69,1.34-11.55,1.12C46.98,7.76,41.67,6.18,36,6.18c-16.48,0-29.89,13.41-29.89,29.89S19.52,65.96,36,65.96
                            s29.89-13.41,29.89-29.89C65.89,27.43,62.2,19.63,56.32,14.17z M36,62.36c-14.5,0-26.29-11.8-26.29-26.29S21.5,9.78,36,9.78
                            s26.29,11.8,26.29,26.29S50.5,62.36,36,62.36z"/>
                        <path fill="currentColor" d="M39.18,36.07c0-1.76-1.42-3.18-3.18-3.18s-3.18,1.42-3.18,3.18c0,1.76,1.42,3.18,3.18,3.18
                            S39.18,37.82,39.18,36.07z"/>
                    </g>
                </g>
            </g>     
        </svg>
    )
}