export const FireIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M65.17,58.76c-0.41-1.65-1.44-3.05-2.9-3.93c0,0,0,0,0,0c0,0,0,0,0,0l-5.82-3.48
                        c4.4-4.32,5.81-9.09,4.54-15.22c-0.57-2.75-1.79-5.2-2.97-7.57c-0.88-1.77-1.72-3.44-2.25-5.15c-0.37-1.2-1.48-2.01-2.73-1.98
                        c-1.26,0.02-2.34,0.85-2.67,2.07c-0.07,0.26-0.14,0.52-0.19,0.78c-4.4-6.14-6.55-11.54-6.39-16.1c0.03-1-0.47-1.95-1.32-2.47
                        c-0.86-0.53-1.94-0.56-2.83-0.08c-2.93,1.58-7.83,5.26-11.15,13.13c-0.02-1.1-0.68-2.1-1.72-2.54c-1.12-0.47-2.41-0.18-3.21,0.74
                        c-1.81,2.07-6.2,7.64-7.73,14.57c-1.49,6.75,0.14,13.02,4.71,18.28l-8.39,5.02c0,0,0,0,0,0c0,0,0,0,0,0
                        c-1.46,0.88-2.49,2.27-2.9,3.93c-0.41,1.65-0.15,3.37,0.73,4.83c0.88,1.46,2.28,2.49,3.93,2.9c0.51,0.13,1.03,0.19,1.54,0.19
                        c1.13,0,2.24-0.31,3.24-0.9c0.01-0.01,0.03-0.01,0.04-0.02l0,0c0,0,0,0,0,0c0,0,0,0,0,0l12.1-7.24c0.54,0.32,3.58,2.01,7.54,2.01
                        c1.88,0,3.97-0.38,6.1-1.48l11.21,6.7c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0.01,0.01,0.03,0.01,0.04,0.02c1,0.59,2.11,0.9,3.24,0.9
                        c0.51,0,1.03-0.06,1.54-0.19c1.65-0.41,3.05-1.44,3.93-2.9C65.32,62.13,65.58,60.42,65.17,58.76z M53.52,57
                        c-0.51,0.85-0.8,1.78-0.89,2.73L40.72,52.6l5.41-3.24l9.36,5.6C54.71,55.48,54.03,56.15,53.52,57z M21.79,59.73
                        c-0.08-0.96-0.38-1.89-0.89-2.73c-0.5-0.83-1.17-1.52-1.96-2.03L42.2,41.05c0.64-0.38,1.38-0.5,2.1-0.32
                        c0.72,0.18,1.33,0.63,1.71,1.26c0.72,1.2,0.42,2.73-0.63,3.58c-0.22,0.09-0.42,0.23-0.6,0.4L21.79,59.73z M39.82,34.79
                        c0.08,0.53,0.2,1.02,0.37,1.48c0.18,0.49,0.41,0.96,0.66,1.42c-0.17,0.08-0.35,0.18-0.52,0.28l-10.15,6.08
                        c0.48-0.97,1.12-1.84,1.93-2.58l0.8-0.74c2.05-1.46,3.95-3.06,5.63-4.75C38.94,35.57,39.39,35.17,39.82,34.79z M24.92,20.94
                        c0.24,2.02,1.04,3.73,2.33,4.88c0.47,0.42,1.12,0.56,1.72,0.38c0.6-0.18,1.06-0.66,1.22-1.26c2.43-9.22,7.2-13.59,10.01-15.45
                        c0.29,5.98,3.6,12.88,9.86,20.55c0.47,0.58,1.24,0.8,1.95,0.57c0.71-0.23,1.2-0.87,1.24-1.61c0.04-0.65,0.08-1.24,0.13-1.79
                        c0.45,1.01,0.94,1.99,1.42,2.96c1.13,2.26,2.19,4.39,2.67,6.69c1.08,5.21-0.15,8.93-4.22,12.58l-4.26-2.55
                        c1.27-1.98,1.4-4.6,0.11-6.75c-0.88-1.46-2.27-2.49-3.93-2.9c-0.11-0.03-0.22-0.04-0.33-0.06c-0.04-0.06-0.08-0.13-0.13-0.19
                        c-0.49-0.6-0.87-1.26-1.14-1.97c-0.19-0.5-0.27-1.16-0.23-1.91l0.07-1.46c0.04-0.75-0.34-1.46-0.99-1.85
                        c-0.65-0.39-1.45-0.39-2.09-0.01c-0.05,0.03-0.1,0.06-0.15,0.1c-0.1,0.07-2.46,1.82-4.18,3.55c-1.56,1.57-3.33,3.06-5.25,4.42
                        c-0.06,0.04-0.12,0.09-0.18,0.14l-0.89,0.81c-1.27,1.16-2.26,2.54-2.96,4.11c-0.54,1.21-0.87,2.48-1,3.79l-2.04,1.22
                        C20.26,44.1,14.25,34.38,24.92,20.94z M14.77,62.99c-0.72-0.18-1.33-0.63-1.71-1.26c-0.38-0.63-0.5-1.38-0.32-2.1
                        c0.18-0.72,0.63-1.33,1.26-1.71c0.44-0.26,0.93-0.4,1.43-0.4c0.22,0,0.45,0.03,0.67,0.08c0.72,0.18,1.33,0.63,1.71,1.26
                        c0,0,0,0,0,0c0.38,0.63,0.5,1.38,0.32,2.1c-0.18,0.72-0.63,1.33-1.26,1.71C16.24,63.06,15.49,63.17,14.77,62.99z M34.59,56.27
                        l2.63-1.57l3.31,1.98C38.18,57.2,36.04,56.77,34.59,56.27z M61.36,61.73c-0.38,0.63-0.99,1.08-1.71,1.26
                        c-0.72,0.18-1.47,0.07-2.1-0.32c-0.63-0.38-1.08-0.99-1.26-1.71c-0.18-0.72-0.07-1.47,0.32-2.1c0.52-0.86,1.44-1.34,2.38-1.34
                        c0.49,0,0.98,0.13,1.43,0.4c0.64,0.38,1.08,0.99,1.26,1.71C61.86,60.35,61.74,61.09,61.36,61.73z"/>
                </g>
            </g>
        </svg>
    )
}