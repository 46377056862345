export const SprinklerIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M64.15,37.86h-6.09H13.94H7.85c-0.99,0-1.8,0.81-1.8,1.8s0.81,1.8,1.8,1.8h4.29v6.05
                            c0,2.98,2.42,5.4,5.4,5.4h3.21v2.64c0,2.98,2.42,5.4,5.4,5.4h2.34v2.25c0,1.65,1.35,3,3,3h9.02c1.65,0,3-1.35,3-3v-2.25h2.34
                            c2.98,0,5.4-2.42,5.4-5.4v-2.64h3.21c2.98,0,5.4-2.42,5.4-5.4v-6.05h4.29c0.99,0,1.8-0.81,1.8-1.8S65.14,37.86,64.15,37.86z
                            M47.65,55.55c0,0.99-0.81,1.8-1.8,1.8h-4.14c-0.99,0-1.8,0.81-1.8,1.8v3.45h-7.82v-3.45c0-0.99-0.81-1.8-1.8-1.8h-4.14
                            c-0.99,0-1.8-0.81-1.8-1.8v-2.64h23.3V55.55z M56.26,47.51c0,0.99-0.81,1.8-1.8,1.8h-5.01h-26.9h-5.01c-0.99,0-1.8-0.81-1.8-1.8
                            v-6.05h40.52V47.51z"/>
                        <path fill="currentColor" d="M51.88,31c1.32,0.76,3.01,0.31,3.77-1.01c0.76-1.32,0.31-3.01-1.01-3.77c-1.32-0.76-3.01-0.31-3.77,1.01
                            C50.1,28.55,50.55,30.24,51.88,31z"/>
                        <path fill="currentColor" d="M56.82,22.44c1.32,0.76,3.01,0.31,3.77-1.01s0.31-3.01-1.01-3.77c-1.32-0.76-3.01-0.31-3.77,1.01
                            C55.04,19.99,55.49,21.68,56.82,22.44z"/>
                        <path fill="currentColor" d="M61.76,13.89c1.32,0.76,3.01,0.31,3.77-1.01c0.76-1.32,0.31-3.01-1.01-3.77c-1.32-0.76-3.01-0.31-3.77,1.01
                            C59.98,11.43,60.43,13.12,61.76,13.89z"/>
                        <path fill="currentColor" d="M40.95,32.26c1.47,0.39,2.99-0.48,3.38-1.95c0.39-1.47-0.48-2.99-1.95-3.38c-1.47-0.39-2.99,0.48-3.38,1.95
                            C38.6,30.35,39.47,31.87,40.95,32.26z"/>
                        <path fill="currentColor" d="M43.5,22.72c1.47,0.39,2.99-0.48,3.38-1.95c0.39-1.47-0.48-2.99-1.95-3.38c-1.47-0.39-2.99,0.48-3.38,1.95
                            C41.16,20.81,42.03,22.32,43.5,22.72z"/>
                        <path fill="currentColor" d="M46.06,13.18c1.47,0.39,2.99-0.48,3.38-1.95c0.39-1.47-0.48-2.99-1.95-3.38c-1.47-0.39-2.99,0.48-3.38,1.95
                            C43.71,11.27,44.59,12.78,46.06,13.18z"/>
                        <path fill="currentColor" d="M16.35,29.99c0.76,1.32,2.45,1.77,3.77,1.01c1.32-0.76,1.77-2.45,1.01-3.77c-0.76-1.32-2.45-1.77-3.77-1.01
                            C16.04,26.98,15.59,28.67,16.35,29.99z"/>
                        <path fill="currentColor" d="M11.41,21.43c0.76,1.32,2.45,1.77,3.77,1.01c1.32-0.76,1.77-2.45,1.01-3.77c-0.76-1.32-2.45-1.77-3.77-1.01
                            C11.1,18.42,10.65,20.11,11.41,21.43z"/>
                        <path fill="currentColor" d="M10.24,13.89c1.32-0.76,1.77-2.45,1.01-3.77C10.49,8.79,8.8,8.34,7.48,9.1c-1.32,0.76-1.77,2.45-1.01,3.77
                            C7.23,14.2,8.92,14.65,10.24,13.89z"/>
                        <path fill="currentColor" d="M29.62,26.92c-1.47,0.39-2.35,1.91-1.95,3.38c0.39,1.47,1.91,2.35,3.38,1.95c1.47-0.39,2.35-1.91,1.95-3.38
                            C32.61,27.4,31.1,26.53,29.62,26.92z"/>
                        <path fill="currentColor" d="M27.07,17.38c-1.47,0.39-2.35,1.91-1.95,3.38c0.39,1.47,1.91,2.35,3.38,1.95s2.35-1.91,1.95-3.38
                            C30.06,17.86,28.54,16.99,27.07,17.38z"/>
                        <path fill="currentColor" d="M25.94,13.18c1.47-0.39,2.35-1.91,1.95-3.38c-0.39-1.47-1.91-2.35-3.38-1.95c-1.47,0.39-2.35,1.91-1.95,3.38
                            C22.95,12.7,24.47,13.57,25.94,13.18z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}