/**
 * Splits the hmi responses into their own areas of concern popups/modals, screens, sidebar, form
 */

import { getCommandService } from '../services/command.service'

const commandService = getCommandService()

export const getScreen = (data) => (dispatch) => {
    if (data) {
      dispatch({
        type: 'FETCH_SCREEN_SUCCESS',
        payload: { data },
      })
    } else {
      dispatch({
        type: 'FETCH_SCREEN_FAIL',
        message: 'Error: no screen data'
      })
    }
}

export const updateScreen = (data) => (dispatch) => {
  if (data) {
    dispatch({
      type: 'UPDATE_SCREEN_SUCCESS',
      payload: { data },
    })
  } else {
    dispatch({
      type: 'UPDATE_SCREEN_FAIL',
      message: 'Error: no screen data'
    })
  }
}

export const getModal = (modalData) => (dispatch) => {
  if (modalData) {
    dispatch({
      type: 'FETCH_MODAL_SUCCESS',
      payload: { modalData },
    })
  } else {
    dispatch({
      type: 'FETCH_MODAL_FAIL',
      message: 'Error: no modal data'
    })
  }
}

export const clearModal = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_MODAL',
    payload: null
  })
}

export const sendCommand = (command, data = null) => (dispatch) => {
  if (command) {
    commandService.queueCommand(command, data)

    dispatch({
      type: 'SEND_COMMAND_SUCCESS',
      payload: { command },
    })
  } else {
    dispatch({
      type: 'SEND_COMMAND_FAIL',
      message: 'invalid command'
    })
  }
}

export const getSidebar = (sidebarData) => (dispatch) => {
    if (!sidebarData) {
      dispatch({
        type: 'FETCH_SIDEBAR_FAIL',
        payload: [],
        message: 'no sidebar data'
      })
    } else {
      dispatch({
        type: 'FETCH_SIDEBAR_SUCCESS',
        payload: [ ...sidebarData ],
      })
    }
}


export const login = (data) => (dispatch) => {
  if (data) {
    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: { data },
    })
  } else {
    dispatch({
      type: 'LOGIN_FAIL',
      message: 'Error: Login failed',
      payload: {}
    })
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('auth')
  
  dispatch({
      type: 'LOGOUT_SUCCESS',
      payload: {},
  })
  
}

export const setFormState = (data) => (dispatch) => {
  if (!data) {
    dispatch({
      type: 'SET_FORM_FAIL',
      message: 'no data found'
    })
  } else {
    dispatch({
      type: 'SET_FORM_SUCCESS',
      payload: data,
    })
  }
}

export default getScreen