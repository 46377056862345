import React, { useState } from 'react'
import pwaInstaller from '../../services/installation.service'
import { login, getUserProfile, getInsecureRememberMe, deleteInsecureRememberMe } from '../../services/auth.service' 
import { LoginLoader } from '../Loaders'
import { getInitalAppSettings } from '../../services/settings.service'
import { useEffect } from 'react'

const LoginForm = ({ onLogin }) => {
    const [isLoading, setIsLoading] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [rememberMe, setRememberMe] = useState(true)
    const [showRememberMe, setShowRememberMe] = useState(false)
    const [showPwaInstallButton, setShowPwaInstallButton] = useState(false)
    const [showIOSinstallInfo, setShowShowIOSinstallInfo] = useState(false)


    const [error, setError] = useState('')

    const installApp = (e) => {
        e.preventDefault()
        pwaInstaller.install()
    }

    const submitLogin =  (e) => {
        e.preventDefault()
        setIsLoading(true)
        login(email, password, processLogin, processErrors)
    }

    const processLogin = () => {
        getUserProfile(processUser)
    }

    const handlerememberMe = (e) => {
        setRememberMe(e.currentTarget.checked)

        if (!e.currentTarget.checked) {
            deleteInsecureRememberMe()
        }
    }

    const handleSetShowPassword = (e) => {
        e.preventDefault()
        setShowPassword(!showPassword)
    }

    const processErrors = (error) => {
        setIsLoading(false)        
        
        try {
            const message = JSON.parse(error.original.message).error_description
            setError(message)
        } catch(e) {
            console.error(e)
            setError('There was an Error Logging in')
        }
    }

    const processUser = (user) => {
        setIsLoading(false)
        onLogin(user)
    }

    useEffect(() => {
        const settings = getInitalAppSettings()
        setShowRememberMe(settings?.allow_insecure_remember_me)

        if (settings?.allow_insecure_remember_me) {
            let rm = getInsecureRememberMe() 
            
            if (rm) {
                rm = JSON.parse(rm)
                setEmail(rm.u)
                setPassword(rm.p)
            }   
        }
        
        if ( pwaInstaller.canInstall_iOS() ) {
            setShowShowIOSinstallInfo(true)
        } else {
            pwaInstaller.addListener((canInstall) => {
                setShowPwaInstallButton(canInstall)
            })
        }
    }, [])

    if (isLoading) {
        return (<LoginLoader />)
    }

    return (
        <form autoComplete="off" className="w-full p-6">
            <div>
                <img src="/images/ata-touch-logo.png" className="mx-auto w-3/5 max-w-[200px] mb-6" alt="Ata-Touch Mobile" />
                <h2 className="text-center my-4 font-bold">Login</h2>
            </div>

            {
                error ? <div className="text-ata-d-burnt-sienna text-center py-2">{error}</div> : ''
            }

            <div className="mb-6">
                <label 
                    htmlFor="email" 
                    className="block mb-2 text-xs font-medium">Email</label>
                <input 
                    autoComplete="username"
                    type="email" 
                    id="email"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                    className="
           `             block w-full p-2.5 border text-sm rounded-lg 
                        focus:ring-ata-a-june-bud
                        focus:border-ata-a-june-bud   
                        dark:bg-ata-d-dark-liver 
                        dark:border-ata-d-cultured
                        dark:placeholder-ata-d-gray-web
                        dark:text-ata-d-cultured
                        dark:focus:ring-ata-d-yellow-green 
                        dark:focus:border-ata-d-yellow-green" 
                    placeholder="name@email.com" 
                    required />
            </div>
            <div className="mb-6 pb-6 relative">
                <label 
                    htmlFor="password" 
                    className="block mb-2 text-xs font-medium">Password</label>
                <input 
                    type={showPassword ? 'text': 'password'}
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                    className="
           `             block w-full p-2.5 border text-sm rounded-lg 
                        focus:ring-ata-a-june-bud
                        focus:border-ata-a-june-bud   
                        dark:bg-ata-d-dark-liver 
                        dark:border-ata-d-cultured
                        dark:placeholder-ata-d-gray-web
                        dark:text-ata-d-cultured
                        dark:focus:ring-ata-d-yellow-green 
                        dark:focus:border-ata-d-yellow-green"  
                    required />
                <button 
                    className="absolute top-8 right-2"
                    onClick={(e) => { handleSetShowPassword(e) }}>
                    {
                        showPassword ? 
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg> : 
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                            </svg>
                    }
                </button>
            </div>
            <button 
                type="submit"
                onClick={(e) => { submitLogin(e) }}
                className="block border font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center
                    focus:ring-ata-a-june-bud
                    focus:border-ata-a-june-bud   
                    dark:bg-ata-d-dark-liver 
                    dark:border-ata-d-cultured
                    dark:placeholder-ata-d-gray-web
                    dark:text-ata-d-cultured
                    dark:focus:ring-ata-d-yellow-green 
                    dark:focus:border-ata-d-yellow-green"   
                >Submit</button>

            { showRememberMe ?  
                <div className="flex w-full items-center justify-center pr-2 mt-8">
                    <input 
                        checked={rememberMe}
                        onChange={(e) => { handlerememberMe(e) }} 
                        id="remember-me" 
                        type="checkbox" 
                        value="rememberMe" 
                        className="
                            w-4 h-4 accent-ata-a-outer-space-crayola dark:accent-ata-d-cultured  
                            rounded border-ata-a-outer-space-crayola dark:border-ata-d-cultured
                        " 
                    />
                    <label htmlFor="remember-me" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember Me</label>
                </div> : ''
            }

            {
                showPwaInstallButton ? 
                    <button 
                        type="submit"
                        onClick={(e) => { installApp(e) }}
                        className="block border font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mt-6
                            focus:ring-ata-a-june-bud
                            focus:border-ata-a-june-bud   
                            dark:bg-ata-d-dark-liver 
                            dark:border-ata-d-cultured
                            dark:placeholder-ata-d-gray-web
                            dark:text-ata-d-cultured
                            dark:focus:ring-ata-d-yellow-green 
                            dark:focus:border-ata-d-yellow-green"   
                    >Install</button> : ''
            }

            {
                showIOSinstallInfo ? 
                    <div className="my-8">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 block mx-auto">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15" />
                        </svg>
                        <small className="text-center block my-2">iOS: To install press the share button in safari and choose add to homescreen from the menu</small>
                    </div> : ''
            }
        </form>
    )
}

export default LoginForm