export const CellarIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M65.34,27.22c0-6.52-1.68-12.95-4.86-18.59c-0.07-0.12-0.14-0.22-0.23-0.32c-1.1-1.4-4.1-3.05-12.17-3.07
                        c-3.08,0.01-6.01,0.3-8.23,0.87c-0.88,0.22-3.13,0.81-4.11,2.25c-0.07,0.08-0.14,0.17-0.19,0.27c-0.87,1.54-1.63,3.16-2.28,4.8
                        c-1.85-0.47-4.39-0.78-7.87-0.79c-3.09-0.02-6.01,0.3-8.23,0.87c-0.88,0.22-3.13,0.81-4.11,2.25c-0.07,0.08-0.14,0.17-0.19,0.27
                        C9.67,21.67,7.99,28.1,7.99,34.62c0,6,1.36,11.73,4.04,17.05c0.7,1.39,1.88,2.51,3.33,3.16l0.08,0.03
                        c3.13,1.41,6.49,2.12,9.87,2.12c1.11,0,2.22-0.08,3.32-0.23c0.56,1.64,1.22,3.25,2.02,4.83c0.7,1.39,1.88,2.51,3.33,3.16
                        l0.08,0.03c3.14,1.41,6.52,2.12,9.89,2.12c3.4,0,6.8-0.72,9.97-2.15c1.44-0.65,2.61-1.77,3.31-3.15
                        c2.68-5.32,4.05-11.06,4.05-17.06c0-0.07,0-0.15,0-0.22c0.01-0.01,0.01-0.02,0.02-0.03C63.98,38.96,65.34,33.22,65.34,27.22z
                        M48.06,8.83c3.96,0.01,6.77,0.55,8.25,1.07c-0.81,0.28-2.08,0.6-3.96,0.81c0,0,0,0,0,0c-1.35,0.15-2.78,0.23-4.29,0.23
                        c-3.97-0.01-6.79-0.56-8.27-1.08C41.26,9.35,44.08,8.82,48.06,8.83z M54.53,30.16c1.86,4.05,2.93,8.45,3.12,12.93
                        c-9.09,2.81-18.3,2.81-27.4,0c0.19-4.52,1.27-8.95,3.16-13.03c1.77,0.96,4.94,1.78,10.57,1.79c0.04,0,0.09,0,0.13,0
                        c1.59,0,3.13-0.08,4.58-0.25c0,0,0,0,0,0C51.23,31.32,53.18,30.83,54.53,30.16z M12.41,42.08c-0.37-1.68-0.62-3.39-0.73-5.12
                        c4.53,1.3,9.09,1.94,13.65,1.94c0.57,0,1.15-0.01,1.72-0.03c-0.26,1.73-0.4,3.49-0.42,5.25C21.87,44.27,17.12,43.59,12.41,42.08z
                        M30.3,46.87c4.53,1.3,9.09,1.94,13.65,1.94c4.56,0,9.11-0.65,13.65-1.94c-0.11,1.74-0.36,3.45-0.73,5.13
                        c-8.57,2.75-17.26,2.75-25.83,0C30.66,50.32,30.41,48.61,30.3,46.87z M48.29,28.03c-1.35,0.15-2.79,0.22-4.3,0.22
                        c-3.97-0.01-6.79-0.56-8.27-1.07c1.48-0.51,4.29-1.06,8.27-1.03c3.96,0.01,6.77,0.55,8.25,1.07
                        C51.44,27.5,50.17,27.82,48.29,28.03z M35.78,23.42c-0.87,0.22-3.12,0.81-4.11,2.25c-0.07,0.08-0.14,0.18-0.19,0.28
                        c-1.64,2.91-2.88,6.04-3.7,9.27c-5.39,0.3-10.79-0.38-16.15-2.04c0.19-4.52,1.27-8.95,3.16-13.02c1.77,0.96,4.94,1.78,10.57,1.79
                        c0.04,0,0.09,0,0.13,0c1.59,0,3.13-0.08,4.58-0.25c2.54-0.29,4.48-0.77,5.83-1.44c0.44,0.94,0.83,1.91,1.18,2.89
                        C36.62,23.22,36.18,23.32,35.78,23.42z M25.38,16.24c3.96,0.01,6.77,0.55,8.25,1.07c-0.81,0.28-2.08,0.6-3.96,0.81c0,0,0,0,0,0
                        c-1.35,0.15-2.79,0.22-4.29,0.22c-3.97-0.01-6.79-0.56-8.27-1.07C18.58,16.76,21.41,16.24,25.38,16.24z M16.92,51.59l-0.08-0.03
                        c-0.7-0.31-1.26-0.85-1.59-1.5c-0.64-1.27-1.19-2.56-1.66-3.88c3.9,1.05,7.82,1.58,11.74,1.58c0.48,0,0.96-0.01,1.43-0.03
                        c0.15,1.87,0.43,3.72,0.86,5.53C23.96,53.67,20.29,53.1,16.92,51.59z M52.43,61.47c-5.37,2.43-11.53,2.44-16.9,0.03l-0.08-0.03
                        c-0.7-0.31-1.26-0.85-1.59-1.5c-0.64-1.27-1.19-2.56-1.66-3.88c3.9,1.05,7.82,1.58,11.74,1.58c3.92,0,7.84-0.53,11.74-1.58
                        c-0.47,1.32-1.02,2.62-1.66,3.88C53.69,60.63,53.13,61.16,52.43,61.47z M56.41,25.94c-0.07-0.12-0.14-0.22-0.23-0.32
                        c-1.1-1.4-4.1-3.05-12.17-3.07c-1.13,0-2.23,0.04-3.28,0.12c-0.76-2.3-1.74-4.53-2.93-6.64c-0.07-0.12-0.14-0.22-0.23-0.32
                        c-0.23-0.3-0.55-0.6-0.98-0.9c0.01-0.02,0.02-0.04,0.03-0.06c0.26-0.68,0.55-1.34,0.86-2c1.77,0.96,4.94,1.78,10.58,1.79
                        c0.04,0,0.08,0,0.12,0c1.6,0,3.14-0.08,4.59-0.25c2.54-0.29,4.48-0.77,5.83-1.44c2.06,4.48,3.15,9.4,3.15,14.37
                        c0,3.22-0.44,6.36-1.3,9.38C59.64,32.87,58.29,29.27,56.41,25.94z"/>
                </g>
            </g>
        </svg>
    )
}