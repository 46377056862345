export const KitchenIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M60.6,38.26H11.4c-2.98,0-5.4,2.42-5.4,5.4v17.65c0,2.98,2.42,5.4,5.4,5.4H60.6c2.98,0,5.4-2.42,5.4-5.4
                            V43.66C66,40.68,63.57,38.26,60.6,38.26z M20.08,63.11H11.4c-0.99,0-1.8-0.81-1.8-1.8V43.66c0-0.99,0.81-1.8,1.8-1.8h8.68V63.11z
                            M34.2,63.11H23.68V41.86H34.2V63.11z M62.4,61.31c0,0.99-0.81,1.8-1.8,1.8H37.8V48.58h24.6V61.31z M62.4,44.98H37.8v-3.12h22.8
                            c0.99,0,1.8,0.81,1.8,1.8V44.98z"/>
                        <path fill="currentColor" d="M24.98,24.22h22.03c1.65,0,3-1.35,3-3v-1.31c0-0.83-0.35-1.63-0.97-2.21l-4.11-3.74V8.28c0-1.65-1.35-3-3-3
                            H30.07c-1.65,0-3,1.35-3,3v5.68l-4.13,3.76c-0.61,0.56-0.95,1.36-0.95,2.19v1.31C21.98,22.87,23.33,24.22,24.98,24.22z
                            M25.58,20.18l4.5-4.09c0.38-0.34,0.59-0.82,0.59-1.33V8.88h10.66v5.88c0,0.51,0.21,0.99,0.59,1.33l4.5,4.09v0.44H25.58V20.18z"
                            />
                        <path fill="currentColor" d="M16.76,53.88c0.99,0,1.8-0.81,1.8-1.8v-1.33c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8v1.33
                            C14.96,53.07,15.77,53.88,16.76,53.88z"/>
                        <path fill="currentColor" d="M27,53.88c0.99,0,1.8-0.81,1.8-1.8v-1.33c0-0.99-0.81-1.8-1.8-1.8c-0.99,0-1.8,0.81-1.8,1.8v1.33
                            C25.2,53.07,26.01,53.88,27,53.88z"/>
                    </g>
                </g>
            </g>     
        </svg>
    )
}