import React from 'react'
import ZoneListModal from '../forms/ZoneListModal'
import { CrossCircleIcon, TickCircleIcon } from '../icons'

export default function TabMenu({ 
    tabOptions, 
    currentTabIndex,
    onSelectTab, 
    onZoneToggle 
}) {
    const setCurrentTab = (index) => {
        onSelectTab(index)
    }

    const handleToggleZoneState = (e, command) => {
        e.preventDefault()
        e.stopPropagation()
        onZoneToggle(command)
    }

    const zoneList = (orientation = 'landscape') => {
        return (
            <>
                { 
                    tabOptions ?
                        tabOptions.map((item, idx) => {

                            let classes = `
                                relative flex items-stretch w-full zone-nav-button-height
                                border-ata-a-silver
                                dark:border-ata-d-cultured
                                ${ currentTabIndex === idx 
                                ? '' : 'border-r border-t bg-ata-a-platinum dark:bg-ata-d-deep-taupe'
                                }
                                ${currentTabIndex + 1 === idx ? 'rounded-tr-lg' : ''}
                                ${currentTabIndex - 1 === idx ? 'rounded-br-lg border-b' : ''}
                                ${idx + 1 === tabOptions.length && idx !== currentTabIndex && tabOptions.length < 5 ? 'border-b rounded-br-lg' : ''}
                                active:bg-ata-a-gainsboro 
                                dark:active:bg-ata-d-liver
                            `
                
                            if (orientation !== 'landscape') {
                                classes = `
                                    relative flex w-full
                                    border-t
                                    border-ata-a-silver
                                    dark:border-ata-d-cultured
                                    py-6
                                    ${ currentTabIndex === idx ? 'dark:bg-ata-d-deep-taupe' : 'dark:bg-ata-d-dark-liver bg-ata-d-cultured' }
                                `
                            }

                            return (
                                <li role="presentation" key={idx} onClick={() => { setCurrentTab(idx) }}>
                                    <button 
                                        className={classes}
                                        id={'tab-' + idx}
                                        data-tabs-target={'tab-content-' + idx} 
                                        type="button" 
                                        role="tab" 
                                        aria-controls="profile" 
                                        aria-selected="false"
                                    >
                                        { currentTabIndex !== idx || orientation !== 'landscape' ?     
                                            <span className={`
                                                absolute top-1 right-1 text-xs 
                                                ${
                                                    item.stateBubble 
                                                        ? 'text-ata-d-yellow-green'
                                                        : 'text-ata-d-burnt-sienna'
                                                } 
                                            `}>
                                                { item.subTitle }
                                            </span>
                                        : '' }

                                        { item?.displayStateBubble ? 
                                            <div 
                                                className={`
                                                    w-8 h-8 ml-2 text-ata-a-june-bud dark:text-ata-d-cultured
                                                    ${orientation === 'landscape' ? ' pt-4 ' : 'mr-5'}
                                                    ${ item.stateBubble ? 'text-ata-d-yellow-green' : 'text-ata-d-burnt-sienna' }
                                                `} 
                                                onClick={(e) => { handleToggleZoneState(e, item.setState) }}
                                            >    
                                                {
                                                    item.stateBubble 
                                                        ? <TickCircleIcon colorClass="text-ata-d-yellow-green" />
                                                        : <CrossCircleIcon colorClass="text-ata-d-burnt-sienna" />
                                                }
                                            </div>

                                        : ''}

                                        <div 
                                            className={ orientation === 'landscape' ? 'pr-4 flex-grow pt-5 text-center' : 'pt-1 text-center' }
                                            onClick={() => { setCurrentTab(idx) }}
                                        >
                                            { item.title }
                                        </div> 
                                </button>
                            </li>
                        )
                    }) : 'No Items' 
                } 
            </> 
        )
    }

    return (
        <div className="border-b sm:border-b-0 sm:block w-full sm:w-2/6 sm:h-full overflow-y-hidden sm:overflow-y-auto">
            { 
                tabOptions ?
                    <div className="p-2 mb-4 sm:hidden">
                        <ZoneListModal selected={tabOptions[currentTabIndex]} handleToggleZoneState={handleToggleZoneState}>
                            { zoneList('portrait') }
                        </ZoneListModal>
                    </div> : ''
            }

            <ul 
                className="-mb-px text-sm font-medium text-center hidden sm:block" 
                id="Tab" 
                data-tabs-toggle="#zoneTabs" 
                role="tablist"
            >
                { zoneList() }
            </ul>
        </div>
    )
}