export const PhoneIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M52.07,5.27H19.93c-2.98,0-5.4,2.42-5.4,5.4v50.81c0,2.98,2.42,5.4,5.4,5.4h32.15c2.98,0,5.4-2.42,5.4-5.4
                            V10.67C57.47,7.69,55.05,5.27,52.07,5.27z M18.13,14.6h35.75v38.51H18.13V14.6z M19.93,8.87h32.15c0.99,0,1.8,0.81,1.8,1.8V11
                            H18.13v-0.33C18.13,9.67,18.93,8.87,19.93,8.87z M52.07,63.27H19.93c-0.99,0-1.8-0.81-1.8-1.8v-4.76h35.75v4.76
                            C53.87,62.47,53.07,63.27,52.07,63.27z"/>
                        <path fill="currentColor" d="M36,57.68c-1.27,0-2.3,1.03-2.3,2.3s1.03,2.3,2.3,2.3s2.3-1.03,2.3-2.3S37.27,57.68,36,57.68z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}