import { isIos, isInStandaloneMode } from '../utils/detectPlatform'

class InstallationService {
	event = null
	callbacks = []

    constructor() {
        if (typeof window === 'undefined') {
			return
		}

        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault()
            this.updateEvent(event)
        })
    
        window.addEventListener('appinstalled', (event) => {
            this.updateEvent(null)
        })
    }

    install = async () => {
		if (this.event) {
			this.event.prompt()
			
            return await this.event.userChoice.then(({ outcome }) => {
				this.updateEvent(null)
				return outcome === 'accepted' || true
			})
		} else {
			throw new Error('Not allowed to prompt.')
		}
	}

    getEvent() {
		return this.event
	}

    canInstall() {
		return this.event !== null
	}

	canInstall_iOS() {
		return (isIos() && !isInStandaloneMode()) 
	}

    updateEvent(event = null) {
		if (event === this.event) {
			return
		}

		this.event = event
		this.callbacks.forEach((callback) => callback(this.canInstall()))
	}

    addListener(callback) {
		callback(this.canInstall())
		this.callbacks.push(callback)
	}

    removeListener(callback) {
		this.callbacks = this.callbacks.filter((cb) => callback !== cb)
	}
}

const pwaInstaller = new InstallationService()
export default pwaInstaller