export const ServeryIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M61.87,46.96c-0.82-12.93-11.16-23.3-24.07-24.19v-3.92h3.39c0.99,0,1.8-0.81,1.8-1.8
                        c0-0.99-0.81-1.8-1.8-1.8H30.81c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8h3.39v3.92c-12.91,0.89-23.25,11.26-24.07,24.19
                        c-2.22,0.53-3.87,2.52-3.87,4.9c0,2.78,2.26,5.03,5.03,5.03h49.42c2.78,0,5.03-2.26,5.03-5.03
                        C65.74,49.48,64.09,47.49,61.87,46.96z M36,26.3c11.7,0,21.32,9.05,22.25,20.52H13.75C14.68,35.35,24.3,26.3,36,26.3z
                        M60.71,53.29H11.29c-0.79,0-1.43-0.64-1.43-1.43c0-0.79,0.64-1.43,1.43-1.43h0.59h48.25h0.59c0.79,0,1.43,0.64,1.43,1.43
                        C62.14,52.65,61.5,53.29,60.71,53.29z"/>
                </g>
            </g>
        </svg>
    )
}