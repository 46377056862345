export const ClimateIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M34.45,46.66c-5.52,0-10-4.49-10-10s4.49-10,10-10c0.99,0,1.8-0.81,1.8-1.8c0-0.99-0.81-1.8-1.8-1.8
                            c-7.5,0-13.6,6.1-13.6,13.6s6.1,13.6,13.6,13.6c0.99,0,1.8-0.81,1.8-1.8C36.25,47.47,35.45,46.66,34.45,46.66z"/>
                        <path fill="currentColor" d="M18.43,36.66c0-0.99-0.81-1.8-1.8-1.8h-8.8c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8h8.8
                            C17.62,38.46,18.43,37.65,18.43,36.66z"/>
                        <path fill="currentColor" d="M9.62,25.18l7.62,4.4c0.28,0.16,0.59,0.24,0.9,0.24c0.62,0,1.23-0.32,1.56-0.9c0.5-0.86,0.2-1.96-0.66-2.46
                            l-7.62-4.4c-0.86-0.5-1.96-0.2-2.46,0.66C8.46,23.58,8.76,24.68,9.62,25.18z"/>
                        <path fill="currentColor" d="M23.58,21.01c0.35,0.52,0.92,0.81,1.5,0.81c0.34,0,0.69-0.1,0.99-0.3c0.83-0.55,1.06-1.67,0.51-2.49
                            l-4.86-7.34c-0.55-0.83-1.67-1.06-2.49-0.51c-0.83,0.55-1.06,1.67-0.51,2.49L23.58,21.01z"/>
                        <path fill="currentColor" d="M19.7,44.4c-0.5-0.86-1.6-1.16-2.46-0.66l-7.62,4.4c-0.86,0.5-1.16,1.6-0.66,2.46
                            c0.33,0.58,0.94,0.9,1.56,0.9c0.3,0,0.61-0.08,0.9-0.24l7.62-4.4C19.9,46.36,20.2,45.26,19.7,44.4z"/>
                        <path fill="currentColor" d="M26.07,51.79c-0.83-0.55-1.95-0.32-2.49,0.51l-4.86,7.34c-0.55,0.83-0.32,1.95,0.51,2.49
                            c0.31,0.2,0.65,0.3,0.99,0.3c0.58,0,1.16-0.28,1.5-0.81l4.86-7.34C27.13,53.46,26.9,52.34,26.07,51.79z"/>
                        <path fill="currentColor" d="M64.17,34.86h-8.64l5.78-5.78c0.7-0.7,0.7-1.84,0-2.55c-0.7-0.7-1.84-0.7-2.55,0l-8.32,8.32H38.8l5.82-10.09
                            l11.37-3.05c0.96-0.26,1.53-1.24,1.27-2.2c-0.26-0.96-1.25-1.53-2.2-1.27l-7.89,2.11l4.32-7.48c0.5-0.86,0.2-1.96-0.66-2.46
                            c-0.86-0.5-1.96-0.2-2.46,0.66l-4.32,7.48l-2.11-7.89c-0.26-0.96-1.25-1.53-2.2-1.27c-0.96,0.26-1.53,1.24-1.27,2.2l3.05,11.37
                            l-7.38,12.79c-0.01,0.02-0.01,0.04-0.02,0.05c-0.02,0.03-0.04,0.07-0.05,0.1c-0.02,0.04-0.03,0.08-0.05,0.11
                            c-0.01,0.04-0.03,0.08-0.04,0.12c-0.01,0.03-0.02,0.07-0.03,0.1c-0.01,0.04-0.02,0.08-0.02,0.12c-0.01,0.04-0.01,0.08-0.02,0.12
                            c0,0.04,0,0.08-0.01,0.11c0,0.02-0.01,0.04-0.01,0.06s0.01,0.04,0.01,0.06c0,0.04,0,0.08,0.01,0.11c0,0.04,0.01,0.08,0.02,0.12
                            c0.01,0.04,0.01,0.08,0.02,0.12c0.01,0.03,0.02,0.07,0.03,0.1c0.01,0.04,0.03,0.08,0.04,0.12c0.01,0.04,0.03,0.08,0.05,0.11
                            c0.02,0.03,0.03,0.07,0.05,0.1c0.01,0.02,0.01,0.04,0.02,0.05l7.38,12.79l-3.05,11.37c-0.26,0.96,0.31,1.95,1.27,2.2
                            c0.16,0.04,0.31,0.06,0.47,0.06c0.79,0,1.52-0.53,1.74-1.33l2.11-7.89l4.32,7.48c0.33,0.58,0.94,0.9,1.56,0.9
                            c0.31,0,0.61-0.08,0.9-0.24c0.86-0.5,1.16-1.6,0.66-2.46l-4.32-7.48l7.89,2.11c0.96,0.25,1.95-0.31,2.2-1.27
                            c0.26-0.96-0.31-1.95-1.27-2.2l-11.37-3.05L38.8,38.46h11.65l8.32,8.32c0.35,0.35,0.81,0.53,1.27,0.53
                            c0.46,0,0.92-0.18,1.27-0.53c0.7-0.7,0.7-1.84,0-2.55l-5.78-5.78h8.64c0.99,0,1.8-0.81,1.8-1.8
                            C65.97,35.66,65.17,34.86,64.17,34.86z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}