export const LockClosedOutlineIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
    )
}

export const LockOpenOutlineIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
        </svg>
    )
}

export const LockClosedFilledIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-full" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
        </svg>
    )
}

export const LockOpenFilledIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-full" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
        </svg>
    )
}

export const LockClosedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-full" viewBox="0 0 72 72" fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M51.6,28.27h-2.27v-9.58c0-7.35-5.98-13.33-13.33-13.33s-13.33,5.98-13.33,13.33v9.58H20.4
                            c-2.98,0-5.4,2.42-5.4,5.4v27.56c0,2.98,2.42,5.4,5.4,5.4h31.2c2.98,0,5.4-2.42,5.4-5.4V33.67C57,30.7,54.58,28.27,51.6,28.27z
                            M26.27,18.69c0-5.36,4.36-9.73,9.73-9.73s9.73,4.36,9.73,9.73v9.58H26.27V18.69z M53.4,61.24c0,0.99-0.81,1.8-1.8,1.8H20.4
                            c-0.99,0-1.8-0.81-1.8-1.8V33.67c0-0.99,0.81-1.8,1.8-1.8h31.2c0.99,0,1.8,0.81,1.8,1.8V61.24z"/>
                        <path fill="currentColor" d="M36,39.72c-2.23,0-4.04,1.81-4.04,4.04c0,1.58,0.91,2.95,2.24,3.61v6.02c0,0.99,0.81,1.8,1.8,1.8
                            s1.8-0.81,1.8-1.8v-6.02c1.33-0.66,2.24-2.03,2.24-3.61C40.04,41.53,38.23,39.72,36,39.72z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const LockOpenIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="currentColor">
            <g>
                <rect className="st0" width="72" height="72"/>
                <g>
                    <g>
                        <path fill="currentColor" d="M51.6,28.27H26.27v-9.58c0-5.36,4.36-9.73,9.73-9.73s9.73,4.36,9.73,9.73v1.13c0,0.99,0.81,1.8,1.8,1.8
                            c0.99,0,1.8-0.81,1.8-1.8v-1.13c0-7.35-5.98-13.33-13.33-13.33s-13.33,5.98-13.33,13.33v9.58H20.4c-2.98,0-5.4,2.42-5.4,5.4
                            v27.56c0,2.98,2.42,5.4,5.4,5.4h31.2c2.98,0,5.4-2.42,5.4-5.4V33.67C57,30.7,54.58,28.27,51.6,28.27z M53.4,61.24
                            c0,0.99-0.81,1.8-1.8,1.8H20.4c-0.99,0-1.8-0.81-1.8-1.8V33.67c0-0.99,0.81-1.8,1.8-1.8h31.2c0.99,0,1.8,0.81,1.8,1.8V61.24z"/>
                        <path fill="currentColor" d="M36,39.72c-2.23,0-4.04,1.81-4.04,4.04c0,1.58,0.91,2.95,2.24,3.61v6.02c0,0.99,0.81,1.8,1.8,1.8
                            s1.8-0.81,1.8-1.8v-6.02c1.33-0.66,2.24-2.03,2.24-3.61C40.04,41.53,38.23,39.72,36,39.72z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const LockUnlockedIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none">
            <g>
                <rect className="st0" width="72" height="72"/>
                <g>
                    <g>
                        <path fill="currentColor" d="M28.19,39.72c-2.23,0-4.04,1.81-4.04,4.04c0,1.58,0.91,2.95,2.24,3.61v6.02c0,0.99,0.81,1.8,1.8,1.8
                            s1.8-0.81,1.8-1.8v-6.02c1.33-0.66,2.24-2.03,2.24-3.61C32.23,41.53,30.42,39.72,28.19,39.72z"/>
                        <path fill="currentColor" d="M51.25,5.36c-7.35,0-13.33,5.98-13.33,13.33v9.58H12.59c-2.98,0-5.4,2.42-5.4,5.4v27.56
                            c0,2.98,2.42,5.4,5.4,5.4h31.2c2.98,0,5.4-2.42,5.4-5.4V33.67c0-2.98-2.42-5.4-5.4-5.4h-2.27v-9.58c0-5.36,4.36-9.73,9.73-9.73
                            s9.73,4.36,9.73,9.73v1.13c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-1.13C64.58,11.34,58.6,5.36,51.25,5.36z M45.59,33.67
                            v27.56c0,0.99-0.81,1.8-1.8,1.8h-31.2c-0.99,0-1.8-0.81-1.8-1.8V33.67c0-0.99,0.81-1.8,1.8-1.8h31.2
                            C44.79,31.87,45.59,32.68,45.59,33.67z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}


