const getIcon = (id) => {
    const componentList = {
        0: '',
        2: 'SettingsIcon',
        8: '',
        9: '',
        10: '',
        11: '',
        12: '',
        13: '',
        14: '',
        15: 'AudioIcon',
        16: 'BlindsClosedIcon',
        17: 'ClimateIcon',
        18: 'ClockIcon',
        19: 'CurtainsOpenIcon',
        20: 'DirectionDownIcon',
        21: 'FanIcon',
        22: 'FireIcon',
        23: '',
        24: 'LightingIcon',
        25: 'PhotoIcon',
        26: '',
        27: 'LockClosedIcon',
        28: 'SetupIcon',
        29: 'SleepIcon',
        30: 'SpaIcon',
        31: 'DirectionUpIcon',
        32: 'WaterIcon',
        33: 'WindowsClosedIcon',
        34: '',
        35: 'HomeIcon',
        36: '',
        37: '',
        38: '',
        39: '',
        40: 'ScheduleIcon',
        41: 'DirectionRightIcon',
        42: '',
        43: '',
        44: 'UnlockedIcon',
        45: 'PlayIcon',
        46: '',
        47: '',
        48: '',
        49: '',
        50: '',
        51: 'DirectionLeftIcon',
        52: 'ThermometerIcon',
        53: 'TickIcon',
        54: 'StopIcon',
        55: '',
        56: 'DevicesIcon',
        57: 'WindowsOpenIcon',
        58: 'CurtainsClosedIcon',
        59: 'BlindsOpenIcon',
        60: '',
        61: '',
        62: 'TrashIcon',
        63: 'CrossIcon',
        64: 'PlusIcon',
        65: 'UserIcon',
        66: 'UsersIcon',
        67: 'AwayIcon',
        68: '',
        69: '',
        70: '',
        71: 'MinusIcon',
        72: '',
        73: '',
        74: '',
        75: '',
        76: '',
        77: '',
        78: '',
        79: '',
        80: '',
        81: '',
        82: '',
        83: '',
        84: '',
        85: '',
        86: '',
        87: '',
        88: '',
        89: '',
        90: 'DamperIcon',
        91: '',
        92: 'PhoneIcon',
        93: 'Swing0Icon',
        94: 'Swing30Icon',
        95: 'Swing60Icon',
        96: 'Swing90Icon',
        97: 'SwingAutoIcon',
        98: 'SwingOffIcon',
        99: 'BathIcon',
        100: 'BathroomHeatIcon',
        101: 'BedIcon',
        102: 'GarageDoorClosedIcon',
        103: 'TowelRailIcon',
        104: 'MirrorDemisterIcon',
        105: 'MirrorLightingIcon',
        106: 'UnderFloorHeatingIcon',
        107: 'UnderTileHeatingIcon',
        108: 'PoolIcon',
        109: 'LightMoodsIcon',
        110: 'GarageDoorOpenIcon',
        111: 'GateClosedIcon',
        112: 'GateOpenIcon',
        113: 'PaletteIcon',
        114: 'RefreshIcon',
        115: 'TickCircleIcon',
        116: 'DeviceOffIcon',
        117: 'DownloadIcon',
        118: 'AdjustIcon',
        119: 'DehumidifyIcon',
        120: 'HeatIcon',
        121: 'CoolIcon',
        122: 'AdminUserIcon',
        123: 'CameraIcon',
        124: 'CropIcon',
        125: 'SprinklerIcon',
        126: 'LightingFeatureIcon',
        127: 'LightingPathIcon',
        128: 'Unlocked2Icon',
        129: 'SensorOnIcon',
        130: 'SensorOffIcon',
        131: 'DeviceOffIcon',
        132: 'DeviceOffCircleIcon',
        133: 'RefreshCircleIcon',
        134: 'DirectionDownCircleIcon',
        135: 'DirectionUpCircleIcon',
        136: 'DirectionRightCircleIcon',
        137: 'DirectionLeftCircleIcon',
        138: 'PlusCircleIcon',
        139: 'MinusCircleIcon',
        140: 'PlayCircleIcon',
        141: 'StopCircleIcon',
        142: 'TickCircleIcon',
        143: 'CrossCircleIcon',
        144: 'StarFilledIcon',
        145: 'StarCircleFilledIcon',
        146: 'StarOutlineIcon',
        147: 'StarCircleOutlineIcon',
        148: 'BarIcon',
        149: 'BedDoubleIcon',
        150: 'CinemaIcon',
        151: 'CupboardIcon',
        152: 'DiningIcon',
        153: 'EntranceIcon',
        154: 'HiWallIcon',
        155: 'KitchenIcon',
        156: 'LivingIcon',
        157: 'PatioIcon',
        158: 'SculleryIcon',
        159: 'ServeryIcon',
        160: 'BathroomIcon',
        161: 'TouchIcon',
        162: 'LightingWallIcon',
        163: 'WindowSeatIcon',
        164: 'CellarIcon'
    }
    
    return componentList[id]
}

export default getIcon