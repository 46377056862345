import React, { useState, useEffect } from 'react'
import { Network } from '@capacitor/network';

export default function NetworkType({ onNetworkstateChanged = () => {}} ) {
    const [networkStatus, setNetworkStatus] = useState('')
    const [manualConnect, setManualConnect] = useState(false)


    const handleRefreshNetwork = (e) => {
        e.preventDefault()     
        updateStatus()
    }

    const handletoggleManualConnect = (e) => {
        setManualConnect(!manualConnect)
        updateStatus(e.currentTarget.checked)
    }

    const updateStatus = async (manualConnect) => {
        const status = await Network.getStatus()
        setNetworkStatus(status)
        onNetworkstateChanged(status, manualConnect)
    }

    const listenToNetwork = () => {
        Network.addListener('networkStatusChange', status => {
            setNetworkStatus(status)
        })
    }

    useEffect(() => {
        updateStatus()
        listenToNetwork()
    }, [])

    return (<div className="pt-2 flex text-xs justify-between w-full border-t">
        <div>
            {
                networkStatus.connectionType === 'wifi' ?   
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z" />
                    </svg> : ''
            }

            {
                networkStatus.connectionType === 'cellular' ?  
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                    </svg> : ''
            }

            {
                networkStatus.connectionType === 'none' || networkStatus.connectionType === 'unknown' ?  
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg> : ''
            }
        </div>

        <div className="flex items-center mb-4">
            <input 
                id="find-dc-mode-checkbox" 
                type="checkbox"
                checked={manualConnect} 
                onChange={handletoggleManualConnect}
                value="connection_mode" 
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="find-dc-mode-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Manual Connect</label>
        </div>
        
        <button onClick={(e) => { handleRefreshNetwork(e) }} className="h-[16px]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        </button>
    </div>)
}