export const screenKeys = {
	button: [
		'UserInfo',
		'customMenuV3'
	],
	table: [
		'customDiagnosticsV3'
	],
	form: [	
		'customParametersControlV3'
	],
	zone: [
		'customZonesControlV3'
	],
	legacy: [
		'schedule'
	]
}