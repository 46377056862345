const Sidebar = (state = [], action) => {
	const { type, payload } = action

	switch (type) {
		case 'FETCH_SIDEBAR_SUCCESS':
			return payload
			
		case 'FETCH_SIDEBAR_FAIL':
			return []
		default:
			return state
	}
}

export default Sidebar