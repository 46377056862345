export const SetupIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <path fill="currentColor" d="M41.66,66.71H30.3c-0.99,0-1.8-0.8-1.8-1.79c-0.03-5.08-4.15-9.22-9.19-9.22c-1.62,0-3.21,0.43-4.59,1.24
                    c-0.41,0.24-0.91,0.31-1.37,0.18c-0.46-0.12-0.86-0.43-1.1-0.84l-5.68-9.84c-0.5-0.86-0.2-1.96,0.65-2.46
                    c4.4-2.56,5.92-8.2,3.4-12.57c-0.81-1.41-1.98-2.57-3.39-3.37c-0.42-0.24-0.72-0.63-0.85-1.09c-0.13-0.46-0.06-0.96,0.18-1.37
                    l5.68-9.84c0.5-0.86,1.6-1.16,2.46-0.66c4.42,2.55,10.08,1.05,12.61-3.33c0.81-1.41,1.24-3.01,1.22-4.63
                    c0-0.48,0.18-0.94,0.52-1.28c0.34-0.34,0.8-0.53,1.28-0.53h11.36c0.99,0,1.8,0.81,1.8,1.8c0,5.11,4.12,9.27,9.19,9.27
                    c1.62,0,3.22-0.43,4.61-1.25c0.41-0.24,0.91-0.31,1.37-0.19c0.46,0.12,0.86,0.42,1.1,0.84l5.68,9.84c0.5,0.86,0.2,1.96-0.66,2.46
                    c-4.4,2.55-5.94,8.16-3.45,12.54c0.01,0.02,0.02,0.03,0.03,0.05c0.81,1.4,1.97,2.56,3.37,3.36c0.42,0.24,0.72,0.63,0.84,1.09
                    c0.12,0.46,0.06,0.95-0.18,1.37l-5.68,9.84c-0.5,0.86-1.59,1.16-2.45,0.66c-4.42-2.53-10.06-1.03-12.59,3.35
                    c-0.81,1.4-1.23,2.98-1.22,4.58c0,0.48-0.19,0.94-0.53,1.28S42.14,66.71,41.66,66.71z M31.96,63.11h8.02
                    c0.23-1.61,0.76-3.17,1.58-4.59c3.21-5.56,10.04-7.79,15.88-5.46l4.01-6.94c-1.27-0.99-2.34-2.22-3.16-3.62
                    c-0.01-0.02-0.02-0.04-0.03-0.06c-3.22-5.58-1.72-12.61,3.24-16.5l-4-6.94c-1.52,0.62-3.15,0.94-4.81,0.94
                    c-6.45,0-11.8-4.82-12.67-11.07h-8c-0.22,1.63-0.76,3.21-1.59,4.65c-3.22,5.57-10.06,7.8-15.9,5.44l-4,6.94
                    c1.3,1.01,2.39,2.26,3.22,3.69c3.21,5.56,1.73,12.59-3.22,16.48l4.01,6.94c1.51-0.61,3.13-0.93,4.78-0.93
                    C25.72,52.1,31.06,56.9,31.96,63.11z M36,49.51c-7.45,0-13.51-6.06-13.51-13.51S28.55,22.5,36,22.5S49.5,28.55,49.5,36
                    S43.45,49.51,36,49.51z M36,26.1c-5.46,0-9.91,4.44-9.91,9.91s4.44,9.91,9.91,9.91S45.9,41.46,45.9,36S41.46,26.1,36,26.1z"/>
            </g>        
        </svg>
    )
}