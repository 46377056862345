export const LightingIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M47.76,20.97c-0.99,0-1.8,0.81-1.8,1.8c0,5.52-4.49,10-10,10s-10-4.49-10-10c0-0.99-0.81-1.8-1.8-1.8
                            c-0.99,0-1.8,0.81-1.8,1.8c0,7.5,6.1,13.6,13.6,13.6c7.5,0,13.6-6.1,13.6-13.6C49.56,21.78,48.75,20.97,47.76,20.97z"/>
                        <path fill="currentColor" d="M20,22.77c0-0.99-0.81-1.8-1.8-1.8H7.79c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8h10.4
                            C19.19,24.57,20,23.76,20,22.77z"/>
                        <path fill="currentColor" d="M36,39.24c-0.99,0-1.8,0.81-1.8,1.8v10.4c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-10.4
                            C37.8,40.05,36.99,39.24,36,39.24z"/>
                        <path fill="currentColor" d="M21.37,33.53l-7.36,7.36c-0.7,0.7-0.7,1.84,0,2.55c0.35,0.35,0.81,0.53,1.27,0.53
                            c0.46,0,0.92-0.18,1.27-0.53l7.36-7.36c0.7-0.7,0.7-1.84,0-2.55C23.21,32.83,22.08,32.83,21.37,33.53z"/>
                        <path fill="currentColor" d="M64.12,20.97h-10.4c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8h10.4c0.99,0,1.8-0.81,1.8-1.8
                            C65.92,21.78,65.11,20.97,64.12,20.97z"/>
                        <path fill="currentColor" d="M50.58,33.53c-0.7-0.7-1.84-0.7-2.55,0c-0.7,0.7-0.7,1.84,0,2.55l7.36,7.36c0.35,0.35,0.81,0.53,1.27,0.53
                            s0.92-0.18,1.27-0.53c0.7-0.7,0.7-1.84,0-2.55L50.58,33.53z"/>
                    </g>
                </g>
            </g>     
        </svg>
    )
}

export const LightingFeatureIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M65.43,53.26l-4.04-5.98v-5.91c0-1.65-1.35-3-3-3H57.9v-27.1h6.25c0.99,0,1.8-0.81,1.8-1.8s-0.81-1.8-1.8-1.8
                        H7.85c-0.99,0-1.8,0.81-1.8,1.8s0.81,1.8,1.8,1.8h6.25v27.1h-0.5c-1.65,0-3,1.35-3,3v5.91l-4.04,5.98
                        c-0.52,0.57-0.81,1.31-0.81,2.09v1.51c0,1.65,1.35,3,3,3h3.79c0.21,1.68,1.64,2.97,3.37,2.96c1.72-0.01,3.13-1.3,3.34-2.96h3.79
                        c1.65,0,3-1.35,3-3v-1.51c0-0.77-0.29-1.52-0.81-2.09l-4.04-5.98v-5.91c0-1.65-1.35-3-3-3h-0.49v-27.1h16.5v18.61h-0.5
                        c-1.65,0-3,1.35-3,3v5.91l-4.04,5.98c-0.52,0.57-0.81,1.32-0.81,2.09v1.51c0,1.65,1.35,3,3,3h3.79c0.21,1.68,1.64,2.97,3.37,2.96
                        c1.72-0.01,3.13-1.3,3.34-2.96h3.79c1.65,0,3-1.35,3-3v-1.51c0-0.77-0.29-1.52-0.81-2.09l-4.04-5.98v-5.91c0-1.65-1.35-3-3-3H37.8
                        V11.27H54.3v27.1h-0.5c-1.65,0-3,1.35-3,3v5.91l-4.04,5.98c-0.52,0.57-0.81,1.32-0.81,2.09v1.51c0,1.65,1.35,3,3,3h3.79
                        c0.21,1.68,1.64,2.97,3.37,2.96c1.72-0.01,3.13-1.3,3.34-2.96h3.79c1.65,0,3-1.35,3-3v-1.51C66.24,54.58,65.95,53.83,65.43,53.26z
                        M22.35,55.41c0.03,0.05,0.07,0.09,0.1,0.14v0.71H9.36v-0.71c0.04-0.04,0.07-0.09,0.1-0.14l4.44-6.57c0.2-0.3,0.31-0.65,0.31-1.01
                        v-5.86h3.39v5.86c0,0.36,0.11,0.71,0.31,1.01L22.35,55.41z M42.44,46.92c0.03,0.05,0.07,0.09,0.1,0.14v0.71H29.46v-0.71
                        c0.04-0.04,0.07-0.09,0.1-0.14L34,40.35c0.2-0.3,0.31-0.65,0.31-1.01v-5.86h3.39v5.86c0,0.36,0.11,0.71,0.31,1.01L42.44,46.92z
                        M62.64,56.26H49.55v-0.71c0.04-0.04,0.07-0.09,0.1-0.14l4.44-6.57c0.2-0.3,0.31-0.65,0.31-1.01v-5.86h3.39v5.86
                        c0,0.36,0.11,0.71,0.31,1.01l4.44,6.57c0.03,0.05,0.07,0.09,0.1,0.14V56.26z"/>
                </g>
            </g>     
        </svg>
    )
}

export const LightingWallIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                <rect  width="72" height="72"/>
                <g>
                    <path fill="currentColor" d="M60.84,23.52c-2.81,0-5.09,2.28-5.09,5.09v11.5H26.39V29h14.92c0.59,0,1.14-0.29,1.48-0.78
                        c0.34-0.49,0.41-1.11,0.2-1.66l-7.2-19.03c-0.26-0.7-0.94-1.16-1.68-1.16H15.07c-0.75,0-1.42,0.46-1.68,1.16l-7.2,19.03
                        c-0.21,0.55-0.13,1.17,0.2,1.66C6.73,28.71,7.28,29,7.87,29h14.92v26.05c0,0.02,0.01,0.05,0.01,0.07
                        c-2.4,0.76-4.14,3.01-4.14,5.66c0,3.27,2.66,5.94,5.94,5.94c3.27,0,5.94-2.66,5.94-5.94c0-2.65-1.74-4.9-4.14-5.66
                        c0-0.02,0.01-0.05,0.01-0.07V43.71h29.36v11.5c0,2.81,2.28,5.09,5.09,5.09c2.81,0,5.09-2.28,5.09-5.09v-26.6
                        C65.93,25.8,63.65,23.52,60.84,23.52z M16.32,9.97h16.55L38.7,25.4H10.48L16.32,9.97z M24.59,63.11c-1.29,0-2.34-1.05-2.34-2.34
                        s1.05-2.34,2.34-2.34c1.29,0,2.34,1.05,2.34,2.34S25.88,63.11,24.59,63.11z M62.33,55.2c0,0.82-0.67,1.49-1.49,1.49
                        s-1.49-0.67-1.49-1.49v-26.6c0-0.82,0.67-1.49,1.49-1.49s1.49,0.67,1.49,1.49V55.2z"/>
                </g>
            </g>     
        </svg>
    )
}

export const LightingPathIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                <rect  width="72" height="72"/>
                <g>
                    <path fill="currentColor" d="M51.43,51.49H20.57c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h30.86c0.99,0,1.8,0.81,1.8,1.8
                        S52.43,51.49,51.43,51.49z"/>
                    <path fill="currentColor" d="M64.15,51.49h-6.27c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h6.27c0.99,0,1.8,0.81,1.8,1.8
                        S65.14,51.49,64.15,51.49z"/>
                    <g>
                        <path fill="currentColor" d="M36.07,30.37c-0.99,0-1.8-0.81-1.8-1.8v-6.26c0-0.99,0.81-1.8,1.8-1.8c0.99,0,1.8,0.81,1.8,1.8v6.26
                            C37.87,29.56,37.07,30.37,36.07,30.37z"/>
                        <path fill="currentColor" d="M47.77,33.08c-0.31,0-0.61-0.08-0.9-0.24c-0.86-0.5-1.16-1.6-0.66-2.46l3.13-5.43
                            c0.5-0.86,1.6-1.16,2.46-0.66c0.86,0.5,1.16,1.6,0.66,2.46l-3.13,5.43C49,32.76,48.39,33.08,47.77,33.08z"/>
                        <path fill="currentColor" d="M54.7,40.86c-0.62,0-1.23-0.32-1.56-0.9c-0.5-0.86-0.2-1.96,0.66-2.46l5.43-3.13c0.86-0.5,1.96-0.2,2.46,0.66
                            c0.5,0.86,0.2,1.96-0.66,2.46l-5.43,3.13C55.32,40.78,55.01,40.86,54.7,40.86z"/>
                    </g>
                    <g>
                        <path fill="currentColor" d="M24.38,33.08c-0.62,0-1.23-0.32-1.56-0.9l-3.13-5.43c-0.5-0.86-0.2-1.96,0.66-2.46
                            c0.86-0.5,1.96-0.2,2.46,0.66l3.13,5.43c0.5,0.86,0.2,1.96-0.66,2.46C24.99,33.01,24.68,33.08,24.38,33.08z"/>
                        <path fill="currentColor" d="M17.44,40.86c-0.31,0-0.61-0.08-0.9-0.24l-5.43-3.13c-0.86-0.5-1.16-1.6-0.66-2.46
                            c0.5-0.86,1.6-1.16,2.46-0.66l5.43,3.13C19.2,38,19.5,39.1,19,39.96C18.67,40.54,18.06,40.86,17.44,40.86z"/>
                    </g>
                    <path fill="currentColor" d="M14.08,51.49H7.82c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h6.26c0.99,0,1.8,0.81,1.8,1.8
                        S15.08,51.49,14.08,51.49z"/>
                </g>
            </g>
        </svg>
    )
}