import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSidebar } from '../actions'
import MenuButton from '../components/MenuButton'

export default function ButtonView() {
    const dispatch = useDispatch()
    const [menuButtons, setMenuButtons] = useState([])
    const [hasSideBar, setHasSideBar] = useState(false)

    const screenKey = useSelector(state => (state?.screen?.data) ? Object.keys(state.screen.data)[0] : null)
    const screenData = useSelector(state => (state?.screen?.data) ? Object.values(state.screen.data)[0] : {})    

    /** get buttons */
    useEffect(() => {
        if (screenData[screenKey + 'Info']?.buttons) {
            setMenuButtons(screenData[screenKey + 'Info'].buttons)
        }

    // eslint-disable-next-line
    }, [screenData[screenKey + 'Info']?.buttons])

    /** get sidebar items */
    useEffect(() => {   
        const sidebar = screenData[screenKey + 'Info']?.sideBarEditor ?? []

        setHasSideBar((sidebar && sidebar.length > 0))

        dispatch( getSidebar(sidebar) )

    // eslint-disable-next-line
    }, [screenData[screenKey + 'Info']?.sideBarEditor])

    return (
        <div className="flex w-full h-full items-start">
            <div className="w-full px-1 relative flex flex-wrap overflow-x-auto" >
                { 
                    (menuButtons) ?
                        menuButtons.map((b) => 
                            <div 
                                className={`
                                ${
                                    hasSideBar ? 'sm:w-1/4' : 'sm:w-1/5'} 
                                    w-1/4 max-button-height
                                `} 
                                key={b.buttonID}
                            >
                                    <MenuButton button={b}/>
                            </div>
                            ) : 'No Menu Items' 
                }
            </div>
        </div>
    )
}