export const BedIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" >
            <g>
                
                <g>
                    <path fill="currentColor" d="M64.15,19.15c-0.99,0-1.8,0.81-1.8,1.8v8.57H48.38c-2.69,0-4.91,1.97-5.33,4.55
                        c-0.94-0.78-2.14-1.25-3.45-1.25H9.56v-5.06c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8v6.86v13.83v8.68c0,0.99,0.81,1.8,1.8,1.8
                        s1.8-0.81,1.8-1.8v-6.88H43.2h19.14v6.88c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8V20.95C65.95,19.95,65.14,19.15,64.15,19.15z
                        M48.38,33.12h13.96v3.51H46.58v-1.71C46.58,33.92,47.39,33.12,48.38,33.12z M9.56,46.64V36.41H39.6c0.99,0,1.8,0.81,1.8,1.8v8.43
                        H9.56z M45,46.64v-6.4h17.34v6.4H45z"/>
                </g>
            </g>        
        </svg>
    )
}

export const BedDoubleIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" >
            <g>
                
                <g>
                    <path fill="currentColor" d="M61.97,38.04V24.23c0-2.98-2.42-5.4-5.4-5.4H15.43c-2.98,0-5.4,2.42-5.4,5.4v13.81
                        C7.72,38.65,6,40.76,6,43.26v9.95c0,2.5,1.72,4.61,4.03,5.22v2.12c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-1.93h44.73v1.93
                        c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8v-2.12c2.32-0.61,4.03-2.71,4.03-5.22v-9.95C66,40.76,64.28,38.65,61.97,38.04z
                        M15.43,22.43h41.14c0.99,0,1.8,0.81,1.8,1.8v13.63h-2.29c0.3-0.67,0.47-1.41,0.47-2.19c0-2.97-2.42-5.39-5.39-5.39H41.01
                        c-2.27,0-4.21,1.41-5.01,3.4c-0.79-1.99-2.74-3.4-5.01-3.4H20.84c-2.97,0-5.39,2.42-5.39,5.39c0,0.78,0.17,1.52,0.47,2.19h-2.29
                        V24.23C13.63,23.24,14.44,22.43,15.43,22.43z M19.05,35.67c0-0.99,0.8-1.79,1.79-1.79h10.15c0.99,0,1.79,0.8,1.79,1.79
                        s-0.8,1.79-1.79,1.79H20.84C19.86,37.46,19.05,36.66,19.05,35.67z M36,37.66c0.03,0.07,0.06,0.14,0.09,0.2h-0.17
                        C35.94,37.79,35.97,37.73,36,37.66z M39.22,35.67c0-0.99,0.8-1.79,1.79-1.79h10.15c0.99,0,1.79,0.8,1.79,1.79s-0.8,1.79-1.79,1.79
                        H41.01C40.02,37.46,39.22,36.66,39.22,35.67z M11.4,41.46H60.6c0.99,0,1.8,0.81,1.8,1.8v6.19H9.6v-6.19
                        C9.6,42.27,10.41,41.46,11.4,41.46z M60.6,55.01H11.4c-0.99,0-1.8-0.81-1.8-1.8v-0.15H62.4v0.15C62.4,54.2,61.59,55.01,60.6,55.01
                        z"/>
                </g>
            </g>
        </svg>
    )
}