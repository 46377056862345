import React from 'react'

import FormContainer from '../forms/FormContainer'

export default function TabContent({ tabContent }) {
    const formatFormElements = (formElements) => {

        return formElements.map(el => {
            let formattedFormObject = {}

            if (tabContent?.controlType === 'value') {
                formattedFormObject = {
                    command: el?.setValue,
                    value: el?.currentValue,
                    label: el?.title,
                    type: tabContent?.controlType,
                    min: el?.minValue,
                    max: el?.maxValue,
                    unit: el?.unit,
                    useSliders: tabContent?.useSliders,
                    enabled: el?.enabled
                }
            } else if (tabContent?.controlType === 'buttons') {
                formattedFormObject = {
                    type: tabContent?.controlType,
                    buttons: el?.buttons
                }
            }

            return formattedFormObject
        })
    }

    return (
        <div 
            className={`p-4 bg-gray-50 h-full overflow-y-auto w-full`}
            id="profile" 
            role="tabpanel" 
            aria-labelledby="profile-tab"
        >
            <div className="text-sm text-gray-500 dark:text-gray-400">
                {
                    tabContent?.mainControl && !tabContent?.displayFeatureControls ? 
                    <div>
                        <div className="pt-2 pb-10">
                            <FormContainer formElements={formatFormElements([tabContent?.mainControl])} />
                        </div> 
                    </div> : ''
                }

                { 
                    tabContent.featureControls 
                        ? <FormContainer formElements={formatFormElements(tabContent.featureControls)} /> 
                        : ''
                } 
            </div>
        
            { tabContent.displaySensorValue ? <div className="p-2 text-center">{tabContent?.sensorValue}</div> : '' }
            { tabContent?.statusText ? <div className="p-2 text-center">{ tabContent?.statusText }</div> : '' }
        </div>
    )
}