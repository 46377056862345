export const CropIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <g>
                            <path fill="currentColor" d="M28.79,66.71H11.47c-2.98,0-5.4-2.42-5.4-5.4V43.98c0-0.99,0.81-1.8,1.8-1.8s1.8,0.81,1.8,1.8v17.33
                                c0,0.99,0.81,1.8,1.8,1.8h17.33c0.99,0,1.8,0.81,1.8,1.8C30.59,65.9,29.78,66.71,28.79,66.71z"/>
                            <path fill="currentColor" d="M7.87,29.96c-0.99,0-1.8-0.81-1.8-1.8V10.83c0-2.98,2.42-5.4,5.4-5.4h17.33c0.99,0,1.8,0.81,1.8,1.8
                                s-0.81,1.8-1.8,1.8H11.47c-0.99,0-1.8,0.81-1.8,1.8v17.33C9.67,29.15,8.86,29.96,7.87,29.96z"/>
                        </g>
                        <g>
                            <path fill="currentColor" d="M60.53,66.71H43.21c-0.99,0-1.8-0.81-1.8-1.8c0-0.99,0.81-1.8,1.8-1.8h17.33c0.99,0,1.8-0.81,1.8-1.8V43.98
                                c0-0.99,0.81-1.8,1.8-1.8s1.8,0.81,1.8,1.8v17.33C65.93,64.29,63.51,66.71,60.53,66.71z"/>
                            <path fill="currentColor" d="M64.13,29.96c-0.99,0-1.8-0.81-1.8-1.8V10.83c0-0.99-0.81-1.8-1.8-1.8H43.21c-0.99,0-1.8-0.81-1.8-1.8
                                s0.81-1.8,1.8-1.8h17.33c2.98,0,5.4,2.42,5.4,5.4v17.33C65.93,29.15,65.13,29.96,64.13,29.96z"/>
                        </g>
                    </g>
                </g>
            </g>     
        </svg>
    )
}