export const LivingIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M60.59,26.26h-0.2c-0.63,0-1.23,0.11-1.79,0.31v-7.05c0-2.98-2.42-5.4-5.4-5.4H18.8c-2.98,0-5.4,2.42-5.4,5.4
                        v7.06c-0.57-0.2-1.17-0.32-1.81-0.32h-0.18c-2.98,0-5.4,2.42-5.4,5.4v18.08c0,2.98,2.42,5.4,5.4,5.4h1.99v2.27
                        c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-2.27H55v2.27c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8v-2.27h1.99
                        c2.98,0,5.4-2.42,5.4-5.4V31.66C65.99,28.68,63.57,26.26,60.59,26.26z M18.79,43.58c-0.99,0-1.8-0.81-1.8-1.8v-3.69H32.4
                        c0.99,0,1.8,0.81,1.8,1.8v3.69H18.79z M37.8,39.89c0-0.99,0.81-1.8,1.8-1.8H55v3.69c0,0.99-0.81,1.8-1.8,1.8H37.8V39.89z
                        M17,19.52c0-0.99,0.81-1.8,1.8-1.8H53.2c0.99,0,1.8,0.81,1.8,1.8v11.97C55,31.55,55,31.6,55,31.66v2.83H39.6
                        c-1.38,0-2.64,0.53-3.6,1.39c-0.96-0.86-2.22-1.39-3.6-1.39H16.99v-1.82c0-0.03,0.01-0.06,0.01-0.09V19.52z M62.39,49.74
                        c0,0.99-0.81,1.8-1.8,1.8H11.41c-0.99,0-1.8-0.81-1.8-1.8V31.66c0-0.99,0.81-1.8,1.8-1.8h0.18c0.99,0,1.8,0.81,1.8,1.8v10.12
                        c0,2.98,2.42,5.4,5.4,5.4H53.2c2.98,0,5.4-2.42,5.4-5.4v-9.11c0-0.03,0.01-0.06,0.01-0.08v-1.01c0.04-0.95,0.83-1.72,1.79-1.72
                        h0.2c0.99,0,1.8,0.81,1.8,1.8V49.74z"/>
                </g>
            </g>
        </svg>
    )
}