export const PoolIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M7.87,53.19c1.52,0,2.21,0.64,3.4,1.86c1.28,1.31,2.87,2.94,5.98,2.94c3.1,0,4.69-1.63,5.98-2.94
                            c1.19-1.22,1.88-1.86,3.4-1.86c1.52,0,2.21,0.64,3.4,1.86c1.28,1.31,2.87,2.94,5.98,2.94c3.1,0,4.7-1.63,5.98-2.94
                            c1.19-1.22,1.89-1.86,3.4-1.86c1.52,0,2.21,0.64,3.4,1.86c1.28,1.31,2.87,2.94,5.98,2.94s4.7-1.63,5.98-2.94
                            c1.19-1.22,1.89-1.86,3.4-1.86c0.99,0,1.8-0.81,1.8-1.8c0-0.99-0.81-1.8-1.8-1.8c-3.1,0-4.7,1.63-5.98,2.94
                            c-1.19,1.22-1.89,1.86-3.4,1.86s-2.21-0.64-3.4-1.86c-1.28-1.31-2.87-2.94-5.98-2.94c-3.1,0-4.7,1.63-5.98,2.94
                            c-1.19,1.22-1.89,1.86-3.4,1.86c-1.52,0-2.21-0.64-3.4-1.86c-1.28-1.31-2.87-2.94-5.98-2.94c-3.1,0-4.7,1.63-5.98,2.94
                            c-1.19,1.22-1.88,1.86-3.4,1.86c-1.52,0-2.21-0.64-3.4-1.86c-1.28-1.31-2.87-2.94-5.98-2.94c-0.99,0-1.8,0.81-1.8,1.8
                            C6.07,52.38,6.87,53.19,7.87,53.19z"/>
                        <path fill="currentColor" d="M64.13,57.85c-3.1,0-4.7,1.63-5.98,2.94c-1.19,1.22-1.89,1.86-3.4,1.86s-2.21-0.64-3.4-1.86
                            c-1.28-1.31-2.87-2.94-5.98-2.94c-3.1,0-4.7,1.63-5.98,2.94c-1.19,1.22-1.89,1.86-3.4,1.86c-1.52,0-2.21-0.64-3.4-1.86
                            c-1.28-1.31-2.87-2.94-5.98-2.94c-3.1,0-4.7,1.63-5.98,2.94c-1.19,1.22-1.88,1.86-3.4,1.86c-1.52,0-2.21-0.64-3.4-1.86
                            c-1.28-1.31-2.87-2.94-5.98-2.94c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8c1.52,0,2.21,0.64,3.4,1.86
                            c1.28,1.31,2.87,2.94,5.98,2.94c3.1,0,4.69-1.63,5.98-2.94c1.19-1.22,1.88-1.86,3.4-1.86c1.52,0,2.21,0.64,3.4,1.86
                            c1.28,1.31,2.87,2.94,5.98,2.94c3.1,0,4.7-1.63,5.98-2.94c1.19-1.22,1.89-1.86,3.4-1.86c1.52,0,2.21,0.64,3.4,1.86
                            c1.28,1.31,2.87,2.94,5.98,2.94s4.7-1.63,5.98-2.94c1.19-1.22,1.89-1.86,3.4-1.86c0.99,0,1.8-0.81,1.8-1.8
                            C65.93,58.65,65.13,57.85,64.13,57.85z"/>
                        <path fill="currentColor" d="M24.8,47.61c0.99,0,1.8-0.81,1.8-1.8v-2.72h12.92v2.72c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8V20.53
                            c0-4.76,3.87-8.64,8.64-8.64s8.64,3.87,8.64,8.64c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8
                            c0-6.75-5.49-12.24-12.24-12.24c-3.18,0-6.07,1.23-8.24,3.22c-2.24-2.07-5.16-3.22-8.28-3.22C28.49,8.29,23,13.78,23,20.53v25.28
                            C23,46.81,23.81,47.61,24.8,47.61z M26.6,39.49v-4.44h12.92v4.44H26.6z M35.24,11.89c2.27,0,4.39,0.88,5.99,2.43
                            c-1.08,1.82-1.71,3.94-1.71,6.21v10.93H26.6V20.53C26.6,15.77,30.47,11.89,35.24,11.89z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}