export const SculleryIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M60.6,38.26H16.83V33.6c0-0.66,0.54-1.2,1.2-1.2c0.66,0,1.2,0.54,1.2,1.2c0,0.99,0.81,1.8,1.8,1.8
                            c0.99,0,1.8-0.81,1.8-1.8c0-2.65-2.15-4.8-4.8-4.8c-2.65,0-4.8,2.15-4.8,4.8v4.65H11.4c-2.98,0-5.4,2.42-5.4,5.4v17.65
                            c0,2.98,2.42,5.4,5.4,5.4H60.6c2.98,0,5.4-2.42,5.4-5.4V43.66C66,40.68,63.57,38.26,60.6,38.26z M62.4,43.66v3.12H37.8v-4.92
                            h22.8C61.59,41.86,62.4,42.66,62.4,43.66z M34.2,63.11H23.68V41.86H34.2V63.11z M9.6,61.31V43.66c0-0.99,0.81-1.8,1.8-1.8h8.68
                            v21.25H11.4C10.41,63.11,9.6,62.3,9.6,61.31z M60.6,63.11H37.8V50.38h24.6v10.93C62.4,62.3,61.59,63.11,60.6,63.11z"/>
                        <path fill="currentColor" d="M16.76,48.95c-0.99,0-1.8,0.81-1.8,1.8v1.33c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-1.33
                            C18.56,49.76,17.76,48.95,16.76,48.95z"/>
                        <path fill="currentColor" d="M27,53.88c0.99,0,1.8-0.81,1.8-1.8v-1.33c0-0.99-0.81-1.8-1.8-1.8c-0.99,0-1.8,0.81-1.8,1.8v1.33
                            C25.2,53.07,26.01,53.88,27,53.88z"/>
                        <path fill="currentColor" d="M60.6,5.28H11.4C8.43,5.28,6,7.7,6,10.68v7.17c0,2.98,2.42,5.4,5.4,5.4H60.6c2.98,0,5.4-2.42,5.4-5.4v-7.17
                            C66,7.7,63.57,5.28,60.6,5.28z M9.6,17.85v-7.17c0-0.99,0.81-1.8,1.8-1.8h22.8v10.77H11.4C10.41,19.65,9.6,18.84,9.6,17.85z
                            M62.4,17.85c0,0.99-0.81,1.8-1.8,1.8H37.8V8.88h22.8c0.99,0,1.8,0.81,1.8,1.8V17.85z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}