import React from 'react'

export default function TableSearch({ tableData, handleOnSearch }) {

    const isJsonString = (str) => {
        if (!str || !isNaN(str)) { return false } 

        try {
            JSON.parse(str)
        } catch (e) {
            return false
        }
        return true
    }

    const handleFilter = (searchTerm) => {
        let data = {...tableData}

        if (searchTerm.length > 2) {
            const filteredData = data.rows.filter((o) => {
                return Object.keys(o.cells).some(k => {
                    return ((typeof o.cells[k] === 'string' || o.cells[k] instanceof String) && !isJsonString(o.cells[k])) 
                        ? o.cells[k].toLowerCase().includes(searchTerm.toLowerCase())
                        : false
                     
                })
            })

            data.rows = filteredData
        } 
        
        handleOnSearch(data)
    }

    return (
        <div className="flex items-center box-border w-full p-2">   
            <label htmlFor="simple-search" className="sr-only">Search</label>
            <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                </div>
                <input 
                    type="text" 
                    id="simple-search" 
                    className="text-sm rounded-lg block border w-full pl-10 p-2.5" 
                    placeholder="Search"
                    onKeyUp={(e) => { handleFilter(e.target.value) }} 
                    required 
                />
            </div>
        </div>
    )
}