export const CupboardIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M52.07,5.27H19.93c-2.98,0-5.4,2.42-5.4,5.4v47.31c0,2.98,2.42,5.4,5.4,5.4h0.3v1.59
                            c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8v-1.59h24.35v1.59c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8v-1.59h0.3
                            c2.98,0,5.4-2.42,5.4-5.4V10.67C57.47,7.69,55.05,5.27,52.07,5.27z M53.87,10.67v43.81H37.8V8.87h14.27
                            C53.07,8.87,53.87,9.67,53.87,10.67z M19.93,8.87H34.2v45.61H18.13V10.67C18.13,9.67,18.93,8.87,19.93,8.87z M52.07,59.78H19.93
                            c-0.96,0-1.74-0.76-1.79-1.7h35.73C53.81,59.02,53.03,59.78,52.07,59.78z"/>
                        <path fill="currentColor" d="M30.12,36.93c0.99,0,1.8-0.81,1.8-1.8v-2.62c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8v2.62
                            C28.32,36.13,29.12,36.93,30.12,36.93z"/>
                        <path fill="currentColor" d="M41.88,36.93c0.99,0,1.8-0.81,1.8-1.8v-2.62c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8v2.62
                            C40.08,36.13,40.89,36.93,41.88,36.93z"/>
                    </g>
                </g>
            </g>     
        </svg>
    )
}