export const AudioIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" >
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M34.28,62.88c-1.27,0-2.52-0.46-3.53-1.34c-0.12-0.1-0.22-0.22-0.31-0.34l-7.32-10.75H11.45
                            c-2.98,0-5.4-2.42-5.4-5.4V27.69c0-2.83,2.31-6,5.4-6h11.68l7.32-10.75c0.09-0.13,0.19-0.24,0.31-0.34
                            c1.62-1.41,3.83-1.73,5.78-0.84c1.95,0.89,3.16,2.77,3.16,4.91v42.81c0,2.14-1.21,4.03-3.16,4.91
                            C35.81,62.72,35.04,62.88,34.28,62.88z M33.27,58.95c0.76,0.54,1.54,0.26,1.77,0.16c0.25-0.11,1.05-0.56,1.05-1.64V14.67
                            c0-1.07-0.81-1.53-1.05-1.64c-0.23-0.11-1.01-0.39-1.77,0.16l-7.5,11.02c-0.09,0.13-0.19,0.24-0.31,0.35
                            c-0.55,0.48-1.25,0.74-1.97,0.74H11.45c-0.84,0-1.8,1.28-1.8,2.4v17.35c0,0.99,0.81,1.8,1.8,1.8h12.05
                            c0.72,0,1.42,0.26,1.97,0.74c0.12,0.1,0.22,0.22,0.31,0.34L33.27,58.95z M24.28,23.2L24.28,23.2L24.28,23.2z"/>
                        <path fill="currentColor" d="M47.7,50.42c-0.6,0-1.2-0.3-1.53-0.86c-0.52-0.85-0.26-1.96,0.59-2.48c3.86-2.37,6.16-6.49,6.16-11.02
                            s-2.3-8.64-6.16-11.02c-0.85-0.52-1.11-1.63-0.59-2.48c0.52-0.85,1.63-1.11,2.48-0.59c4.93,3.03,7.88,8.3,7.88,14.08
                            s-2.94,11.05-7.88,14.08C48.35,50.33,48.02,50.42,47.7,50.42z"/>
                        <path fill="currentColor" d="M53.58,56.69c-0.6,0-1.2-0.3-1.53-0.86c-0.52-0.85-0.26-1.96,0.59-2.48c6.06-3.73,9.67-10.19,9.67-17.29
                            s-3.62-13.56-9.67-17.29c-0.85-0.52-1.11-1.63-0.59-2.48c0.52-0.85,1.63-1.11,2.48-0.59c7.13,4.39,11.39,12,11.39,20.36
                            s-4.26,15.97-11.39,20.36C54.23,56.61,53.9,56.69,53.58,56.69z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}