export const getTitles = (isLoggedIn = false) => {
    const title = { title: !isLoggedIn ? 'Login' : 'Ata Touch' }

    if (window.location.pathname === '/account') {
        title.title = 'Account Settings'
    }

    if (window.location.pathname === '/settings') {
        title.title = 'App Settings'
    }

    if (window.location.pathname === '/logs') {
        title.title = 'Event Log'
    }

    if (window.location.pathname === '/connections') {
        title.title = 'My Connections'
    }

    return title
}

export const computeTitle = (screenData) => {
    return Object.values(screenData)[0]?.titleBar
}
