const Modal = (state = null, action) => {
	const { type, payload } = action

	switch (type) {
		case 'FETCH_MODAL_SUCCESS':
			return {
				...state,
				...payload
			}
		case 'FETCH_MODAL_FAIL':
			return {
				...state
			}
		case 'CLEAR_MODAL':
			return null
		default:
			return state
	}
}

export default Modal