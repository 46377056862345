export const PatioIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M57.62,33.71c-0.99,0-1.8,0.81-1.8,1.8V49.7h-8.39c-2.26,0-4.11,1.84-4.11,4.11v11.21
                            c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8v-6.91h8.9v6.91c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8V35.51
                            C59.42,34.52,58.61,33.71,57.62,33.71z M46.92,54.51v-0.7c0-0.28,0.23-0.51,0.51-0.51h8.39v1.2H46.92z"/>
                        <path fill="currentColor" d="M24.57,49.7h-8.39V35.51c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8v29.51c0,0.99,0.81,1.8,1.8,1.8
                            s1.8-0.81,1.8-1.8v-6.91h8.9v6.91c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8V53.81C28.68,51.55,26.84,49.7,24.57,49.7z
                            M16.18,54.51v-1.2h8.39c0.28,0,0.51,0.23,0.51,0.51v0.7H16.18z"/>
                        <path fill="currentColor" d="M63.09,18.81L38.71,6.05c-1.68-0.98-3.75-0.98-5.43,0L8.91,18.81c-0.99,0.52-1.61,1.54-1.61,2.66v2.38
                            c0,1.65,1.35,3,3,3h23.9v16.77h-9.21c-0.99,0-1.8,0.81-1.8,1.8s0.81,1.8,1.8,1.8h9.21v17.79c0,0.99,0.81,1.8,1.8,1.8
                            s1.8-0.81,1.8-1.8V47.22h9.21c0.99,0,1.8-0.81,1.8-1.8s-0.81-1.8-1.8-1.8H37.8V26.85h23.9c1.65,0,3-1.35,3-3v-2.38
                            C64.7,20.34,64.08,19.32,63.09,18.81z M46.72,23.25H25.28v-1.13c0-0.45,0.17-0.89,0.48-1.22L36,9.79L46.24,20.9
                            c0.31,0.33,0.48,0.77,0.48,1.22V23.25z M10.9,21.83l17.58-9.2l-5.38,5.83c-0.92,1-1.43,2.3-1.43,3.66v1.13H10.9V21.83z
                            M61.1,23.25H50.32v-1.13c0-1.36-0.51-2.66-1.43-3.66l-5.38-5.83l17.58,9.2V23.25z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}