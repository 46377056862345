import React, { useEffect } from 'react'
import TableButton from './TableButton'
import formatLogIcons from '../../utils/formatLogIcons'
import { useState } from 'react'
import TableSearch from './TableSearch'
import TableSort from './TableSort'
import RowDetail from './RowDetail'

export default function Table({ tableData, onRowClick, onInfoClick, onSort, enableSearch = true, enableIcons = false }) {
    const [visibleData, setVisibleData] = useState('')
    const [sortedBy, setSortedby] = useState('')
    const [sortIndex, setSortIndex] = useState(null)
    const [sortDirection, setSortDirection] = useState('')
    const [infoData, setInfoData] = useState(null)
    const [showInfo, setShowInfo] = useState(false)

    useEffect(() => {
        setVisibleData(tableData)
    }, [tableData])

    const handleOnSendCommandClick = (e, rowCommand) => {
        e.preventDefault()
        onRowClick(rowCommand) 
    }

    const handleOnInfoClick = (e, str) => {
        e.preventDefault()
        setInfoData(str)
        setShowInfo(true)
    }

    const handleOnInfoClose = () => {
        setShowInfo(false) 
        setInfoData('')
    }

    const sort = (index) => {
        if (sortDirection) {
            let sortedData = [...tableData?.rows]
            
            sortedData = sortedData.sort((a, b) => {
                a = a.cells[index]
                b = b.cells[index]
                
                if (!a) { return 1 }
                if (!b) { return -1 }
                if (sortDirection === 'asc') { return a < b ? -1 : 1 }
                return a < b ? 1 : -1
            })
            
            const setSorted = {...tableData}
            setSorted.rows = sortedData

            setVisibleData(setSorted)
            return
        } 
            
        setVisibleData(tableData)
    }

    useEffect(()=> { sort(sortIndex) }, [sortDirection])

    const handleSort = (e, columnName, index) => {
        setSortIndex(index)

        if (columnName !== sortedBy) {
            setSortDirection('asc')
            setSortedby(columnName)
            return
        }

        if (!sortDirection) {
            setSortDirection('asc')
            return
        }

        setSortDirection((sortDirection === 'asc') ? 'desc' : '')     
    }

    const filterTable = (filteredData) => {
        setVisibleData(filteredData)
    }

    const isJsonString = (str) => {
        if (!str || !isNaN(str)) { return false } 

        try {
            JSON.parse(str)
        } catch (e) {
            return false
        }
        return true
    }

    const formatIcon = (str) => {
        return (!enableIcons) ? str : formatLogIcons(str)
    }

    const canSort = (o, i) => {
        if (o.rows.length < 1) { return false }
        o = o.rows[0].cells
        return ((typeof o[i] === 'string' || o[i] instanceof String) && !isJsonString(o[i]))
    }
    
    return (
        <div>
            { enableSearch ? 
                <TableSearch tableData={tableData} handleOnSearch={filterTable} />
            : ''}

            <table className="
                w-full text-sm text-left 
                text-ata-a-outer-space-crayola 
                dark:text-ata-d-cultured          
                "
            >
                <thead className="
                    text-xs uppercase
                    text-ata-a-outer-space-crayola 
                    dark:text-ata-d-cultured    
                "
                >
                    <tr className="
                        border-b 
                        border-b-ata-a-silver
                        dark:border-b-ata-d-cultured">
                        { 
                            tableData?.header ?
                                tableData?.header.cells.map(
                                    (i, idx) => 
                                        <th key={'th-'+idx} scope="col" className="px-2 py-2">
                                            <div className="flex" onClick={(e) => { handleSort(e, i, idx) }}>
                                                <div>{i}</div>
                                                <div className={`pt-0.5 pl-1 ${ (sortedBy === i && sortDirection) ? '' : 'opacity-30' }`}> 
                                                    { canSort(tableData, idx) ?
                                                        <TableSort columnName={i} sortedBy={sortedBy} sortDirection={sortDirection} />
                                                    : '' }
                                                </div>
                                            </div>
                                        </th>
                                )
                                : <th className="px-2 py-2"></th> 
                        }
                        <th></th>         
                    </tr>
                </thead>
                <tbody>
                    { 
                        visibleData?.rows
                            ? visibleData?.rows.map((row, idx) => {
                                return (
                                    <tr 
                                        key={idx} 
                                        className="
                                            bg-ata-a-white
                                            dark:bg-ata-d-dark-liver 
                                            odd:bg-ata-a-platinum 
                                            even:bg-ata-a-silver 
                                            odd:dark:bg-ata-d-dark-liver 
                                            even:dark:bg-ata-d-deep-taupe
                                            active:bg-ata-a-gainsboro 
                                            dark:active:bg-ata-d-liver
                                        "
                                    >
                                        { 
                                            row.cells.map(
                                                (i, index) => 
                                                    <td 
                                                        key={'cell-' + index} 
                                                        className={`px-1 py-2 ${enableIcons ? '' : 'text-xs'}`}
                                                        style={ enableIcons ? {'fontSize': '10px', 'lineHeight': '1.2'} : {} }
                                                    >
                                                        { ((typeof i === 'string' || i instanceof String) && !isJsonString(i)) ? formatIcon(i)  : '' }
                                                        { (typeof i === 'object' && !Array.isArray(i)) ? <div className="w-6 h-6"><TableButton button={i}/></div> : '' }                                                                        
                                                        { 
                                                            (isJsonString(i) && Object.keys(JSON.parse(i)).length !== 0) 
                                                                ?  <button 
                                                                        className="px-1 block mx-auto" 
                                                                        onClick={(e) => { handleOnInfoClick(e, i) }}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 block mx-auto">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                                                        </svg>
                                                                    </button> 
                                                                : '' 
                                                        }
                                                    </td>
                                                ) 
                                        }
                
                                        <td className="pr-2">
                                            { 
                                                row?.editRow 
                                                    ?   <button 
                                                            className="px-1 underline" 
                                                            onClick={(e) => { handleOnSendCommandClick(e, row?.editRow) }}
                                                        >
                                                            edit
                                                        </button> 
                                                    : '' 
                                            }
                                        </td>
                                    </tr>
                                )
                        }) : <tr></tr> 
                    }
                </tbody>
            </table>

            <RowDetail data={infoData} show={showInfo} onClose={handleOnInfoClose} />
        </div>
    )
}



