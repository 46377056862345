export const BathIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none"  >
            <g>
                
                <g>
                    <path fill="currentColor" d="M64.13,27.94h-49.1V23.4c0-1.59,1.29-2.89,2.89-2.89c1.59,0,2.89,1.29,2.89,2.89c0,0.99,0.81,1.8,1.8,1.8
                        s1.8-0.81,1.8-1.8c0-3.58-2.91-6.49-6.49-6.49c-3.58,0-6.49,2.91-6.49,6.49v4.54H7.87c-0.99,0-1.8,0.81-1.8,1.8v2.19
                        c0,9.2,5.97,17.04,14.23,19.84v3.49c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8v-2.61c1.02,0.15,2.06,0.23,3.12,0.23h17.95
                        c1.06,0,2.1-0.08,3.12-0.23v2.61c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8v-3.49c8.27-2.81,14.23-10.64,14.23-19.84v-2.19
                        C65.93,28.75,65.13,27.94,64.13,27.94z M62.33,31.93c0,9.57-7.79,17.36-17.36,17.36H27.02c-9.57,0-17.36-7.79-17.36-17.36v-0.39
                        h52.67V31.93z"/>
                </g>
            </g>
        </svg>
    )
}