export const BarIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full" fill="none" >
            <g>
                
                <g>
                    <path fill="currentColor" d="M38.42,46.38l18.42-25.1c0.67-0.92,0.77-2.12,0.26-3.13c-0.51-1.01-1.54-1.64-2.68-1.64h-4.14l6.13-8.35
                        c0.59-0.8,0.41-1.93-0.39-2.52c-0.8-0.59-1.93-0.41-2.52,0.39L45.81,16.5H17.58c-1.14,0-2.16,0.63-2.68,1.64
                        c-0.51,1.01-0.41,2.21,0.26,3.13l18.42,25.1c0.18,0.24,0.39,0.44,0.62,0.62v16.04H21.54c-0.99,0-1.8,0.81-1.8,1.8
                        c0,0.99,0.81,1.8,1.8,1.8h28.91c0.99,0,1.8-0.81,1.8-1.8c0-0.99-0.81-1.8-1.8-1.8H37.8V47C38.03,46.82,38.24,46.62,38.42,46.38z
                        M53.24,20.1l-4.55,6.2h-5.6l4.55-6.2H53.24z M43.17,20.1l-4.55,6.2H23.31l-4.55-6.2H43.17z M25.95,29.9h10.03L33.7,33
                        c-0.59,0.8-0.41,1.93,0.39,2.52c0.32,0.24,0.69,0.35,1.06,0.35c0.55,0,1.1-0.25,1.45-0.74l3.84-5.24h5.6L36,43.59L25.95,29.9z"/>
                </g>
            </g>
        </svg>
    )
}