import React from 'react'
import RouteLink from './RouteLink'

export default function Header({titleBar, iOS, isPortrait, openDrawer, isLoggedIn}) {

    const handleOpenDrawer = (e) => {
        e.preventDefault()
        openDrawer()
    }

    return (
        <nav className={`
            flex justify-between border-b py-3 px-2 
            bg-black
            text-ata-a-white 
            dark:text-ata-d-cultured
            ${(iOS && isPortrait) ? 'pt-10' : '' }
        `}>

            { isLoggedIn ? 
                <button onClick={ (e) => { handleOpenDrawer(e) } }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
            : <span className="inline-block w-1 h-6"></span> }
                
            <label>{ titleBar?.title }</label>
            
            <div className="flex">
                { (titleBar?.backButton?.enabled) ? <RouteLink button={titleBar?.backButton} /> : '' }
                { (titleBar?.homeButton?.enabled) ? <RouteLink button={titleBar?.homeButton} /> : '' }
                { (titleBar?.settingsButton?.enabled) ? <RouteLink button={titleBar?.settingsButton} /> : '' }
            </div>
        </nav>
    )

}