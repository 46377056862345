import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { Auth0Provider } from '@auth0/auth0-react'
import { defineCustomElements } from '@ionic/pwa-elements/loader'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const clientId = process.env.REACT_APP_AUTH0_CLIENTID
const enableServiceWorker = process.env.REACT_APP_ENABLE_PWA

// @todo set the current version number if there is none
if (!localStorage.getItem('installedVersion')) {
  localStorage.setItem('installedVersion', process.env.REACT_APP_VERSION)
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
      >
        <App />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
)

reportWebVitals()
defineCustomElements(window)


if (enableServiceWorker === 'true') {
  serviceWorkerRegistration.register()
}

if (!enableServiceWorker || enableServiceWorker === 'false') {
  serviceWorkerRegistration.unregister()
}
