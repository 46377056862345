import React, { useState, useEffect } from 'react'
import { CrossCircleIcon, TickCircleIcon } from '../icons'

export default function ZoneListModal({ selected, children , handleToggleZoneState = () => {} }) {
    const [show, setShow] =  useState(false)

    useEffect(() => {

    }, [])

    return (
        <div>
            {
                selected ? 
                    <button 
                        className="relative w-full flex border rounded-lg"
                    >
                        <span className={`
                            absolute top-1 right-1 text-xs 
                            ${
                                selected.stateBubble 
                                    ? 'text-ata-d-yellow-green'
                                    : 'text-ata-d-burnt-sienna'
                            } 
                        `}>
                            { selected.subTitle }
                        </span>
    

                        { selected?.displayStateBubble ? 
                            <div 
                                className="w-8 h-8 pt-2 ml-2 text-ata-a-june-bud dark:text-ata-d-cultured"
                                onClick={(e) => { handleToggleZoneState(e, selected.setState) }}
                            >    
                                {
                                    selected.stateBubble 
                                        ? <TickCircleIcon colorClass="text-ata-d-yellow-green" />
                                        : <CrossCircleIcon colorClass="text-ata-d-burnt-sienna" />
                                }
                            </div>

                        : ''}

                        <div 
                            className="pr-6 flex-grow py-3 text-center"
                            onClick={() => { setShow(true) }}
                        >
                            { selected.title }
                        </div>     
                    </button> : ''
            }

            { 
                show ?
                    <div className="fixed top-12 left-0 w-full h-full p-6 bg-black bg-opacity-50" style={{zIndex: 30}}>
                        <button className="top-2 right-2 absolute px-4 py-2" onClick={() => { setShow(false) }}>close</button>
                        <ul className="mt-8 mx-auto border rounded-lg overflow-hidden" onClick={() => { setShow(false) }}>
                            { children }  
                        </ul>
                    </div> : '' 
            }
        </div>
    )
}