import React, { useEffect } from 'react'
import { useState } from 'react'

export default function RowDetail({ data, show, onClose }) {
    const [copied, setCopied] = useState(false)

    const canSend = () => {
        return false
    }

    const handleCopyToClipboard = (e) => {
        e.preventDefault()
        setCopied(true)
        navigator.clipboard.writeText(JSON.stringify(JSON.parse(data), null, 4))
        setTimeout(() => { setCopied(false) }, 1000);
    }

    const handleClose = (e) => {
        e.preventDefault()
        onClose()
    }

    return (
        <>
            { show ? 
                <>
                    <div 
                        className="fixed bg-black top-0 left-0 w-screen h-screen opacity-40"
                        onClick={(e) => { handleClose(e) }}
                    >
                    </div>

                    <div className="fixed top-2 right-2 w-6 h-6 text-ata-l-baby-powder" onClick={(e) => { handleClose(e) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>

                    <div 
                        className="fixed bg-ata-d-cultured rounded p-8 top-8 w-5/6 h-5/6 left-1/2 center-fixed"
                    >
                        <label className="block py-2">Event Data</label>
                        <pre className="w-full text-xs overflow-auto border rounded p-2 max-h-48">{JSON.stringify(JSON.parse(data), null, 4)}</pre>
                        <div className="flex justify-end">
                            { copied 
                                ?   <div className="my-2 p-2">Copied!</div> 
                                :   <button 
                                        className="my-2 p-2 border rounded"
                                        onClick={(e) => { handleCopyToClipboard(e) }}
                                    >Copy to clipboard</button> 
                            }

                            { canSend() ? <button className="my-2 p-2 border rounded">Contact support</button> : '' }
                        </div>
                    </div>

                </>
            : ''}

        </>
    )
}