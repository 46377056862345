/* eslint-disable */

// don't check this file it's just a temporary hack for old screens, use at your own risk

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import MenuButton from '../components/MenuButton'
import Select from '../components/forms/Select'
import { dayLabels } from '../constants'
import TimePicker from '../components/forms/TimePicker'

export default function LegacyView() {
    const screenKey = useSelector(state => (state?.screen?.data) ? Object.keys(state.screen.data)[0] : null)
    const screenData = useSelector(state => (state?.screen?.data) ? Object.values(state.screen.data)[0] : {})

    const forceOnButton = {
        command: null,
        bitMapId: 132,
        buttonTitle: 'Force On'
    }
    const forceOffButton = {
        command: null,
        bitMapId: 131,
        buttonTitle: 'Force Off'
    }

    let dayOptions = []
    const options = () => {
        const optionsArray = []

        for (const [key, value] of Object.entries(dayLabels)) {
            dayOptions.push({
                id: key, 
                value: value.short, 
                label: value.short.charAt(0), 
                selected: false
            })
        }

        return optionsArray 
    }

    useEffect(() => {
        dayOptions = options()
        // console.warn('screenData', screenData)

    // eslint-disable-next-line    
    }, [screenData])

    const handleOnSelect = () => {

    }
     
    return (

        <div className="flex w-full h-full justify-center pb-2">
            {
                screenKey === 'schedule' ? 
                <div>
                    { 
                        screenData?.scheduleInfo?.Events.map((i, idx) => {
                            return (
                                <div className="border p-4 my-4 rounded-lg" key={idx}>
                                    <h1 className="text-center">{i.name}</h1>

                                    <div className="flex justify-center p-2">
                                        <div className="w-20 max-button-height mx-2">
                                            <MenuButton button={forceOnButton} />
                                        </div>

                                        <div className="w-20 max-button-height mx-2">
                                            <MenuButton button={forceOffButton} />
                                        </div>
                                    </div>
                                    <div>
                                        <Select
                                                label='Select Days'
                                                options={dayOptions}
                                                multi={true}
                                                daySelector={true}
                                                onChange={handleOnSelect}
                                            />
                                    </div>
                                    <div className="pt-2">
                                        <TimePicker label="Set Time" />
                                    </div>
                                </div>
                            )
                        }) 
                    }
                </div> : ''
            }
        </div>
    )
}