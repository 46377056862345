import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearModal } from '../actions'
import { getCommandService } from '../services/command.service'
import NumberInput from './forms/NumberInput'
import OptionsInput from './forms/OptionsInput'
import TextInput from './forms/TextInput'
import DatePicker2 from './forms/DatePicker2'


export default function Modal({ data, display = true }) {
    const commandService = getCommandService()
    const [shouldDisplay, setShouldDisplay] = useState(display)
    const dispatch = useDispatch()

    const [modalKey, setModalKey] = useState(null)
    const [modalData, setModalData] = useState(null)
    const [modalContent, setModalContent] = useState({})
    const [numeric, setNumeric] = useState('')
    const [options, setOptions] = useState('')
    const [text, setText] = useState('')
    const [date, setDate] = useState('')

    const handleClose = () => {
        dispatch(clearModal())
        setShouldDisplay(false)
    }

    const handleNumericSubmit = (e) => {
        e.preventDefault()

        if (numeric && numeric.length > 0 && modalContent?.setValue) {
            commandService.queueCommand(modalContent?.setValue, numeric.toString())
            setShouldDisplay(false)
        } else {
            console.warn('invalid numeric input')
        }
    }

    const handleTextSubmit = (e) => {
        e.preventDefault()

        if (text && text.length > 0 && modalContent?.setValue) {
            commandService.queueCommand(modalContent?.setValue, text.toString())
            setShouldDisplay(false)
        } else {
            console.warn('invalid text input')
        }
    }

    const handleDateSubmit = (e) => {
        e.preventDefault()

        if (date && modalContent?.setValue) {
            commandService.queueCommand(modalContent?.setValue, date.toString())
            setShouldDisplay(false)
        } else {
            console.warn('invalid date input')
        }
    }

    const handleNumericOnChange = (label, value) => {
        setNumeric(value)
    }

    const handleOptionsOnChange = (label, value) => {
        setOptions(value)
        if (modalContent?.setValue && modalContent?.title.toLowerCase() === 'done') {
            //send options only if the final button title is done, to match the implementation on the hmi
            if (options && options.length > 0 && modalContent?.setValue) {
                commandService.queueCommand(modalContent?.setValue, options.toString())
                setShouldDisplay(false)
            } else {
                console.warn('invalid options')
            }
        }
    }

    const handleTextOnChange = (label, value) => {
        setText(value)
    }

    const handleDateOnChange = (label, value) => {
        setDate(value)
    }

    useEffect(() => {
        if (data && data?.modalData) {
            setModalKey(Object.keys(data?.modalData)[0])
            setModalData(Object.values(data)[0])
        }
    }, [display, data])

    useEffect(() => {
        if (modalKey && modalData) {
            setModalContent(modalData[modalKey]);
            setShouldDisplay(display);
        }
    }, [modalKey, modalData]);

    useEffect(() => {
        if (modalContent?.closePopUp) {
            handleClose()
        }

        //set the starting value
        if (modalContent?.type === 'optionsSetterV3') {
            setOptions(modalContent?.options)
        } else if (modalContent?.type === 'keyPadV3') {
            if (modalContent?.subType === 'numeric') {
                setNumeric(modalContent?.value)
            } else if (modalContent?.subType === 'text') {
                setText(modalContent?.value)
            } else if (modalContent?.subType === 'calendar') {
                setDate(modalContent?.value)
            }
        }
    }, [modalContent]);

    return (
        <div 
            id="appModal" 
            tabIndex="-1" 
            aria-hidden="true" 
            className={`
                ${!shouldDisplay ? 'hidden' : '' } 
                overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-screen bg-ata-d-dark-liver bg-opacity-80
            `}>
            
            <div className="relative p-4 w-full h-full md:h-auto">
                <div className="
                    relative bg-white rounded-lg shadow border
                    bg-ata-a-white
                    border-ata-a-june-bug
                    dark:bg-ata-d-dark-liver
                    dark:border-ata-d-yellow-green
                ">
                    <div className="flex justify-between items-start p-4 rounded-t border-b dark:text-ata-d-cultured">
                        <h3 className="text-xl font-semibold dark:text-ata-d-cultured">
                            { modalContent?.title }
                        </h3>
                        <button
                            onClick={() => { handleClose() }} 
                            type="button" 
                            className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:text-ata-d-cultured"
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>  
                        </button>
                    </div>
                    <div className="p-6 space-y-6 dark:text-ata-d-cultured">
                        {modalContent?.type === 'messageBoxV3' ? <div>{modalContent?.message}</div> : ''}
                        {
                            modalContent?.type === 'optionsSetterV3' ?
                                <div>
                                    <OptionsInput
                                        label={modalContent?.title}
                                        options={modalContent?.options}
                                        multi={modalContent?.multiSelect}
                                        onChange={handleOptionsOnChange}
                                    />
                                </div> : ''
                        }

                        {
                            modalContent?.type === 'keyPadV3' && (modalContent?.subType === 'pin' || modalContent?.subType === 'numeric') ? (
                                <div>
                                    <NumberInput
                                        label={modalContent?.title}
                                        value={modalContent?.subType === 'pin' ? "" : modalContent?.value}
                                        onChange={handleNumericOnChange}
                                        inputType={modalContent?.subType === 'pin' ? "password" : "numeric"}
                                    />
                                    <button
                                        type="button"
                                        className="block rounded border w-full p-2 mt-2 text-sm"
                                        onClick={(e) => { handleNumericSubmit(e) }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            ) : modalContent?.type === 'keyPadV3' && (modalContent?.subType === 'text' || modalContent?.subType === 'ip') ? (
                                <div>
                                    <TextInput label={modalContent?.title} value={modalContent?.value} onChange={handleTextOnChange} />
                                    <button
                                        type="button"
                                        className="block rounded border w-full p-2 mt-2 text-sm"
                                        onClick={(e) => { handleTextSubmit(e) }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            ) : modalContent?.type === 'keyPadV3' && modalContent?.subType === 'calendar' ? (
                                <div>
                                    <DatePicker2 label={modalContent?.title} preSelectedDay={modalContent?.value} onChange={handleDateOnChange} />
                                    <button
                                        type="button"
                                        className="block rounded border w-full p-2 mt-2 text-sm"
                                        onClick={(e) => { handleDateSubmit(e) }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            ) : ''
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

