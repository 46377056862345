import { combineReducers } from 'redux'
import Auth from './auth.reducer'
import Screen  from './app.reducer'
import Modal from './modal.reducer'
import Form from './form.reducer'
import Sidebar from './sidebar.reducer'

const reducers = combineReducers({
    screen: Screen,
    modal: Modal,
    form: Form,
    sidebar: Sidebar,
    auth: Auth
})

export default reducers