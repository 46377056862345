export const DownloadIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M33.39,48.49c0.72,0.72,1.66,1.08,2.61,1.08c0.94,0,1.88-0.36,2.6-1.07l11.29-11.23
                            c0.7-0.7,0.71-1.84,0.01-2.55c-0.7-0.7-1.84-0.71-2.55-0.01l-9.55,9.5V14.56c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8V44.2
                            l-9.49-9.49c-0.7-0.7-1.84-0.7-2.55,0c-0.7,0.7-0.7,1.84,0,2.55L33.39,48.49z"/>
                        <path fill="currentColor" d="M63.91,37.02c-0.99,0-1.8,0.81-1.8,1.8v15.16c0,0.99-0.81,1.8-1.8,1.8H11.69c-0.99,0-1.8-0.81-1.8-1.8V38.82
                            c0-0.99-0.81-1.8-1.8-1.8s-1.8,0.81-1.8,1.8v15.16c0,2.98,2.42,5.4,5.4,5.4h48.63c2.98,0,5.4-2.42,5.4-5.4V38.82
                            C65.71,37.83,64.91,37.02,63.91,37.02z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}