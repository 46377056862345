import React from 'react'

export function AppLoader() {
    return (
        <div className="flex flex-col absolute top-0 left-0 w-screen h-screen items-center justify-center">
            <div className="loader-ata">
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
            </div>
            <label className="text-xs text-ata-a-outer-space-crayola dark:text-ata-d-cultured">Initializing</label>
        </div>
    )
}

export function ScreenLoader() {
    return (
      <div className="flex flex-col absolute top-0 left-0 w-screen h-screen items-center justify-center">
            <div className="loader-ata">
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
            </div>
            <label className="text-xs text-ata-a-outer-space-crayola dark:text-ata-d-cultured">Loading</label>
      </div>
    )
}

export function LoginLoader() {
    return (
      <div className="flex flex-col absolute top-0 left-0 w-screen h-screen items-center justify-center">
            <div className="loader-ata">
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
            </div>
            <label className="text-xs text-ata-a-outer-space-crayola dark:text-ata-d-cultured">Signing in</label>
      </div>
    )
}

export function FragmentLoader({ message = '' }) {
    return (
        <div className="flex flex-col w-full h-full items-center justify-center">
            <div className="loader-ata">
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
                <div className="bg-ata-a-outer-space-crayola dark:bg-ata-d-cultured"></div>
            </div>
            <label className="text-xs text-ata-a-outer-space-crayola dark:text-ata-d-cultured">{message}</label>
        </div>
    )
}