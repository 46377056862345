import React from 'react'

import getIcon from '../../utils/getIcon'
import * as Icons from '../icons'
import { getCommandService } from '../../services/command.service'

export default function TableButton(props) {
    let Icon = null
    const commandService = getCommandService()
    const iconComponentName = getIcon(props?.button?.bitMapId)
    
    if (Icons[iconComponentName]) {
        Icon = Icons[iconComponentName]
    }

    const handleClick = () => {
        commandService.queueCommand(props?.button?.command) 
    }

    return (
        <button 
            onClick={(e) => { handleClick() }} 
            className={`
                flex items-center relative w-full max-h-full rounded text-center active:bg-ata-a-gainsboro dark:active:bg-ata-d-liver
            `}
        >

            { 
                Icon 
                    ? <div
                        className={`
                            icon-max-height
                            text-ata-a-june-bud
                            dark:text-ata-d-cultured
                        `}
                    >
                        <Icon />
                    </div> 
                    : <div className="text-center px-2 mb-2 py-6 w-4/5">...</div>
            } 
            
            <label className="block text-sm text-center mb-2">
                <span>{props?.button?.buttonTitle}</span>
                <small className={`
                    absolute top-2 right-2 text-xs
                    ${ props?.button?.colour === 1  ? 'text-ata-d-burnt-sienna' : '' }
                    ${ props?.button?.colour === 2  ? 'text-ata-d-yellow-green' : '' }
                `}>
                    {props?.button?.buttonSubTitle}
                </small>
            </label>
        </button>
    )
}