export const PaletteIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M28.05,44.15c-0.02,0-0.04,0-0.06,0c-3.64,0.03-6.57,3.02-6.54,6.66c0.02,1.76,0.72,3.41,1.97,4.65
                            c1.24,1.22,2.88,1.89,4.62,1.89c0.02,0,0.04,0,0.06,0c1.76-0.02,3.41-0.72,4.65-1.97c1.24-1.26,1.91-2.92,1.89-4.68
                            C34.62,47.07,31.67,44.15,28.05,44.15z M30.19,52.85c-0.56,0.57-1.31,0.89-2.11,0.9c-0.79,0-1.56-0.3-2.13-0.86
                            c-0.57-0.56-0.89-1.31-0.9-2.11c-0.01-1.65,1.32-3.01,2.97-3.02c0.01,0,0.02,0,0.03,0c1.64,0,2.98,1.33,3,2.97
                            C31.06,51.53,30.75,52.28,30.19,52.85z"/>
                        <path fill="currentColor" d="M28.57,21.19c2.12,0,3.84-1.72,3.84-3.84c0-2.12-1.72-3.84-3.84-3.84c-2.12,0-3.84,1.72-3.84,3.84
                            C24.73,19.47,26.45,21.19,28.57,21.19z"/>
                        <circle fill="currentColor" cx="40.73" cy="16.3" r="3.84"/>
                        <path fill="currentColor" d="M51.08,25.59c2.12,0,3.84-1.72,3.84-3.84s-1.72-3.84-3.84-3.84c-2.12,0-3.84,1.72-3.84,3.84
                            S48.96,25.59,51.08,25.59z"/>
                        <path fill="currentColor" d="M23.26,24.53c0-2.12-1.72-3.84-3.84-3.84c-2.12,0-3.84,1.72-3.84,3.84s1.72,3.84,3.84,3.84
                            C21.54,28.37,23.26,26.65,23.26,24.53z"/>
                        <path fill="currentColor" d="M56.68,48.13c5.74-2.95,9.34-8.91,9.28-15.61c0-0.04,0-0.07,0-0.11C64.96,18.13,51.47,6.6,35.82,6.6
                            c-0.09,0-0.19,0-0.28,0c0,0,0,0,0,0c-8.02,0.07-15.49,3.22-21.04,8.86C8.96,21.1,5.94,28.62,6.01,36.65
                            c0.07,8.02,3.22,15.49,8.86,21.04c5.58,5.48,13,8.49,20.92,8.49c0.09,0,0.18,0,0.27,0c1.64-0.01,3.29-0.7,4.52-1.88
                            c1.32-1.27,2.04-2.96,2.02-4.78c-0.02-1.8-0.74-3-1.54-4.18c-0.07-0.1-0.14-0.19-0.23-0.27c-0.42-0.42-0.68-1.13-0.69-1.92
                            c-0.01-1.42,1.2-3.01,2.97-3.02c0.22,0,0.43-0.05,0.62-0.12c1.67,1.85,3.78,4.15,5.98,6.43c9.87,10.25,11.86,10.29,12.91,10.31
                            c0.03,0,0.06,0,0.09,0c0.88,0,1.72-0.36,2.31-0.99c2.1-2.24,0.44-5.48-5.29-13.46C58.76,50.91,57.72,49.51,56.68,48.13z
                            M36.55,53.17c0.01,1.67,0.6,3.23,1.63,4.31C38.82,58.46,39,58.9,39,59.55c0.01,0.81-0.32,1.57-0.92,2.15
                            c-0.57,0.55-1.32,0.87-2.06,0.88c-7.03,0.06-13.68-2.59-18.64-7.46c-4.96-4.87-7.72-11.44-7.78-18.5
                            c-0.06-7.06,2.59-13.68,7.46-18.64c4.87-4.96,11.44-7.72,18.5-7.78c0,0,0,0,0,0c0.08,0,0.17,0,0.25,0
                            c13.78,0,25.64,10,26.54,22.41c0.03,5.53-3.06,10.4-7.92,12.61c-2.15-2.76-4.19-5.25-5.71-7.03l-0.86-1.01
                            c-0.49-0.58-1.12-1.02-1.81-1.28c-0.05-1.1-0.42-2.43-1.11-4.03c-0.55-1.27-1.3-2.35-2.24-3.2c-1.5-1.37-3.15-1.71-4.38-1.95
                            c-0.59-0.12-1.15-0.23-1.55-0.45c-0.09-0.05-0.12-0.09-0.14-0.15c-0.43-1.24-1.55-2.07-2.86-2.14c-1.3-0.07-2.48,0.66-3.03,1.83
                            c-0.34,0.73-0.63,1.57-0.87,2.57v0c-0.1,0.42-0.19,0.86-0.28,1.36c-0.81,4.55,0.97,8.6,4.77,10.84c1.25,0.74,2.69,1.44,4.07,1.77
                            c0.05,1.08,0.44,2.12,1.15,2.94c0.01,0.01,0.02,0.03,0.04,0.04c0.05,0.06,0.22,0.25,0.73,0.85c0.08,0.1,0.3,0.34,0.61,0.69
                            C38.23,47.86,36.52,50.6,36.55,53.17z M39.9,38.96C39.9,38.96,39.9,38.96,39.9,38.96c-0.55,0-2-0.47-3.7-1.47
                            c-2.48-1.46-3.6-4.05-3.06-7.11c0.07-0.42,0.15-0.8,0.24-1.15c0.09-0.38,0.19-0.73,0.3-1.05c0.35,0.52,0.82,0.95,1.4,1.26
                            c0.88,0.47,1.76,0.65,2.56,0.81c1.01,0.21,1.88,0.38,2.64,1.08c0.56,0.51,1.02,1.17,1.36,1.97c0.74,1.7,0.84,2.61,0.81,2.92
                            C42.03,36.69,40.44,38.39,39.9,38.96z M43.08,43.83c-0.47-0.55-0.67-0.78-0.77-0.89c-0.24-0.28-0.33-0.68-0.25-1.06
                            c0.11-0.09,0.21-0.18,0.31-0.28c0.17-0.18,1.43-1.53,2.21-2.36c0.2,0.01,0.4,0.11,0.55,0.29L46,40.53
                            c2.72,3.17,7.12,8.68,10.82,13.86c3.64,5.08,4.88,7.42,5.3,8.47C59.28,61.24,51.35,53.34,43.08,43.83z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}