import React, { useState } from 'react'

export default function TextInput({
    label,
    placeholder = '',
    value,
    formKey,
    onChange,
    onClick,
    command
}) {
    const [inputValue, setValue] = useState(value)

    const handleOnChange = (event) => {
        setValue(event.target.value)
        if (onChange !== null) {
            onChange(command, event.target.value, formKey)
        }
    }

    const handleOnClick = (event) => {
        if (onClick !== null) {
            onClick(command, event.target.value, formKey)
        }
    }

    return (
        <div className="w-full">
            <label 
                htmlFor={label} 
                className="
                    block mb-2 text-sm font-medium text-gray-900 
                    dark:text-gray-300
                "
            >
                {label}
            </label>
            <input 
                type="text" 
                id={label}
                placeholder={placeholder}
                onChange={handleOnChange}
                onClick={handleOnClick}
                value={inputValue}
                className="
                    block w-full p-2.5 border text-sm rounded-lg 
                    focus:ring-ata-a-june-bud
                    focus:border-ata-a-june-bud   
                    dark:bg-ata-d-dark-liver 
                    dark:border-ata-d-cultured
                    dark:placeholder-ata-d-gray-web
                    dark:text-ata-d-cultured
                    dark:focus:ring-ata-d-yellow-green 
                    dark:focus:border-ata-d-yellow-green" 
            />
        </div>
    )
}