export const DamperIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M53.29,20.26c-2.31-2.31-5.16-3.25-8.05-2.64c-4,0.84-7.6,4.48-9.64,9.72c-0.12,0.31-0.45,0.46-0.76,0.5
                            c-0.34,0.05-0.69-0.19-0.83-0.57c-0.4-1.07-0.46-2.02-0.18-2.88c1.12-3.35,0.03-6.86-2.66-8.52c-1.18-0.73-5.45-2.68-10.98,2.86
                            c-2.31,2.31-3.25,5.17-2.65,8.06c0.84,4,4.48,7.61,9.74,9.65c0.25,0.1,0.44,0.37,0.48,0.68c0,0.02,0,0.04,0.01,0.06
                            c0.05,0.35-0.19,0.71-0.56,0.84c-1.06,0.38-1.99,0.43-2.84,0.15c-3.36-1.12-6.88-0.01-8.55,2.7c-0.73,1.18-2.67,5.45,2.84,10.96
                            c2.32,2.32,5.2,3.27,8.09,2.65c4.01-0.85,7.62-4.5,9.66-9.76c0.1-0.25,0.33-0.44,0.64-0.48c0.33-0.05,0.74,0.09,0.88,0.44
                            c0.44,1.12,0.52,2.14,0.23,3.03c-1.13,3.36-0.03,6.87,2.67,8.55c0.54,0.34,1.75,0.93,3.43,0.93c1.96,0,4.57-0.81,7.54-3.78
                            c2.31-2.31,3.26-5.18,2.66-8.06c-0.83-3.99-4.44-7.59-9.66-9.64c-0.34-0.13-0.57-0.41-0.62-0.78c-0.05-0.33,0.14-0.66,0.44-0.78
                            c1.11-0.43,2.12-0.5,3-0.2c3.35,1.12,6.85,0.01,8.52-2.69C56.87,30.07,58.82,25.79,53.29,20.26z M53.08,29.36
                            c-0.79,1.27-2.56,1.75-4.31,1.17c-1.71-0.58-3.54-0.49-5.46,0.26c-1.87,0.73-2.98,2.63-2.7,4.66c0.23,1.61,1.32,2.99,2.87,3.6
                            c4.02,1.58,6.88,4.27,7.45,7.03c0.36,1.71-0.21,3.31-1.68,4.78c-2.47,2.47-4.91,3.34-6.53,2.34c-1.27-0.79-1.75-2.57-1.15-4.35
                            c0.56-1.71,0.46-3.56-0.3-5.48c-0.66-1.66-2.29-2.72-4.11-2.72c-0.2,0-0.4,0.01-0.61,0.04c-1.59,0.2-2.94,1.25-3.52,2.75
                            c-1.57,4.06-4.27,6.95-7.04,7.54c-1.71,0.36-3.33-0.2-4.8-1.68c-2.46-2.46-3.33-4.9-2.33-6.52c0.79-1.28,2.58-1.77,4.35-1.17
                            c1.62,0.54,3.37,0.48,5.2-0.17c1.97-0.71,3.19-2.69,2.91-4.72c0-0.02,0-0.03-0.01-0.04c-0.22-1.63-1.27-3-2.75-3.57
                            c-4.06-1.58-6.94-4.28-7.52-7.04c-0.36-1.7,0.2-3.3,1.67-4.77c2.47-2.47,4.92-3.35,6.54-2.35c1.26,0.78,1.73,2.56,1.14,4.33
                            c-0.54,1.65-0.47,3.43,0.22,5.28c0.74,1.96,2.66,3.14,4.71,2.86c1.65-0.21,3.03-1.27,3.61-2.76c1.57-4.05,4.26-6.92,7.02-7.5
                            c1.7-0.36,3.3,0.2,4.76,1.66c0,0,0,0,0,0C53.22,25.28,54.09,27.73,53.08,29.36z"/>
                        <circle fill="currentColor" cx="36" cy="36.07" r="3.09"/>
                        <path fill="currentColor" d="M36,6.18c-16.48,0-29.89,13.41-29.89,29.89S19.52,65.96,36,65.96s29.89-13.41,29.89-29.89
                            S52.48,6.18,36,6.18z M36,62.36c-14.5,0-26.29-11.8-26.29-26.29S21.5,9.78,36,9.78s26.29,11.8,26.29,26.29S50.5,62.36,36,62.36z"
                            />
                    </g>
                </g>
            </g>     
        </svg>
    )
}