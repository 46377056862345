import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getSidebar } from '../actions'
import Table from '../components/table/Table'
import { getCommandService } from '../services/command.service'

export default function TableView() {
    const commandService = getCommandService()
    const dispatch = useDispatch()
    const [tableData, setTableData] = useState([])

    const screenKey = useSelector(state => (state?.screen?.data) ? Object.keys(state.screen.data)[0] : null)
    const screenData = useSelector(state => (state?.screen?.data) ? Object.values(state.screen.data)[0] : {})

    const handleOnClick = (rowCommand) => {
        commandService.queueCommand(rowCommand)
    }

    /** Update the table and sidebar */
    useEffect(() => {
        const table = screenData[screenKey + 'Info']?.table ?? []
        const sidebar = screenData[screenKey + 'Info']?.sideBarEditor ?? []

        setTableData(table)
        dispatch( getSidebar(sidebar) )
    
    // eslint-disable-next-line
    }, [screenData, screenKey, dispatch])

    return (
        <div className="w-full">
            <div className="w-full relative overflow-x-auto">
                { tableData ? <Table tableData={tableData} onRowClick={handleOnClick} /> : '' }
            </div>
        </div>
    )
}