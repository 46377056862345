export const PhotoIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <path fill="currentColor" d="M61.58,19.5L26,12.7c-1.42-0.27-2.85,0.03-4.05,0.84c-1.19,0.81-2,2.04-2.27,3.45l-1.76,9.22h-6.49
                        c-2.98,0-5.4,2.42-5.4,5.4v20.6c0,2.98,2.42,5.4,5.4,5.4h36.22c2.98,0,5.4-2.42,5.4-5.4v-2.37l2.63,0.5
                        c0.34,0.07,0.68,0.1,1.02,0.1c2.54,0,4.81-1.8,5.3-4.39l3.87-20.23C66.43,22.9,64.5,20.06,61.58,19.5z M49.46,52.2
                        c0,0.99-0.81,1.8-1.8,1.8H11.43c-0.99,0-1.8-0.81-1.8-1.8v-20.6c0-0.99,0.81-1.8,1.8-1.8h5.8l-1.42,7.41
                        c-0.27,1.42,0.03,2.85,0.84,4.05c0.81,1.19,2.04,2,3.45,2.27l29.35,5.61V52.2z M62.33,25.14l-3.87,20.23
                        c-0.19,0.97-1.13,1.62-2.11,1.43l-4.74-0.91c0,0,0,0,0,0L20.78,40c-0.47-0.09-0.88-0.36-1.15-0.76c-0.27-0.4-0.37-0.88-0.28-1.35
                        l1.82-9.52c0,0,0,0,0-0.01l2.04-10.7c0.09-0.47,0.36-0.88,0.76-1.15c0.4-0.27,0.88-0.37,1.35-0.28l35.58,6.8
                        c0.47,0.09,0.88,0.36,1.15,0.76C62.33,24.19,62.42,24.67,62.33,25.14z"/>
                </g>
            </g>
        </svg>
    )
}