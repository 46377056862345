export const WaterIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M33.93,53.07c-0.99,0-1.8,0.81-1.8,1.8v4.09c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-4.09
                            C35.73,53.87,34.92,53.07,33.93,53.07z"/>
                        <path fill="currentColor" d="M46.28,53.07c-0.99,0-1.8,0.81-1.8,1.8v4.09c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-4.09
                            C48.08,53.87,47.28,53.07,46.28,53.07z"/>
                        <path fill="currentColor" d="M58.64,53.07c-0.99,0-1.8,0.81-1.8,1.8v4.09c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-4.09
                            C60.44,53.87,59.63,53.07,58.64,53.07z"/>
                        <path fill="currentColor" d="M40.11,42.69c-0.99,0-1.8,0.81-1.8,1.8v4.09c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8v-4.09
                            C41.91,43.49,41.1,42.69,40.11,42.69z"/>
                        <path fill="currentColor" d="M52.46,42.69c-0.99,0-1.8,0.81-1.8,1.8v4.09c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8v-4.09
                            C54.26,43.49,53.45,42.69,52.46,42.69z"/>
                        <path fill="currentColor" d="M48.06,24.9C47.68,14.01,38.7,5.27,27.71,5.27c-11.23,0-20.37,9.14-20.37,20.37v39.39
                            c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8V25.64c0-9.25,7.52-16.77,16.77-16.77c9.01,0,16.38,7.14,16.75,16.05
                            c-9.26,0.58-16.26,5.23-16.26,11.05c0,0.99,0.81,1.8,1.8,1.8h32.85c0.99,0,1.8-0.81,1.8-1.8C64.66,30.08,57.49,25.39,48.06,24.9z
                            M32.28,34.17c1.55-3.01,6.85-5.71,14.16-5.71s12.61,2.71,14.16,5.71H32.28z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}