export const CurtainsOpenIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M62.33,54.04h-2.49V11.96c0-2.98-2.42-5.4-5.4-5.4H17.55c-2.98,0-5.4,2.42-5.4,5.4v42.08H9.67
                            c-1.99,0-3.6,1.61-3.6,3.6v4.33c0,1.99,1.61,3.6,3.6,3.6h52.67c1.99,0,3.6-1.61,3.6-3.6v-4.33
                            C65.93,55.66,64.32,54.04,62.33,54.04z M36.69,10.16c0.3,2,1.46,9.12,4.02,17.58c3.48,11.53,7.92,20.35,13.23,26.3H18.78
                            c12.37-13.9,16.62-39.65,17.24-43.88H36.69z M56.25,11.96v39.26c-10.84-12.6-15.09-35.9-15.91-41.06h14.11
                            C55.44,10.16,56.25,10.97,56.25,11.96z M17.55,10.16h14.82c-0.85,5.28-5.25,29.46-16.62,41.86V11.96
                            C15.75,10.97,16.56,10.16,17.55,10.16z M9.67,61.98v-4.33h52.67l0,4.33H9.67z"/>
                        <path fill="currentColor" d="M20.8,29.3c0.28,0.16,0.59,0.24,0.9,0.24c0.62,0,1.23-0.32,1.56-0.9c2.63-4.55,3.2-11.95,3.22-12.27
                            c0.07-0.99-0.67-1.85-1.66-1.93c-0.98-0.07-1.85,0.67-1.93,1.66c0,0.07-0.53,6.9-2.75,10.73C19.65,27.7,19.94,28.81,20.8,29.3z"
                            />
                        <path fill="currentColor" d="M49.44,28.65c0.33,0.58,0.94,0.9,1.56,0.9c0.31,0,0.62-0.08,0.9-0.24c0.86-0.5,1.15-1.6,0.66-2.46
                            c-2.22-3.83-2.74-10.66-2.75-10.73c-0.07-0.99-0.94-1.74-1.93-1.66c-0.99,0.07-1.74,0.93-1.66,1.93
                            C46.24,16.69,46.81,24.1,49.44,28.65z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}