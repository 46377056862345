import { getClient, setClient, setSocketStatus } from './socket.service'
import { getAppSetting } from './settings.service'
import { writeLog } from './log.service'

export default class ConnectionService {
    constructor(commandService) {
        this.developerMode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')

        this.commandService = commandService
        this.localConnection = false

        this.authToken = null
        this.accessToken = null

        this.dcObject = null
        this.dcUri = `${this.developerMode ? process.env.REACT_APP_DC_DEVELOP : process.env.REACT_APP_DC_PRODUCTION}/ws`
        
        this.client = null
        this.activeConnection = null
        this.hmiTimeout = parseInt(getAppSetting('hmi_timeout'))
        this.connectionTimeout = parseInt(getAppSetting('dc_timeout'))

        this.connect = null
        this.disconnect = null
        this.handleConnectionFailure = (msg) => { throw new Error('Handled Error', msg) }
    }

    init() {
        this.authToken = JSON.parse(localStorage.getItem('access_token')).idToken
        this.accessToken = JSON.parse(localStorage.getItem('access_token')).accessToken
        
        this.connect = this.createWebSocketConnection
        this.disconnect = this.closeConnection
    }

    createWebSocketConnection(connection, handleConnectionFailure = null) {
        this.localConnection = (typeof connection === 'string' || connection instanceof String)
        
        if (!this.localConnection) { this.dcObject = connection }
        if (handleConnectionFailure) { this.handleConnectionFailure = handleConnectionFailure }

        const ip = (!this.localConnection) 
            ? this.dcUri : connection
    
        this.openConnection(ip, this.localConnection)
    }

    openConnection(ip) {
        setClient(ip, this.localConnection)
        this.client = getClient()

        this.client.addEventListener('open', (connection) => {
            this.activeConnection = connection
            setSocketStatus('OPEN')

            writeLog('Success', `Connection to ${(!this.localConnection) ? this.dcObject?.dcName : ip}`, this.client)

            this.commandService.init()
            this.commandService.processQueue()

            this.createEventListeners()
            this.sendInitialMessage()
        })
    }

    sendInitialMessage() {
        let data = ''
        let addressTo = 0

        if ((!this.localConnection)) {
            data = JSON.stringify({
                token: this.authToken,
                installationId: this.dcObject.installationID,
                dcId: this.dcObject.dcId
            })

            addressTo = this.dcObject.dcId
        } 

        this.client.send( 
            JSON.stringify({ 
                ataTouchMessage: {
                    addressFrom: [ 0 ],
                    addressTo: [ addressTo ],
                    instruction: 16,
                    data: data
                } 
            }) 
        )
    }

    closeConnection() {
        if (this.client) {
            this.client.close()
            writeLog('System', `Connection Closed`)
        }
    }

    getAvailableConnections() {        
        const getHmisUri = this.developerMode ? process.env.REACT_APP_DC_DEVELOP : process.env.REACT_APP_DC_PRODUCTION
        const protocol = (process.env.REACT_APP_USE_SSL === 'false') ? 'http' : 'https'
        
        if (!this.authToken || !this.accessToken) {
            writeLog('Error', `Unauthorized: access tokens are missing or invalid`)
            return
        }

        return fetch(`${protocol}://${getHmisUri}/getHMIs`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.authToken}`
            },
            body: JSON.stringify({token : this.accessToken})
          })
    }

    createEventListeners() {
        const timeoutType = this.localConnection ? this.hmiTimeout : this.connectionTimeout

        const timer = setTimeout(() => {
            this.handleConnectionFailure('Failed to connect: Timed out')
        }, parseInt(timeoutType))

        this.client.addEventListener('error', function(error) {
            setSocketStatus('ERROR')
            
            writeLog('Error', `Connection to ${this.dcObject.dcName} Failed`, error)
            this.handleConnectionFailure(error)
        })

        this.client.addEventListener('message', (r) => {
            const response = JSON.parse(r.data)
            clearTimeout(timer)

            if (response?.ataTouchMessage?.instruction === 17) {
                this.commandService.setCommandHeader(response?.ataTouchMessage)   
                this.commandService.setHomeCommand(response?.ataTouchMessage?.data?.hostDetails?.homeAddress)
                this.commandService.queueCommand(response?.ataTouchMessage?.data?.hostDetails?.homeAddress)
            } else {
                this.commandService.recieveCommand(response?.ataTouchMessage)	
            }
            
            setSocketStatus('REFRESHING')
        })
    }
}
