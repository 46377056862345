export const GarageDoorClosedIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                <rect className="st0" width="72" height="72"/>
                <g>
                    <path fill="currentColor" d="M64.2,66.83c-0.99,0-1.8-0.81-1.8-1.8V10.64c0-0.99-0.81-1.8-1.8-1.8H11.4c-0.99,0-1.8,0.81-1.8,1.8v54.39
                        c0,0.99-0.81,1.8-1.8,1.8S6,66.02,6,65.03V10.64c0-2.98,2.42-5.4,5.4-5.4H60.6c2.98,0,5.4,2.42,5.4,5.4v54.39
                        C66,66.02,65.19,66.83,64.2,66.83z"/>
                    <path fill="currentColor" d="M57.05,16.16h-42.1c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h42.1c0.99,0,1.8,0.81,1.8,1.8
                        S58.04,16.16,57.05,16.16z"/>
                    <path fill="currentColor" d="M57.05,22.92h-42.1c-0.99,0-1.8-0.81-1.8-1.8c0-0.99,0.81-1.8,1.8-1.8h42.1c0.99,0,1.8,0.81,1.8,1.8
                        C58.85,22.11,58.04,22.92,57.05,22.92z"/>
                    <path fill="currentColor" d="M57.05,29.67h-42.1c-0.99,0-1.8-0.81-1.8-1.8c0-0.99,0.81-1.8,1.8-1.8h42.1c0.99,0,1.8,0.81,1.8,1.8
                        C58.85,28.86,58.04,29.67,57.05,29.67z"/>
                    <path fill="currentColor" d="M57.05,36.42h-42.1c-0.99,0-1.8-0.81-1.8-1.8c0-0.99,0.81-1.8,1.8-1.8h42.1c0.99,0,1.8,0.81,1.8,1.8
                        C58.85,35.62,58.04,36.42,57.05,36.42z"/>
                    <path fill="currentColor" d="M57.05,43.18h-42.1c-0.99,0-1.8-0.81-1.8-1.8s0.81-1.8,1.8-1.8h42.1c0.99,0,1.8,0.81,1.8,1.8
                        S58.04,43.18,57.05,43.18z"/>
                    <path fill="currentColor" d="M57.05,49.93h-42.1c-0.99,0-1.8-0.81-1.8-1.8c0-0.99,0.81-1.8,1.8-1.8h42.1c0.99,0,1.8,0.81,1.8,1.8
                        C58.85,49.12,58.04,49.93,57.05,49.93z"/>
                    <path fill="currentColor" d="M57.05,56.68h-42.1c-0.99,0-1.8-0.81-1.8-1.8c0-0.99,0.81-1.8,1.8-1.8h42.1c0.99,0,1.8,0.81,1.8,1.8
                        C58.85,55.88,58.04,56.68,57.05,56.68z"/>
                    <circle fill="currentColor" cx="36" cy="62.68" r="2.34"/>
                </g>
            </g>        
        </svg>
    )
}

export const GarageDoorOpenIcon = () => {
    return (
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" className="w-full"  fill="none">
            <g>
                
                <g>
                    <g>
                        <path fill="currentColor" d="M60.6,5.24H11.4c-2.98,0-5.4,2.42-5.4,5.4v54.39c0,0.99,0.81,1.8,1.8,1.8s1.8-0.81,1.8-1.8V10.64
                            c0-0.99,0.81-1.8,1.8-1.8H60.6c0.99,0,1.8,0.81,1.8,1.8v54.39c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8V10.64
                            C66,7.66,63.57,5.24,60.6,5.24z"/>
                        <path fill="currentColor" d="M57.05,12.56h-42.1c-0.99,0-1.8,0.81-1.8,1.8s0.81,1.8,1.8,1.8h42.1c0.99,0,1.8-0.81,1.8-1.8
                            S58.04,12.56,57.05,12.56z"/>
                        <path fill="currentColor" d="M57.05,19.32h-42.1c-0.99,0-1.8,0.81-1.8,1.8c0,0.99,0.81,1.8,1.8,1.8h42.1c0.99,0,1.8-0.81,1.8-1.8
                            C58.85,20.12,58.04,19.32,57.05,19.32z"/>
                        <path fill="currentColor" d="M53.54,41.13l-1.72-9.62c-0.46-2.58-2.7-4.45-5.32-4.45H25.58c-2.62,0-4.86,1.87-5.32,4.45l-1.72,9.62
                            c-1.72,0.9-2.89,2.71-2.89,4.78v6.8c0,2.35,1.51,4.35,3.61,5.09v4.74c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8v-4.43
                            h26.36v4.43c0,0.99,0.81,1.8,1.8,1.8c0.99,0,1.8-0.81,1.8-1.8V57.8c2.1-0.74,3.61-2.74,3.61-5.09v-6.8
                            C56.43,43.84,55.26,42.04,53.54,41.13z M23.81,32.14c0.15-0.86,0.9-1.48,1.77-1.48h20.92c0.87,0,1.62,0.62,1.77,1.48l1.49,8.37
                            H22.32L23.81,32.14z M52.83,52.71c0,0.99-0.81,1.8-1.8,1.8H21.06c-0.99,0-1.8-0.81-1.8-1.8v-6.8c0-0.99,0.81-1.8,1.8-1.8h29.97
                            c0.99,0,1.8,0.81,1.8,1.8V52.71z"/>
                        
                            <ellipse fill="currentColor" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -27.5274 32.1676)" cx="25.07" cy="49.31" rx="2.98" ry="2.98"/>
                        
                            <ellipse fill="currentColor" transform="matrix(0.9732 -0.2298 0.2298 0.9732 -10.0717 12.1229)" cx="47.02" cy="49.31" rx="2.98" ry="2.98"/>
                    </g>
                </g>
            </g>        
        </svg>
    )
}